import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Row, Card, Form, Radio, message, Divider } from "antd";
import React, { useState, useEffect } from "react";
import styles from "./EditSupplier.module.css";
import { _PATCH } from "../../../../../common/commonFunc";
import SupplierAdditionalInfo from "../../../../Store/StorePage/SupplierAdditionalInfo";

const StoreInfoCard = ({ userInfo, onSave, back }: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [storeInfoData, setStoreInfoData] = useState<any>({});
  const [form] = Form.useForm();

  useEffect(() => {
    if (userInfo) {
      const storeInfo = {
        upcInfo:
          userInfo?.store_info?.[
          "Do you have universal product codes (UPCs) for all your products?"
          ] || "No",
        trademarkInfo:
          userInfo?.store_info?.[
          "Do you own government-registered trademark for the branded products you want to sell on Octopus"
          ] || "No",
        manufacturerInfo:
          userInfo?.store_info?.[
          "Are you the manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Octopus?"
          ] || "No",
      };

      setInitialValues(storeInfo);
      setStoreInfoData(storeInfo);
      form.setFieldsValue(storeInfo);
    }
  }, [userInfo]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    form.setFieldsValue(initialValues);
    setStoreInfoData(initialValues);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const storeInfoPayload = {
        "Do you have universal product codes (UPCs) for all your products?":
          storeInfoData.upcInfo,
        "Do you own government-registered trademark for the branded products you want to sell on Octopus":
          storeInfoData.trademarkInfo,
        "Are you the manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Octopus?":
          storeInfoData.manufacturerInfo,
      };

      const response = await _PATCH(
        `userProfile/supplier/details`,
        { store_info: storeInfoPayload },
        true
      );

      if (response?.status === true && response?.statusCode === 200) {
        message.success("Store Info updated successfully");
        onSave();
        setIsEditing(false);
        setInitialValues(storeInfoPayload);
        // back();
      } else {
        message.error("Failed to update Store Info");
      }
    } catch (error) {
      console.error("Error updating Store Info:", error);
      message.error("An error occurred while updating Store Info");
    }
  };

  const handleRadioChange = (key: string, value: string) => {
    const updatedStoreInfo = { ...storeInfoData, [key]: value };
    setStoreInfoData(updatedStoreInfo);
    form.setFieldsValue(updatedStoreInfo);
  };

  return (
    <>
      <SupplierAdditionalInfo userInfo={userInfo} onSave={onSave} />
      <Divider />
      <Card
        title={
          userInfo.store_info ? (
            "Store Information"
          ) : (
            <p style={{ color: "#ff4d4f" }}>
              No Store Information. You can edit and add here.
            </p>
          )
        }
        extra={
          <div style={{ display: "flex", gap: "10px" }}>
            {isEditing && (
              <Button
                icon={<CloseOutlined />}
                onClick={handleCancel}
                className={styles.saveBtn}
              >
                Cancel
              </Button>
            )}
            <Button
              type="primary"
              icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
              onClick={() => {
                if (isEditing) {
                  handleSave();
                } else {
                  handleEditClick();
                }
              }}
              className={`${styles.saveBtn} ${isEditing ? styles.saveSmallBtn : ""
                }`}
            >
              {isEditing ? "Save" : "Edit"}
            </Button>
          </div>
        }
        className={styles.contactCard}
      >
        <Form
          form={form}
          style={{ marginTop: "0 !important" }}
          layout="vertical"
          initialValues={initialValues}
          className={styles.form}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <p>
                    <span className="error">*</span> Do you have universal product
                    codes (UPCs) for all your products?
                  </p>
                }
              >
                <Radio.Group
                  disabled={!isEditing}
                  onChange={(e) => handleRadioChange("upcInfo", e.target.value)}
                  value={storeInfoData.upcInfo}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                  <Radio value="Some of them">Some of them</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={
                  <p>
                    <span className="error">*</span> Do you own
                    government-registered trademark for the branded products you
                    want to sell on Octopus?
                  </p>
                }
              >
                <Radio.Group
                  disabled={!isEditing}
                  onChange={(e) =>
                    handleRadioChange("trademarkInfo", e.target.value)
                  }
                  value={storeInfoData.trademarkInfo}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                  <Radio value="Some of them">Some of them</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={
                  <p>
                    <span className="error">*</span> Are you the manufacturer or
                    brand owner (or agent or representative of the brand) for any
                    of the products you want to sell on Octopus?
                  </p>
                }
              >
                <Radio.Group
                  disabled={!isEditing}
                  onChange={(e) =>
                    handleRadioChange("manufacturerInfo", e.target.value)
                  }
                  value={storeInfoData.manufacturerInfo}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                  <Radio value="Some of them">Some of them</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default StoreInfoCard;
