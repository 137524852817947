import React, { useEffect, useState } from "react";
import { Button, Input, Layout, Select, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import OrderTable from "./Orderingtable";
import { _GET } from "../../../common/commonFunc";
import Styles from "../Store/MyStore.module.css";
import CSVIcon from "../../../assests/CSV.png";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import Papa from 'papaparse'
import {
    IconButton,
} from "@mui/material";

const { TabPane } = Tabs;
const {Option} = Select
const Orderlisting = () => {
    const [open, setOpen] = useState(false);
    const [zero, setZero] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTab, setActiveTab] = useState("Pending Orders");
    const [activeSubTab, setActiveSubTab] = useState<any | null>("Octopus Courier");
    const [pendingOrders, setPendingOrders] = useState<any[]>([]);
    const [allOrders, setAllOrders] = useState<any[]>([]);
    const [octopusCourier, setOctopusCourier] = useState<any[]>([]);
    const [ownCourier, setOwnCourier] = useState<any[]>([]);
    const [orderCancelled, setOrderCancelled] = useState<any[]>([]);
    const [orderPrepared, setOrderPrepared] = useState<any[]>([]);
    const [orderHandover, setOrderHandover] = useState<any[]>([]);
    const [orderInTransit, setOrderInTransit] = useState<any[]>([]);
    const [orderCompleted, setOrderCompleted] = useState<any[]>([]);
    const [returnOrder, setReturnOrder] = useState<any[]>([]);
    const [pendingToRTD, setPendingToRTD] = useState<any[]>([]);


    const getOrderList = async () => {
        try {
            const res = await _GET(
                `supplier/fetch/orders`,
                true,
                false
            );
            if (res && res.status && res.data) {

                setAllOrders(res.data);

                setPendingOrders(
                    res.data.filter(
                        (order: any) => order.order_item_status === "ORDERED"
                    )
                );

                setOrderPrepared(
                    res.data.filter(
                        (order: any) => order.order_item_status === "PREPARED"
                    )
                );

                setOrderHandover(
                    res.data.filter(
                        (order: any) => order.order_item_status === "SHIPPED"
                    )
                );
                setOrderInTransit(
                    res.data.filter(
                        (order: any) => (order.order_item_status === "PREPARED") || (order.order_item_status === "SHIPPED")
                    )
                )
                setOrderCompleted(
                    res.data.filter(
                        (order: any) => order.order_item_status === "DELIVERED" || order.order_item_status === "COMPLETED"
                    )
                )
                setOrderCancelled(
                    res.data.filter(
                        (order: any) => order.order_item_status === "CANCELLED" || order.order_status === "CANCELLED"
                    )
                )
            }
        } catch (error) {
            console.log("Error fetching orders:", error);
        }
    };

    const getReturnOrder = async () => {
        try {
            const res = await _GET(
                `supplier/allProductOrderReturnRequest`,
                true,
                false
            );
            if (res && res.status && res.data) {

                setReturnOrder(
                    res.data.filter(
                        (order: any) => order.order_item_status === "RETURNED" && order?.return_status?.toLowerCase() === "pending"
                    )
                );
                setPendingToRTD(
                    res.data.filter(
                        (order: any) => order.order_item_status === "PENDING_TO_RTD" || order?.return_status?.toLowerCase() === "accepted"
                    )
                )
            }
        } catch (error) {
            console.log("Error fetching returned orders:", error);
        }
    };

    useEffect(() => {
        getOrderList();
        getReturnOrder();
    }, []);

    useEffect(() => {
        setOctopusCourier(
            pendingOrders.filter(
                (courier: any) => courier.courier_service_choice === "octopus_courier_service"
            )
        );
        setOwnCourier(
            pendingOrders.filter(
                (courier: any) => courier.courier_service_choice === "supplier_courier_service"
            )
        )
    }, [pendingOrders]);


    const handleTabChange = (status: any) => {
        setActiveTab(status);
        // if (status !== "Pending Orders") {
        //     setActiveSubTab(null);
        // }

    };

    const handleSubTabChange = (status: any) => {
        setActiveSubTab(status);
    };

    const history = useHistory();

    const returnOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product_img", label: "PRODUCT", width: "20%" },
        { id: "return_category", label: "RETURN CATEGORY", width: "20%" },
        {
            id: "price",
            label: "PRICE",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "30%" },
    ];

    const allOrderHeader = [
        { id: "transaction_id", label: "PRODUCT ID", width: "20%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        {
            id: "price",
            label: "ORDER AMOUNT",
            width: "20%",
        },
        { id: "status", label: "STATUS", width: "20%" },
        { id: "action", label: "ACTION", width: "20%" },
       
    ]

    const pendingOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "10%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "order_date", label: "ORDER DATE", width: "10%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "paid",
            label: "PAID",
            width: "10%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]

    const preparedOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "10%" },
        { id: "product", label: "PRODUCT", width: "15%" },
        { id: "sheduled_date_and_time", label: "SCHEDULED DATE AND TIME", width: "15%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "30%" },
    ]


    const pendingToRTDHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product_img", label: "PRODUCT", width: "20%" },
        { id: "handover_date_time", label: "HANDOVER DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]

    const orderHandoverHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "handover_date_time", label: "HANDOVER DATE AND TIME", width: "15%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "10%",
        },
        { id: "action", label: "ACTION", width: "25%" },
    ]


    const order_completedHeader = [

        { id: "transaction_id", label: "TRANSACTION ID", width: "10%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "completed_date_time", label: "COMPLETED DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" }

    ]

    const order_cancelledHeader = [

        { id: "transaction_id", label: "TRANSACTION ID", width: "20%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "cancelled_date_time", label: "CANCELLED ON", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "20%",
        },
        // { id: "action", label: "ACTION", width: "20%" }

    ]

    const formatDate = (unixTimestamp: any) => {
        const date = new Date(parseInt(unixTimestamp) * 1000);
        return date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      };
    
      const formatAddress = (location: any) => {
        if (!location) return "Address not available";
    
        let addressParts = [
          location?.address_name,
          location?.building_no,
          location?.landmark,
          location?.city,
          location?.state,
          location?.country,
          location?.pincode,
        ];
    
        let formattedAddress = addressParts
          .filter((part) => part)
          .slice(0, 2)
          .join(", ");
    
        return formattedAddress;
      };

    const downloadCsv = async (value: any) => {
        let csvFriendlyData : any[] = []
        let data = []
        switch (value){
            case "allOrders" : data = allOrders
            break;
            case "pendingOrders" : data = pendingOrders
            break;
            case "orderCompleted" : data = orderCompleted
            break;
            case "orderInTransit" : data = orderInTransit
            break;
            case "orderCancelled" : data = orderCancelled
            break;
            default : data = allOrders
        }
        if(data?.length > 0){
             csvFriendlyData = data?.map((order: any) => ({
                "Order Item ID": order?.order_id,
                "Product Name": order?.product_name,
                "Size" : order?.product_size ?? order?.prod_size,
                "Color" : order?.product_color ?? order?.prod_color,
                "Customer First Name" : order?.customer_details?.first_name,
                "Customer Last Name" : order?.customer_details?.last_name,
                "Customer email" : order?.customer_details?.email,
                "Delivery Address" : formatAddress(order?.delivery_address),
                "Order Date": formatDate(order?.purchase_date),
                "Order Status": order?.order_item_status,
                "Price Per Unit": `BHD ${order?.price_per_unit}`,
                "Supplier Final Amount":  `BHD ${order?.supplier_final_amount}`,
              }));
        }
      
          const csv = Papa.unparse(csvFriendlyData);
          const blob = new Blob([csv], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("hidden", "");
          a.setAttribute("href", url);
          a.setAttribute("download", `${value}.csv`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
      };
    


    return (
        <Layout
            style={{
                height: "100vh",
                backgroundColor: "#F5F4FA",
            }}
            className={Styles.customLayoutBG}
        >
            <div className={Styles.header}>
                <h1>Manage Orders</h1>
                <div className={Styles.serviceHeader}>

                    <div className={Styles.actionsContainerNext}>
                    <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                ALL ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {allOrders ? allOrders.length : 0}
                                </span>
                            </span>
                        }
                        key="All Orders"
                    />
                    </Tabs>
                    {/* <Button className={Styles.getcsvbutton}>
                <img src={CSVIcon} alt="csv" /> Get CSV
              </Button> */}

<Select
            placeholder={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={CSVIcon} className={Styles.csvOption} alt="csv" />
                Get CSV
              </div>
            }
            style={{ width: 200 }}
            onChange={downloadCsv}
            className={Styles.getcsvbutton}
          >
            <Option value="allOrders">  <img src={CSVIcon} className={Styles.csvOption} alt="csv" />All Orders</Option>
            <Option value="pendingOrders">  <img src={CSVIcon} className={Styles.csvOption} alt="csv" />Pending Orders</Option>
            <Option value="orderInTransit">  <img src={CSVIcon} className={Styles.csvOption} alt="csv" />Orders In-Transit</Option>
            <Option value="orderCompleted">  <img src={CSVIcon} className={Styles.csvOption} alt="csv" />Completed Orders</Option>
            <Option value="orderCancelled">  <img src={CSVIcon} className={Styles.csvOption} alt="csv" />Cancelled Orders</Option>
            
          </Select>
                        <div className={Styles.searchDiv}>
                            <img src={SearchIcon} alt="SearchIcon" />
                            <Input
                                placeholder="Search by ID, Name..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className={Styles.searchInput}
                            />

                            <IconButton>
                                <Filter />
                            </IconButton>
                        </div>
                    </div>
                </div>

            </div>

            <div className={Styles.actionsContainerMain}>
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PENDING ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {pendingOrders ? pendingOrders.length : 0}
                                </span>
                            </span>
                        }
                        key="Pending Orders"
                    />

                    {/* <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PREPARED ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {orderPrepared ? orderPrepared.length : 0}
                                </span>
                            </span>
                        }
                        key="Prepared Orders"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                ORDER HANDOVER 
                                <span className={Styles.countingSpan}>
                                    {orderHandover ? orderHandover.length : 0}
                                </span>
                            </span>
                        }
                        key="Order Handover"
                    /> */}

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                ORDERS IN-TRANSIT
                                <span className={Styles.countingSpan}>
                                    {orderInTransit ? orderInTransit.length : 0}
                                </span>
                            </span>
                        }
                        key="Order InTransit"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                COMPLETED ORDERS
                                <span className={Styles.countingSpan}>
                                    {orderCompleted ? orderCompleted.length : 0}
                                </span>
                            </span>
                        }
                        key="Order Completed"
                    />
                        <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                CANCELLED ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {orderCancelled ? orderCancelled.length : 0}
                                </span>
                            </span>
                        }
                        key="Orders Cancelled"
                    />

                    <TabPane
                        tab={<span className={Styles.separator}>|</span>}
                        disabled
                        key="Separator"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                RETURN ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {returnOrder ? returnOrder.length : 0}
                                </span>
                            </span>
                        }
                        key="Return Order"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PENDING TO RTD
                                <span className={Styles.countingSpan}>
                                    {pendingToRTD ? pendingToRTD?.length : 0}
                                </span>
                            </span>
                        }
                        key="Pending To Rtd"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                RETURN COMPLETE <span className={Styles.countingSpan}>0</span>
                            </span>
                        }
                        key="Return Complete"
                    />


                </Tabs>
            </div>

            <div
                style={{ height: "auto", width: "100%" }}
                className={Styles.parentContentdiv_}
            >
                {/* {activeTab === "Pending Orders" && (
                    // <OrderTable headers={pendingOrderHeader}
                    //     data={pendingOrders} status="pendingOrders"
                    //     getOrderList={getOrderList} />
                    <Tabs activeKey={activeTab} onChange={handleTabChange}>

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                 Octopus Courier{" "}
                                <span className={Styles.countingSpan}>
                                    {octopusCourier ? octopusCourier.length : 0}
                                </span>
                            </span>
                        }
                        key="Octopus Courier"
                    />
                         <TabPane
                        tab={<span className={Styles.separator}>|</span>}
                        disabled
                        key="Separator"
                    />
                       <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                 Own Courier{" "}
                                <span className={Styles.countingSpan}>
                                    {ownCourier ? ownCourier.length : 0}
                                </span>
                            </span>
                        }
                        key="Own Courier"
                    />
                    </Tabs>
                )} */}

            {activeTab === "All Orders" && (
                    <OrderTable headers={allOrderHeader}
                        data={allOrders} status="allOrders"
                        getOrderList={getOrderList} />
                )}

                {activeTab === "Pending Orders" && (
                    <>
                        <Tabs activeKey={activeSubTab} onChange={handleSubTabChange}>
                            <TabPane
                                tab={
                                    <span className={Styles.countingSpanParent}>
                                        Octopus Courier{" "}
                                        <span className={Styles.countingSpan}>
                                            {octopusCourier ? octopusCourier.length : 0}
                                        </span>
                                    </span>
                                }
                                key="Octopus Courier"
                            />
                            <TabPane
                                tab={<span className={Styles.separator}>|</span>}
                                disabled
                                key="Separator"
                            />
                            <TabPane
                                tab={
                                    <span className={Styles.countingSpanParent}>
                                        Own Courier{" "}
                                        <span className={Styles.countingSpan}>
                                            {ownCourier ? ownCourier.length : 0}
                                        </span>
                                    </span>
                                }
                                key="Own Courier"
                            />
                        </Tabs>

                        {activeSubTab === "Octopus Courier" && (
                            <OrderTable
                                headers={pendingOrderHeader}
                                data={octopusCourier}
                                status="pendingOrders-octopus"
                                getOrderList={getOrderList}
                            />
                        )}
                        {activeSubTab === "Own Courier" && (
                            <OrderTable
                                headers={pendingOrderHeader}
                                data={ownCourier}
                                status="pendingOrders-own"
                                getOrderList={getOrderList}
                            />
                        )}
                    </>
                )}

                {activeTab === "Prepared Orders" && (
                    <OrderTable headers={preparedOrderHeader}
                        data={orderPrepared} status="preparedOrders"
                        getOrderList={getOrderList} />
                )}
                {activeTab === "Order Handover" && (
                    <OrderTable headers={orderHandoverHeader}
                        data={orderHandover} status="orderHandover"
                        getOrderList={getOrderList} />
                )}
                {activeTab === "Order InTransit" && (
                    <OrderTable headers={orderHandoverHeader}
                        data={orderInTransit} status="orderInTransit"
                        getOrderList={getOrderList} />
                )}
                {activeTab === "Order Completed" && (
                    <OrderTable headers={order_completedHeader}
                        data={orderCompleted} status="orderCompleted"
                        getOrderList={getOrderList} />
                )}
                  {activeTab === "Orders Cancelled" && (
                    <OrderTable headers={order_cancelledHeader}
                        data={orderCancelled} status="cancelledOrders"
                        getOrderList={getOrderList} />
                )}
                {activeTab === "Return Order" && (
                    <OrderTable
                        headers={returnOrderHeader}
                        data={returnOrder}
                        status="returnOrder"
                        getOrderList={getReturnOrder}
                    />
                )}
                {activeTab === "Pending To Rtd" && (
                    <OrderTable headers={pendingToRTDHeader}
                        data={pendingToRTD} status="pendingToRtd"
                        getOrderList={getReturnOrder} />
                )}
                {activeTab === "Return Complete" && (
                    <OrderTable headers={pendingToRTDHeader}
                        data={zero} status="returnComplete"
                        getOrderList={getReturnOrder} />
                )}



            </div>
        </Layout>
    );
};

export default Orderlisting;
function filteredAllServiceData(freelancers: never[], searchTerm: string) {
    throw new Error("Function not implemented.");
}
