import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { CalendarOutlined, LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Input,
  Form,
  Button,
  notification,
  Typography,
  Space,
  Row,
  Col,
  Select,
} from "antd";
import _ from "lodash";
import countries from "../../pages/SupplierRegister/Data/countries.json";
import "./login.css";
import Styles from "./FormStyle.module.css";
import OctopusImage from "../../assests/logo.svg";
import { _POST } from "../../common/commonFunc";
import { BASE_URL } from "../../constant/ApplicationRoutes";
import { noSpecialCharactersValidator } from "../../utils/helpers"
import axios from "axios";
import VerifyOtpGuest from "./VerifyOtpGuest";
import { setCookie } from "../../utils/tokenService";
import GuestLogin from "./GuestLogin";

const { Option } = Select;

const InstantCheckout = ({ open, onClose }: any) => {
  const [emailExists, setEmailExists] = useState(false);
  const [phoneExists, setPhoneExists] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false)
  const [form] = Form.useForm<any>();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [loginMode, setLoginMode] = useState (true)
  

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any;
    return function (this: any) {
      const context: any = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const closeVerifyOtpModal = () => {
    setVerifyOtp(false);
  }

  const closeDrawer = () => {
    onClose()
    form.resetFields();
    setEmailExists(false)
    setPhoneExists(false)
    setLoginMode(true)
  }


  const PhoneNumberInput = ({ value = {}, onChange }: any) => {
    const [number, setNumber] = React.useState(value.number || "");
    const [countryCode, setCountryCode] = React.useState(
      value.countryCode || "+973"
    );

    const triggerChange = (changedValue: any) => {
      onChange?.({
        number,
        countryCode,
        ...value,
        ...changedValue,
      });
    };

    const onNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
      triggerChange({ number: newNumber });
    };

    const onCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
      triggerChange({ countryCode: newCountryCode });
    };

    return (
      <div className={Styles.NormalInput}>
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            value={countryCode}
            onChange={onCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {countries.map((country: any) => (
              <Option key={country.code} value={country.dial_code}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {country.dial_code}
              </Option>
            ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            value={number}
            onChange={onNumberChange}
            placeholder="Enter number"
            className={Styles.FormInput}
          />
        </Input.Group>
      </div>
    );
  };

  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = debounce(async (e: any) => {
    const email = e.target.value;
    if (email && isValidEmail(email)) {
      try {
        const response = await axios.post(
          `${BASE_URL}checkUserExists/${email}`
        );
        if (response.data.status) {
          setEmailExists(true);
        } else {
          setEmailExists(false);
        }
      } catch (error) {
        console.error("Error checking email", error);
        setEmailExists(false);
      }
    }
  }, 500);


  const handlePhoneChange = debounce(async (phoneData: { countryCode: string; number: string }) => {
    const { countryCode, number } = phoneData;
    const fullPhoneNumber = `${countryCode}${number}`;

    if (number) {
      try {
        const response = await axios.post(
          `${BASE_URL}checkGuestExists/${fullPhoneNumber}`
        );
        if (response.data.status) {
          setPhoneExists(true);
          setLoginMode(true);
        } else {
          setPhoneExists(false);
          setLoginMode(false)
        }
      } catch (error) {
        console.error("Error checking phone number", error);
        setPhoneExists(false);
        setLoginMode(false)
      }
    } else {
      setPhoneExists(false);
      setLoginMode(false)
    }
  }, 1800);

  useEffect(() => {
    if (emailExists === true) {
      notification.error({
        message: "User Already Exists",
        description:
          "User already exists with this email. Please login or use the forgot password feature.",
      });
    }
  }, [emailExists]);

  // useEffect(() => {
  //   if (phoneExists === true) {
  //     notification.error({
  //       message: "User Already Exists",
  //       description:
  //         "User already exists with this phone number. Please login or use the forgot password feature.",
  //     });
  //   }
  // }, [phoneExists]);


  const openLogin = () => {
    closeDrawer()
    form.resetFields()
    setIsLoginOpen(true)
  }

  const closeLogin = () => {
    setIsLoginOpen(false);
  }

const onLogin = async (values: any) => {
    console.log(" onLogin  values:", values);
     const fullPhoneNumber = `${values.phoneNumber.countryCode}${values.phoneNumber.number}`
    if(values.phoneNumber.number === "" || values.phoneNumber.number === " " ){
         notification.error({
                  message: "Please enter phone number",
                  description:
                    "Phone number cannot be empty",
                });
                return
              }
    
    try {
      const res: any = await _POST(
        "octopus/guest/login",
        {
          phone_number: fullPhoneNumber,
        },
        false,
        false
      );
      if (res && res.status === false) {
        notification.info({
          message: res.message,
        });

      }
      const userInfo = {
        full_phone_number: fullPhoneNumber,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));

        if (res.data && res.status) {
          onClose()
          form.resetFields()
          setPhoneExists(true)
          setVerifyOtp(true)
        }

    } catch (error) {
      console.log("🚀 ~ onLogin ~ error:", error);
      return notification.open({
        type: "info",
        message: "Something went wrong!. Please try again",
        description: _.get(error, "message", "error"),
      });
    }
  };
  

  const onRegister = async (values: any) => {
    console.log("onRegister  values:", values);
    if (emailExists) {
      if (emailExists === true) {
        notification.error({
          message: "User Already Exists",
          description:
            "User already exists with this email. Please login or use the forgot password feature.",
        });
      }
      setEmailExists(true);
      return;
    }
    if (phoneExists) {
      if (phoneExists === true) {
        notification.error({
          message: "User Already Exists",
          description:
            "User already exists with this phone number. Please login or use the forgot password feature.",
        });
      }
      setPhoneExists(true);
      setLoginMode(true)
      return;
    }
    try {
      const fullPhoneNumber = `${values.phoneNumber.countryCode}${values.phoneNumber.number}`


      const res: any = await _POST(
        "octopus/guest",
        {
          first_name: values.firstName,
          last_name: values.lastName,
          // display_name : values.display_name,
          phone_no: values.phoneNumber.number,
          email: values.email,
          full_phone_number: fullPhoneNumber,
          country_code: values.phoneNumber.countryCode
        },
        false,
        false
      );
      if (res && res.status === false) {
        notification.info({
          message: res.message,
        });

      }

      if (res) {
        const userInfo = {
          first_name: values.firstName,
          last_name: values.lastName,
          phone_no: values.phoneNumber.number,
          email: values.email,
          full_phone_number: fullPhoneNumber,
          country_code: values.phoneNumber.countryCode
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        if (res.data && res.status) {
          localStorage.setItem("jwtToken", res?.data?.token);
          setCookie("refreshToken", res?.data?.refreshToken, 720);
          onClose()
          setEmailExists(false)
          setVerifyOtp(true)
        }
      }

    } catch (error) {
      console.log("🚀 ~ onLogin ~ error:", error);
      return notification.open({
        type: "info",
        message: "Something went wrong!. Please try again",
        description: _.get(error, "message", "error"),
      });
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        className="loginPopUp"
        sx={{
          "& .MuiPaper-root": { borderRadius: 6 },
          "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
            background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
          },
          "& .css-y2taew .MuiPaper-root": {
            background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
          },
          "& .loginPopUp .MuiDialog-paperWidthSm": {
            background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
          },
          "& .MuiDialog-paperWidthSm": {
            background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
          },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeDrawer}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              backgroundColor: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              marginTop: "3%",
              width: "100%",
              height: "100%",
            }}
          >
            <Space
              direction="vertical"
              size="middle"
              align="start"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "8px",
                  marginTop: "8px",
                }}
                src={OctopusImage}
                alt=""
              />
              <Typography.Text
                style={{
                  textAlign: "left",
                  fontFamily: "Ubuntu",
                  fontWeight: "bold",
                  marginTop: "0px",
                  fontSize: "20px",
                }}
              >
                Please enter your details to continue.
              </Typography.Text>
            </Space>
            <Form
              form={form}
              className="registerContainer"
              name="basic"
              size="large"
              layout="vertical"
              onFinish={loginMode ? onLogin :onRegister}
              style={{ margin: "3% 15%", width: "70%", height: "80%" }}
              initialValues={{
                country: countries[0].name,
                number: {
                  countryCode: "+973",
                  number: "",
                },
              }}
            >
              <div>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[{ required: true }]}
                >
                  <PhoneNumberInput
                    onChange={handlePhoneChange}
                  />
                </Form.Item>
                {/* {phoneExists && (
                  <p
                    style={{ color: "red" }}
                  >{`User already exists with this phone number. \n Please login or use the forgot password feature.`}
                   <Button
                                    size="small"
                                    type="link"
                                    onClick={() =>
                                      openLogin()
                                    }
                                  >
                                    {"Login here"}
                                  </Button>
                                </p>
                )} */}

              </div>
              
              {loginMode ? 
                <Button
                shape="round"
                    type="primary"
                    htmlType="submit"
                    className="buttonBG"
                    style={{ width: "100%" }}
              >
                Login
              </Button>
              :

              <>
                <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                <div className={Styles.NormalInput}>
                  <MailOutlined className={Styles.mailIcon} />
                  <Input
                    name="email"
                    placeholder="Email"
                    className={Styles.FormInput}
                    type="email"
                    onChange={handleEmailChange}
                  />
                </div>
              </Form.Item>
              {/* {emailExists && (
                <p
                  style={{ color: "red" }}
                >{`User already exists with this email. \n Please login or use the forgot password feature.`}
               <Button
                                    size="small"
                                    type="link"
                                    onClick={() =>
                                     openLogin()
                                    }
                                  >
                                    {"Login here"}
                                  </Button>
                </p>
              )} */}

              <Row gutter={24}>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                      {
                        max: 25,
                        message: "First name cannot exceed 25 characters",
                      },
                      {
                        validator: noSpecialCharactersValidator(
                          "No special characters allowed in the first name!"
                        ),
                      },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <UserOutlined className={Styles.mailIcon} />
                      <Input
                        name="firstName"
                        placeholder="First Name"
                        className={Styles.FormInput}
                      // maxLength={25}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12} xxl={12} span={12}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                      {
                        max: 25,
                        message: "First name cannot exceed 25 characters",
                      },
                      {
                        validator: noSpecialCharactersValidator(
                          "No special characters allowed in the last name!"
                        ),
                      },
                    ]}
                  >
                    <div className={Styles.NormalInput}>
                      <UserOutlined className={Styles.mailIcon} />
                      <Input
                        name="lastName"
                        placeholder="Last Name"
                        className={Styles.FormInput}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <div className="extraButtonSectionLogin">
                <div className="extrasCenter">
                  <Button
                    shape="round"
                    type="primary"
                    htmlType="submit"
                    className="buttonBG"
                    style={{ width: "100%" }}
                  >
                    Quick Checkout
                  </Button>
                </div>
              </div>
              </>}
            </Form>
          </div>
        </DialogContent>
      </Dialog>


      <VerifyOtpGuest open={verifyOtp} onClose={closeVerifyOtpModal} source={loginMode ? "guestLogin": "register"} />
      <GuestLogin open={isLoginOpen} onClose={closeLogin} />
    </>
  );
};

export default InstantCheckout;
