import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { CalendarOutlined, LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Input,
  Form,
  Button,
  notification,
  Typography,
  Space,
  Row,
  Col,
  Select,
} from "antd";
import _ from "lodash";
import countries from "../../pages/SupplierRegister/Data/countries.json";
import "./login.css";
import Styles from "./FormStyle.module.css";
import OctopusImage from "../../assests/logo.svg";
import { _POST } from "../../common/commonFunc";
import { BASE_URL } from "../../constant/ApplicationRoutes";
import { noSpecialCharactersValidator } from "../../utils/helpers"
import axios from "axios";
import VerifyOtpGuest from "./VerifyOtpGuest";
import { setCookie } from "../../utils/tokenService";

const { Option } = Select;

const GuestLogin = ({ open, onClose }: any) => {
  const [phoneExists, setPhoneExists] = useState(true);
  const [verifyOtp, setVerifyOtp] = useState(false)
  const [form] = Form.useForm<any>();

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any;
    return function (this: any) {
      const context: any = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const closeVerifyOtpModal = () => {
    setVerifyOtp(false);
  }

  const closeDrawer = () => {
    onClose()
    form.resetFields();
    setPhoneExists(false)
  }


  const PhoneNumberInput = ({ value = {}, onChange }: any) => {
    const [number, setNumber] = React.useState(value.number || "");
    const [countryCode, setCountryCode] = React.useState(
      value.countryCode || "+973"
    );

    const triggerChange = (changedValue: any) => {
      onChange?.({
        number,
        countryCode,
        ...value,
        ...changedValue,
      });
    };

    const onNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
      triggerChange({ number: newNumber });
    };

    const onCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
      triggerChange({ countryCode: newCountryCode });
    };

    return (
      <div className={Styles.NormalInput}>
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            value={countryCode}
            onChange={onCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {countries.map((country: any) => (
              <Option key={country.code} value={country.dial_code}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {country.dial_code}
              </Option>
            ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            value={number}
            onChange={onNumberChange}
            placeholder="Enter number"
            className={Styles.FormInput}
          />
        </Input.Group>
      </div>
    );
  };


  const handlePhoneChange = debounce(async (phoneData: { countryCode: string; number: string }) => {
    const { countryCode, number } = phoneData;
    const fullPhoneNumber = `${countryCode}${number}`;

    if (number) {
      try {
        const response = await axios.post(
          `${BASE_URL}checkGuestExists/${fullPhoneNumber}`
        );
        if (response.data.status) {
          setPhoneExists(true);
        } else {
          setPhoneExists(false);
        }
      } catch (error) {
        console.error("Error checking phone number", error);
        setPhoneExists(false);
      }
    } else {
      setPhoneExists(false);
    }
  }, 500);


  const onLogin = async (values: any) => {
    console.log(" onLogin  values:", values);
     const fullPhoneNumber = `${values.phoneNumber.countryCode}${values.phoneNumber.number}`
    if(values.phoneNumber.number === "" || values.phoneNumber.number === " " ){
         notification.error({
                  message: "Please enter phone number",
                  description:
                    "Phone number cannot be empty",
                });
                return
              }
    
    try {
      const res: any = await _POST(
        "octopus/guest/login",
        {
          phone_number: fullPhoneNumber,
        },
        false,
        false
      );
      if (res && res.status === false) {
        notification.info({
          message: res.message,
        });

      }
      const userInfo = {
        full_phone_number: fullPhoneNumber,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));

        if (res.data && res.status) {
          onClose()
          form.resetFields()
          setPhoneExists(true)
          setVerifyOtp(true)
        }

    } catch (error) {
      console.log("🚀 ~ onLogin ~ error:", error);
      return notification.open({
        type: "info",
        message: "Something went wrong!. Please try again",
        description: _.get(error, "message", "error"),
      });
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        className="loginPopUp"
        sx={{
          "& .MuiPaper-root": { borderRadius: 6 },
          "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
            background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
          },
          "& .css-y2taew .MuiPaper-root": {
            background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
          },
          "& .loginPopUp .MuiDialog-paperWidthSm": {
            background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
          },
          "& .MuiDialog-paperWidthSm": {
            background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
          },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeDrawer}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              backgroundColor: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              marginTop: "3%",
              width: "100%",
              height: "100%",
            }}
          >
            <Space
              direction="vertical"
              size="middle"
              align="start"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "8px",
                  marginTop: "8px",
                }}
                src={OctopusImage}
                alt=""
              />
              <Typography.Text
                style={{
                  textAlign: "left",
                  fontFamily: "Ubuntu",
                  fontWeight: "bold",
                  marginTop: "0px",
                  fontSize: "20px",
                }}
              >
                Please enter your phone number to login.
              </Typography.Text>
            </Space>
            <Form
              form={form}
              className="registerContainer"
              name="basic"
              size="large"
              layout="vertical"
              onFinish={onLogin}
              style={{ margin: "3% 15%", width: "70%", height: "80%" }}
              initialValues={{
                country: countries[0].name,
                number: {
                  countryCode: "+973",
                  number: "",
                },
              }}
            >
              <div>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[{ required: true }]}
                >
                  <PhoneNumberInput
                    onChange={handlePhoneChange}
                  />
                </Form.Item>
                {!phoneExists && (
                  <p
                    style={{ color: "red" }}
                  >{`User does not exists with this phone number. \n Please register before login`}</p>
                )}

              </div>
              <div className="extraButtonSectionLogin">
                <div className="extrasCenter">
                  <Button
                    shape="round"
                    type="primary"
                    htmlType="submit"
                    className="buttonBG"
                    style={{ width: "100%" }}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>


      <VerifyOtpGuest open={verifyOtp} onClose={closeVerifyOtpModal} source={"guestLogin"} />
    </>
  );
};

export default GuestLogin;
