import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  styled
} from "@mui/material";
import {
  Button,
  Drawer,
  Space,
  notification,
  Checkbox,
  message,
  Typography
} from "antd";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";
import Styles from "./OrderTable.module.css";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {
  _GET,
  _PATCH,
  _POST,
} from "../../../common/commonFunc";
import { EyeOutlined } from "@ant-design/icons";
import OctopusImage from "../../../assests/octopus_logo.png";
import FooterImg from "../../../assests/whyOctopus.png";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import OrderDetails from "./OrderDetails";
import CancelPopUp from "./CancelPopUp";
import { Popconfirm } from "antd/lib";
import ReturnOrderDetails from "./ReturnOrderDetails"
import ReadyToDispatch from "./ReadyToDispatch";
import RejectReturnModal from "./RejectReturnModal";
import ReadyToDispatchOwn from "./ReadyToDispatchOwn";
import { color } from "framer-motion";
import AllOrdersDrawer from "./AllOrdersDrawer";
import { filterProducts } from "../Utils/Filters";
import jsPDF from "jspdf";
import _ from "lodash";


const OrderTable = ({ headers, data, status, getOrderList }: any) => {

  console.log("data in order table is : ", data)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleReturnModal, setVisibleReturnModal] = useState(false);
  const [visibleAllOrderModal, setVisibleAllOrderModal] = useState(false);
  const [visibleDispatchDrawer, setVisibleDispatchDrawer] = useState(false);
  const [visibleDispatchOwnDrawer, setVisibleDispatchOwnDrawer] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<any>([]);
  const [dispatchData, setDispatchData] = useState<any>({});
  const [returnOrderDetails, setReturnOrderDetails] = useState([]);
  const [allOrderDetails, setAllOrderDetails] = useState([]);
  const [orderData, setOrderData] = useState<any>([]);
  const [selectedOrder, setSelectedOrder] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderItemId, setOrderItemId] = useState();
  const [rejectModal, setRejectModal] = useState(false)
  const [courierId, setCourierId] = useState("")
  const [returnId, setReturnId] = useState("")
  const [orderFilter, setOrderFilter] = useState<string | null>(null);
  const [pageData, setPageData] = useState([])

  const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));
  const userInfo: any = localStorage.getItem("userInfo");
  const SupplierName = `${JSON.parse(userInfo)?.first_name} ${JSON.parse(userInfo)?.last_name}`;

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Transaction ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Transaction ID" });
    }
    event.stopPropagation();
  };

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    // setSelectAll(e.target.checked);
    if (e.target.checked) {
      const newSelectedProducts = data.reduce((acc: any, row: any) => {
        if (
          status !== "orderInTransit" ||
          (status === "orderInTransit" && row.courier_service === "supplier_courier_service")
        ) {
          acc[row.order_item_id] = {
            order_item_id: row.order_item_id,
            selected: true,
            pickupAddrId: row.pickup_address_id,
            courier_service_id: courierId
          };
        }
        // acc[row.order_item_id] = {
        //   order_item_id: row.order_item_id,
        //   selected: true,
        //   pickupAddrId: row.pickup_address_id
        // };
        return acc;
      }, {});
      setSelectedOrders(newSelectedProducts);
    } else {
      setSelectedOrders([]);
    }
  };

  const selectedCount = Object.values(selectedOrders).filter(Boolean).length;

  const getCourierinfo = async () => {
    try {
      const res = await _GET(
        `supplier/allPlatformCourierServcie`,
        true,
        false
      );
      if (res && res.data) {
        setCourierId(res.data[0]?.id)
      }
    } catch (error) {
      console.log("Error fetching orders:", error);
    }
  }

  useEffect(() => {
    getCourierinfo()
  }, [])


  const handleSelectedOrder = (orderId: any, checked: any, pickupAddrId: any) => {
    setSelectedOrders((prev: any) => {
      const updatedOrders = checked
        ? {
          ...prev,
          [orderId]: {
            order_item_id: orderId,
            selected: checked,
            pickupAddrId: pickupAddrId,
            courier_service_id: courierId
          },
        }
        : Object.keys(prev).reduce((acc: any, key: any) => {
          if (key !== orderId) {
            acc[key] = prev[key];
          }
          return acc;
        }, {});

      // const allSelected = Object.values(updatedOrders).every((order : any) => order.selected);
      // setSelectAll(allSelected);

      return updatedOrders;
    });
  };

  const getProductDetails = async (item: any) => {
    try {
      const res: any = await _GET(
        `supplier/customer/fetch/order/${item.order_id}`,
        true
      );
      if (res.status) {
        return res;

      }

    } catch (error) {
      console.error(error);
    }
  }

  const handleConfirmArrival = async (return_id: any) => {
    try {
      const res: any = await _PATCH(
        `supplier/return-confirm-arrival/${return_id}`,
        {},
        true
      );
      if (res.status && res.statusCode === 200) {
        message.success("Successfully confirmed product arrival");
      } else {
        message.error("Failed to confirm product arrival");
      }

    } catch (error) {
      console.error(error);
    }
  }

  const handleOrderComplete = async (selectedOrders: any) => {
    try {
      const orderIds = Object.keys(selectedOrders)
      const payload = {
        order_item_ids: orderIds
      }

      console.log("Payload is : ", payload)
      const res: any = await _PATCH(
        `supplier/markOrderComplete`,
        payload,
        true
      );
      if (res.status && res.statusCode === 200) {
        message.success("Successfully marked product delivery as complete");
        getOrderList();
        setSelectedOrder({});
      } else {
        message.error("Failed to mark product delivered");
      }

    } catch (error) {
      console.error(error);
    }
  }


  const openModal = async (item: any) => {
    const res = await getProductDetails(item)
    if (res.status) {
      setOrderData(res.data);
      setVisibleModal(true);
      setOrderItemId(item.order_item_id);
    }
  };

  const openReturnModal = async (item: any) => {
    setReturnOrderDetails(item);
    setVisibleReturnModal(true);
  };

  const openAllOrderDrawer = async (item: any) => {
    const res = await getProductDetails(item)
    if (res.status) {
      setAllOrderDetails(res.data);
      setVisibleAllOrderModal(true);
      setOrderItemId(item.order_item_id);
    }
  };

  const closeAllOrderDrawer = () => {
    setVisibleAllOrderModal(false)
  };


  const openDispatchDrawer = async (item: any) => {

    if (item) {
      setVisibleDispatchDrawer(true);
      setDispatchData(item);
    }
  };


  const closeDispatchDrawer = () => {
    setVisibleDispatchDrawer(false)
    setSelectedOrders({});
    getOrderList();
  };

  const openDispatchOwnDrawer = async (item: any) => {

    if (item) {
      setVisibleDispatchOwnDrawer(true);
      setDispatchData(item);
    }
  };


  const closeDispatchOwnDrawer = () => {
    setVisibleDispatchOwnDrawer(false)
    setSelectedOrders({});
    getOrderList();
  };

  const closeModal = () => {
    setVisibleModal(false);

  };

  const closeReturnModal = () => {
    setVisibleReturnModal(false)
  };

  const formatOrderId = (id: any) => {
    const prefix = id.substring(0, 2);
    const start = id.substring(8, 10);
    const end = id.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatDateAndTime = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };


  const formatPrice = (currency: any, amount: any) => {
    return (
      <span>
        <span style={{ color: "#aaa" }}>{currency} </span>
        <span style={{ fontWeight: "bold" }}>{amount?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
      </span>
    );
  };



  // const generateInvoicePDF = (row: any) => {
  //   const doc = new jsPDF("p", "mm", "a4");
  //   const floor = _.get(row, "delivery_address.floor", "");
  //   const building_no = _.get(row, "delivery_address.building_no", "");
  //   const address = _.get(row, "delivery_address.address", "");
  //   const city = _.get(row, "delivery_address.city", "");
  //   const state = _.get(row, "delivery_address.state", "");
  //   const country = _.get(row, "delivery_address.country", "");
  //   const pincode = _.get(row, "delivery_address.pincode", "");
    
  //   const imgWidth = 70; 
  //   const imgHeight = 20; 
  //   doc.addImage(OctopusImage, "PNG", 10, 10, imgWidth, imgHeight);
  //   doc.setFontSize(20);
  //   doc.text("Delivery Invoice", 130, 25);

  //   doc.setFontSize(12);
  //   doc.text("Supplier Information :", 10, 45);
  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(10);
  //   doc.text(`${SupplierName}`, 10, 50);
  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(10);
  //   doc.text("Address Line 1", 10, 55);
  //   doc.text("Address Line 2", 10, 60);
  //   doc.text("City, State, Pincode", 10, 65);

  //   doc.setFontSize(12);
  //   doc.text("Billing Address :", 110, 45);
  //   doc.setFontSize(10);
  //   doc.text(`${floor},${building_no},${address}`, 110, 50);
  //   doc.text(`${city}, ${state}`, 110, 55);
  //   doc.text(`${country}, ${pincode}`, 110, 60);

  //   doc.setFontSize(12);
  //   doc.text("Shipping Address :", 110, 70);
  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(10);
  //   doc.text(`${row.customer_details.first_name} ${row.customer_details.last_name} `, 110, 75);
  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(10);
  //   doc.text(`${floor},${building_no},${address}`, 110, 80);
  //   doc.text(`${city}`, 110, 85);
  //   doc.text(`${state}`, 110, 90);
  //   doc.text(`${country}, ${pincode}`, 110, 95);

  //   doc.setFontSize(10);
  //   doc.text(`Order Number: ${row.order_id}`, 10, 110);
  //   doc.text(`Order Date: ${formatDate(row.purchase_date)}`, 10, 115);

    
  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(10);
  //   doc.text("Sr. No", 10, 130);
  //   doc.text("Description", 30, 130);
  //   doc.text("Quantity", 70, 130);
  //   doc.text("Price", 100, 130);
  //   doc.text("Courier", 130, 130);
  //   doc.text("VAT", 150, 130);
  //   doc.text("Total", 180, 130);

    
  //   let yPosition = 140;

  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(10);
  //   doc.text("1.", 10, yPosition);
  //   doc.text(row.product_name, 30, yPosition);
  //   doc.text(`${row.units_ordered}`, 70, yPosition);
  //   doc.text(`BHD ${row.prod_discounted_price}`, 100, yPosition);
  //   doc.text(`BHD ${row.courier_charges_per_delivery ?? 0.00}`, 130, yPosition);
  //   doc.text(`BHD ${row.prod_vat_amount}`, 150, yPosition);
  //   doc.text(`BHD ${row.amount_paid}`, 180, yPosition);
  //   yPosition += 10;
  //   ;

  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(12);
  //   doc.text(`Total: BHD ${row.amount_paid}`, 150, yPosition + 10);

  //   const footerWidth = 100; 
  //   const footerHeight = 60; 
  //   doc.addImage(FooterImg, "PNG", 50, yPosition + 90, footerWidth, footerHeight);

  //   const pdfBlob = doc.output("blob");
  //   const pdfURL = URL.createObjectURL(pdfBlob);
  //   window.open(pdfURL, "_blank");
  // };


  const generateInvoicePDF = (orders: any[]) => {
    const doc = new jsPDF("p", "mm", "a4");
    
    const firstOrder = orders[0];
    
    const floor = _.get(firstOrder, "delivery_address.floor", "");
    const building_no = _.get(firstOrder, "delivery_address.building_no", "");
    const address = _.get(firstOrder, "delivery_address.address", "");
    const city = _.get(firstOrder, "delivery_address.city", "");
    const state = _.get(firstOrder, "delivery_address.state", "");
    const country = _.get(firstOrder, "delivery_address.country", "");
    const pincode = _.get(firstOrder, "delivery_address.pincode", "");
    
    const imgWidth = 70; 
    const imgHeight = 20; 
    doc.addImage(OctopusImage, "PNG", 10, 10, imgWidth, imgHeight);
    doc.setFontSize(20);
    doc.text("Delivery Invoice", 130, 25);
  
    doc.setFontSize(12);
    doc.text("Supplier Information :", 10, 45);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text(`${SupplierName}`, 10, 50);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text("Address Line 1", 10, 55);
    doc.text("Address Line 2", 10, 60);
    doc.text("City, State, Pincode", 10, 65);
  
    doc.setFontSize(12);
    doc.text("Billing Address :", 110, 45);
    doc.setFontSize(10);
    doc.text(`${floor},${building_no},${address}`, 110, 50);
    doc.text(`${city}, ${state}`, 110, 55);
    doc.text(`${country}, ${pincode}`, 110, 60);
  
    doc.setFontSize(12);
    doc.text("Shipping Address :", 110, 70);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text(`${firstOrder.customer_details.first_name} ${firstOrder.customer_details.last_name} `, 110, 75);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${floor},${building_no},${address}`, 110, 80);
    doc.text(`${city}`, 110, 85);
    doc.text(`${state}`, 110, 90);
    doc.text(`${country}, ${pincode}`, 110, 95);
  
    doc.setFontSize(10);
    doc.text(`Order Number: ${firstOrder.order_id}`, 10, 110);
    doc.text(`Order Date: ${formatDate(firstOrder.purchase_date)}`, 10, 115);
  
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("Sr. No", 10, 130);
    doc.text("Description", 30, 130);
    doc.text("Quantity", 70, 130);
    doc.text("Price", 100, 130);
    doc.text("Courier", 130, 130);
    doc.text("VAT", 150, 130);
    doc.text("Total", 180, 130);
  
    let yPosition = 140;
    let totalAmountPaid = 0;
  
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
  
    orders.forEach((row, index) => {
      doc.text(`${index + 1}.`, 10, yPosition);
      doc.text(row.product_name, 30, yPosition);
      doc.text(`${row.units_ordered}`, 70, yPosition);
      doc.text(`BHD ${row.prod_discounted_price}`, 100, yPosition);
      doc.text(`BHD ${row.courier_charges_per_delivery ?? 0.00}`, 130, yPosition);
      doc.text(`BHD ${row.prod_vat_amount}`, 150, yPosition);
      doc.text(`BHD ${row.amount_paid}`, 180, yPosition);
      
      totalAmountPaid += parseFloat(row.amount_paid);
      yPosition += 10;
    });
  
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`Total: BHD ${totalAmountPaid.toFixed(3)}`, 150, yPosition + 10);
  
    const footerWidth = 100; 
    const footerHeight = 60; 
    doc.addImage(FooterImg, "PNG", 55, yPosition + 80, footerWidth, footerHeight);
  
    const pdfBlob = doc.output("blob");
    const pdfURL = URL.createObjectURL(pdfBlob);
    window.open(pdfURL, "_blank");
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const confirmAcceptOrder = async (return_id: any) => {
    try {
      const url = `supplier/acceptReturnRequest/${return_id}`;
      console.log("Url is : ", url);
      const acceptProduct = await _PATCH(
        `supplier/acceptReturnRequest/${return_id}`,
        {},
        true
      );

      if (acceptProduct.data === true && acceptProduct.statusCode === 200) {
        message.success("Successfully accepted product");
        getOrderList();

      } else {
        notification.error({
          message: "Accept Product Failed",
          description: "An error occurred while accepting the product.",
        });
      }
    }
    catch (error) {
      console.error("Error during accepting:", error);
      notification.error({
        message: "Accept Product Error",
        description: "An error occurred during accepting product.",
      });

    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "ORDERED":
        return "orange";
      case "DELIVERED":
        return "green";
      case "COMPLETED":
        return "green";
      case "SHIPPED":
        return "blue";
      case "PREPARED":
        return "pink";
      case "PENDING_TO_RTD":
        return "yellow";
      case "CANCELLED":
        return "grey";
      case "RETURNED":
        return "red";
      default:
        return "red";
    }
  };

  const renderActionButtons = (row: any) => {
    const currentStatus = status;

    switch (currentStatus) {
      case "returnOrder":
        return (
          <div className={Styles.actionButtons}>
            <Popconfirm
              title="Are you sure you want to accept this order ?"
              onConfirm={() => confirmAcceptOrder(row?.return_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className={Styles.accept}
              >
                Accept
              </Button>
            </Popconfirm>
            <Button
              className={Styles.buttonDark}
              onClick={() => openRejectModal(row.return_id)}
            >
              Reject
            </Button>
            <Button className={Styles.eyeOutlined} onClick={() => openReturnModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      case "allOrders":
        return (
          <div className={Styles.actionButtons}>
            <Button className={Styles.eyeOutlined} onClick={() => openAllOrderDrawer(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      case "pendingOrders-octopus":
        return (
          <div className={Styles.actionButtons}>
            <Button
              className={Styles.buttonDark}
              onClick={(event) => confirmCancelOrder(row, event)}
            >
              Cancel
            </Button>
            <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      case "pendingOrders-own":
        return (
          <div className={Styles.actionButtons}>
            <Button
              className={Styles.buttonDark}
              onClick={(event) => confirmCancelOrder(row, event)}
            >
              Cancel
            </Button>
            <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      case "preparedOrders":
        return (
          <div className={Styles.actionButtons}>
            <Button
              className={Styles.buttonDark}
              onClick={(event) => confirmCancelOrder(row, event)}
            >
              Cancel
            </Button>
            <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      case "orderHandover":
        return (
          <div className={Styles.actionButtons}>
            <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      // case "orderInTransit":
        // return (
        //   <div className={Styles.actionButtons}>
        //     {row.courier_service_choice === "octopus_courier_service" &&
        //       row.courier_status === "Unassigned" &&
        //       <Button
        //         className={Styles.buttonDark}
        //         onClick={(event) => confirmCancelOrder(row, event)}
        //       >
        //         Cancel
        //       </Button>}
        //     <Button
        //       className={Styles.accept}
        //       onClick={() => generateInvoicePDF(row)}
        //     >
        //       Generate Label
        //     </Button>
        //     <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
        //       <EyeOutlined />
        //     </Button>
        //   </div>
        // );
        case "orderInTransit":
          return (
            <div className={Styles.actionButtons}>
              {row.courier_service_choice === "octopus_courier_service" &&
                row.courier_status === "Unassigned" &&
                <Button
                  className={Styles.buttonDark}
                  onClick={(event) => confirmCancelOrder(row, event)}
                >
                  Cancel
                </Button>}
              <Button
                className={Styles.accept}
                onClick={() => {
                  const ordersToGenerate = data.filter(
                    (order: any) => order.order_id === row.order_id
                  );
                  generateInvoicePDF(ordersToGenerate);
                }}
              >
                Generate Label
              </Button>
              <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
                <EyeOutlined />
              </Button>
            </div>
          );
        case "orderCompleted":
        return (
          <Button className={Styles.eyeOutlined} onClick={() => openModal(row)}>
            <EyeOutlined />
          </Button>
        );
      case "pendingToRtd":
        return (
          <Popconfirm
            title="Are you sure you want to confirm product arrival ?"
            onConfirm={() => handleConfirmArrival(row?.return_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className={Styles.accept}
            >
              Confirm Arrival
            </Button>
          </Popconfirm>
        );
      default:
        return null;
    }
  };

  let FileredOrders = [];




  const confirmCancelOrder = (row: any, event: any) => {
    console.log("🚀 ~ confirmCancelService ~ row:", row.id);
    event.preventDefault();
    setSelectedOrder(row);
    setIsModalOpen(true);
  };

  const handleCancelOrder = async (orderId: any, order_item_id: any, reason: any, categoryId: any, category: any) => {
    const payload = {
      order_id: orderId,
      reason: reason,
      category: category,
      cancel_category_id: categoryId,
      order_item_id: order_item_id

    };
    try {
      const cancelOrderResponse = await _POST(
        `supplier/customer/cancel/product/order`,
        payload,
        true
      );
      if (cancelOrderResponse.status && cancelOrderResponse.statusCode === 200) {
        showSuccessCustomModal({
          title: "Order canceled successfully",
          smallText: "Your order has been cancelled successfully.",
          buttonText: "Continue",
        });
        getOrderList()
      } else {
        console.error(
          cancelOrderResponse.error
            ? cancelOrderResponse.error
            : "Order cancellation failed"
        );
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
    }

  };

  const handleRTD = async (selectedOrders: any) => {
    try {

      const orderId = Object.keys(selectedOrders);
      console.log("Pickupdate and time values are : ", orderId);

      const payload = {
        order_item_ids: orderId,
      };

      console.log('Payload:', payload);

      const response = await _PATCH(
        `supplier/ready-to-delivery`,
        payload,
        true
      );

      if (response.status === true) {
        message.success("Order shipped successfully!");
        getOrderList();
        setSelectedOrder({});
      } else {
        message.error(
          response.data && response.message
            ? response.message
            : "Error in shipping order"
        );
      }

    } catch (error) {
      console.error("Error in shipping order:", error);
    }
  };

  const openRejectModal = (returnId: string) => {
    setReturnId(returnId)
    setRejectModal(true);
  }

  const closeRejectModal = () => {
    setRejectModal(false);
    getOrderList();
  }

  const handleOrderFilter = (orderId: any) => {
    if (orderId === orderFilter) {
      setOrderFilter(null);
    } else {
      setOrderFilter(orderId);
    }
  };

  useEffect(() => {

    if (orderFilter) {
      setPage(0)
      FileredOrders = data?.filter((order: any) => {
        return order.order_id === orderFilter
      })
      setPageData(FileredOrders?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    }

  }, [orderFilter, page, rowsPerPage])

  const dataToDisplay = orderFilter
    ? pageData
    : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <CancelPopUp
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(values) => handleCancelOrder(selectedOrder.order_id, selectedOrder.order_item_id, values.reason, values.categoryId, values.category)}
        id={selectedOrder.order_id}
      />
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map(
                  (header: any) =>
                  (
                    <TableCell
                      key={header.id}
                      style={{
                        width: header.width,
                        textAlign: "center",
                      }}
                    >
                      {header.label === "TRANSACTION ID"
                        && status !== "orderCompleted"
                        && status !== "returnOrder"
                        && status !== "pendingToRtd"
                        && status !== "allOrders"
                        && data.length > 0 ? (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
                          <Checkbox
                            checked={Object.keys(selectedOrders).length === data.length}
                            onChange={handleSelectAll}
                            aria-label='select all rows'
                            className={Styles.customCheckbox}
                          />{header.label}
                        </div>
                      ) : (
                        header.label
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                dataToDisplay
                  .map((row: any, index: any) => (
                    <TableRow key={index}>
                      {headers.map((header: any) => (
                        <TableCell
                          key={`${header.id}-${index}`}
                          style={{
                            width: header.width,
                            textAlign: "center",
                          }}
                        >
                          {header.id === "transaction_id"
                            ?
                            (<div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                              {header.label === "TRANSACTION ID"
                                && status !== "orderCompleted"
                                && status !== "returnOrder"
                                && status !== "pendingToRtd"
                                && status !== "allOrders"
                                && data.length > 0 &&
                                <div>
                                  {status === "orderInTransit" ? row.courier_service_choice === "octopus_courier_service" ?
                                    "" :
                                    <Checkbox
                                      checked={selectedOrders[row.order_item_id] || false}
                                      onChange={(e) => handleSelectedOrder(row.order_item_id, e.target.checked, row.pickup_address_id)}
                                      aria-label={`select row ${index}`}
                                      className={Styles.customCheckbox}
                                    />
                                    :
                                    <Checkbox
                                      checked={selectedOrders[row.order_item_id] || false}
                                      onChange={(e) => handleSelectedOrder(row.order_item_id, e.target.checked, row.pickup_address_id)}
                                      aria-label={`select row ${index}`}
                                      className={Styles.customCheckbox}
                                    />}
                                </div>
                              }
                              <LightTooltip
                                title={
                                  <>
                                    <div style={{ fontWeight: "bold" }}>
                                      Click to Copy :
                                    </div>
                                    <br />
                                    <div>{row.order_id}</div>
                                  </>
                                }
                                placement="bottom"
                                arrow
                              >
                                <span
                                  onClick={(event) =>
                                    copyToClipboard(row.order_id, event)
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {formatOrderId(row.order_id)}
                                </span>
                              </LightTooltip>
                              <Button className={Styles.eyeOutlined} onClick={() => handleOrderFilter(row.order_id)}>
                                {orderFilter ?
                                  <Tooltip title="Click to clear the filter">
                                    <FilterAltOffIcon />
                                  </Tooltip>
                                  :
                                  <Tooltip title={`Click to filter based on order id : ${row.order_id}`}>
                                    <FilterAltIcon />
                                  </Tooltip>
                                }
                              </Button>

                            </div>)
                            : header.id === "product"
                              ? <div className={Styles.prodNameDiv}>
                                <img
                                  src={row.product_images[0]}
                                  alt={row.product_name}
                                  className={Styles.productImage}
                                />
                                <span>{row.product_name}</span>
                              </div>
                              : header.id === "product_img"
                                ? <div className={Styles.prodNameDiv}>
                                  <img
                                    src={row?.prod_images[0] ?? ""}
                                    alt={row.product_name}
                                    className={Styles.productImage}
                                  />
                                  <span>{row.product_name}</span>
                                </div>
                                : header.id === "order_date"
                                  ? formatDate(row.purchase_date)
                                  : header.id === "quantity"
                                    ? row.units_ordered
                                    : header.id === "paid"
                                      ? formatPrice(row.amount_paid_ccy, row.amount_paid)
                                      : header.id === "action"
                                        ? renderActionButtons(row)
                                        : header.id === "status"
                                          ? <Typography style={{ color: `${getStatusColor(row.order_item_status)}` }}>{row.order_item_status}</Typography>
                                          : header.id === "return_category"
                                            ? "-"
                                            : header.id === "completed_date_time"
                                              ? formatDate(row.order_delivery_date_time_unix)
                                              : header.id === " cancelled_date_time"
                                                ? row.order_cancelled_date_time_unix ? formatDate(row.order_cancelled_date_time_unix) : "-"
                                                : header.id === "logisctic_partner"
                                                  ? row.logistic_partner ?? "-"
                                                  : header.id === "sheduled_date_and_time" || header.id === "handover_date_time"
                                                    ? <>
                                                      {row.order_pickup_date !== "NULL" ? row.order_pickup_date ?? "-" : "-"}
                                                      {" "}
                                                      {row.order_pickup_time !== "NULL" ? row.order_pickup_time ?? "-" : "-"}
                                                    </>
                                                    : header.id === "price"
                                                      ? formatPrice("BHD", row.price_per_unit)
                                                      : row[header.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>

              {status === "pendingOrders-octopus" ?
                <Button
                  className={Styles.readyToDispatch}
                  style={{ display: selectedCount > 0 ? 'block' : 'none' }}
                  onClick={() => openDispatchDrawer(selectedOrders)}
                >
                  ({selectedCount}){" "}Ready to Dispatch
                </Button>
                :
                status === "pendingOrders-own" ?
                  <Button
                    className={Styles.readyToDispatch}
                    style={{ display: selectedCount > 0 ? 'block' : 'none' }}
                    onClick={() => openDispatchOwnDrawer(selectedOrders)}
                  >
                    ({selectedCount}){" "}Ready to Dispatch
                  </Button>
                  :
                  status === "orderInTransit" ?
                    <Button
                      className={Styles.readyToDispatch}
                      style={{ display: selectedCount > 0 ? 'block' : 'none' }}
                      onClick={() => handleOrderComplete(selectedOrders)}
                    >
                      ({selectedCount}){" "}Mark Order Completed
                    </Button>
                    :
                    status === "preparedOrders" ?
                      <>
                        <Popconfirm
                          title="Are you sure you want to ship the order(s)?"
                          onConfirm={() => handleRTD(selectedOrders)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button className={Styles.readyToDispatch}
                            style={{ display: selectedCount > 0 ? 'block' : 'none' }}
                          >
                            ({selectedCount}){" "} Ready to Delivery
                          </Button>
                        </Popconfirm>
                      </>
                      :

                      status === "orderHandover" ?
                        <Button className={Styles.readyToDispatch} style={{ display: selectedCount > 0 ? 'block' : 'none' }}>
                          ({selectedCount}){" "}Generate Labels
                        </Button>
                        : ""
              }

            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>

      <OrderDetails
        visible={visibleModal}
        onClose={closeModal}
        data={orderData}
        itemId={orderItemId}
      />
      <AllOrdersDrawer
        visible={visibleAllOrderModal}
        onClose={closeAllOrderDrawer}
        data={allOrderDetails}
        itemId={orderItemId}
      />

      <ReturnOrderDetails
        visible={visibleReturnModal}
        onClose={closeReturnModal}
        data={returnOrderDetails}
        fetchData={getOrderList}
      />

      <ReadyToDispatch
        visible={visibleDispatchDrawer}
        onClose={closeDispatchDrawer}
        data={dispatchData}
      />

      <ReadyToDispatchOwn
        visible={visibleDispatchOwnDrawer}
        onClose={closeDispatchOwnDrawer}
        data={dispatchData}
      />

      <RejectReturnModal
        isVisible={rejectModal}
        onClose={closeRejectModal}
        return_id={returnId}
        fetchData={getOrderList}
      />

    </>
  );
};

export default OrderTable;
