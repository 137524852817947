import React, { useState } from "react";
import Styles from "./Eventcart.module.css";
import profile from "../../../assests/profile1.png";
import EventBookingDrawer from "../../Events/ServiceDetails/EventBookingDrawer";
import { _DELETE, _POST } from "../../../common/commonFunc";
import { Button, Modal, notification } from "antd";
import {
  deleteCartItem,
  deleteCartEventItem,
} from "../redux/actions/bookingActions";
import DeleIcon from "../../../assests/delIco.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { fetchCartItems, resetCartFetched } from "../../../redux/actions/cartActions";

interface Cartitem {
  customerId: any;
  eventData: any;
  itemId: string;
  type: string;
  quantity: number;
  service_category_main: string;
  service_name?: string;
  service_images?: string | any;
  upfront_payment_amount?: any;
  id?: string;
  itemPic?: string;
  itemCategory?: string;
  itemName?: string;
  itemPrice?: any;
  eventMode?: string;
}
interface Props {
  item: Cartitem;
}
function Eventcartcontainer({ item }: Props) {
  const [quantity, setQuantity] = useState(item.quantity || 0);
  const [finalPrice, setFinalPrice] = useState(item.itemPrice || 0);
  const [isBookingDrawerVisible, setIsBookingDrawerVisible] = useState(false);
    const [isCheckoutClick, setIsCheckoutClick] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const userdata: any = localStorage.getItem("userInfo");
  const parsedUserData = JSON.parse(userdata);
  const userId = parsedUserData?.id;

  const dispatch = useDispatch();

  const showDeleteConfirm = () => {
    setIsModalVisible(true);
  };

  const openBookingDrawer = () => {
    setIsCheckoutClick(true); // Indicate the drawer was opened due to "Checkout"
    setIsBookingDrawerVisible(true);
  };

  const closeBookingDrawer = () => {
    setIsCheckoutClick(false); // Reset the flag when the drawer closes
    setIsBookingDrawerVisible(false);
  };


  const increaseQuantity = () => {
    setQuantity(quantity + 1);
    increasePrice();
  };
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      decreasePrice();
    }
  };
  const increasePrice = () => {
    setFinalPrice(finalPrice * (quantity + 1));
  };

  const decreasePrice = () => {
    setFinalPrice(item.itemPrice * (quantity - 1));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async () => {
    try {
      const url = `cart/removeItem/event/${userId}/${item.itemId}`;
      const response = await _DELETE(url, true);
      dispatch(deleteCartEventItem(item.itemId));
      notification.success({
        message: "Item Deleted",
        description: "The item has been successfully removed from the cart.",
      });
      setIsModalVisible(false);
      dispatch(resetCartFetched());
      
      dispatch(fetchCartItems());
      // window.location.reload();
    } catch (error) {
      console.error("Error in deleting item:", error);
    }
  };

  return (
    <div>
      <Modal
        title=""
        open={isModalVisible}
        onCancel={handleCancel}
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              key="back"
              onClick={handleCancel}
              style={{
                marginRight: "10px",
                backgroundColor: "#31263e",
                color: "white",
                border: "none",
                fontSize: "16px",
                fontWeight: "bold",
                // paddingTop: "10px",
                // paddingBottom: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "15px",
              }}
            >
              No, keep it
            </Button>
            <Button
              style={{
                color: "white",
                border: "none",
                fontSize: "16px",
                fontWeight: "bold",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "15px",
              }}
              key="submit"
              type="primary"
              onClick={handleDelete}
            >
              Yes, delete it
            </Button>
          </div>
        }
      >
        <img
          src={DeleIcon}
          alt="Delete"
          style={{ display: "block", margin: "auto" }}
        />
        <h1 style={{ marginTop: "20px" }}>
          Are you sure you <br />
          want to delete?
        </h1>
      </Modal>
      <EventBookingDrawer
        visible={isBookingDrawerVisible}
        onClose={closeBookingDrawer}
        eventData={item.eventData}
        customerId={item.customerId}
        _POST={_POST}
        closeFun={closeBookingDrawer}
        triggerBillingSummary={isCheckoutClick}
      />
      <div className={Styles.eventcartcardcontainer}>
        <div className={Styles.eventcartcard}>
          <div className={Styles.eventcartcardcontent}>
            <div>
              <Link
                to={{
                  pathname: `${APPLICATION_ROUTES.EVENT_DETAILS}/${item.itemId}`,
                  state: {
                    serviceData: item,
                    categoriesInfo: "none",
                    providerDetails: "none",
                  },
                }}
              >
                <img
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  src={item.itemPic}
                  alt="notavailable"
                />
              </Link>
            </div>
            <div className={Styles.eventcartcarddetails}>
              <div>
                <Link
                  to={{
                    pathname: `${APPLICATION_ROUTES.EVENT_DETAILS}/${item.itemId}`,
                    state: {
                      serviceData: item,
                      categoriesInfo: "none",
                      providerDetails: "none",
                    },
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <h4 style={{ margin: 0, padding: 0 }}>{item.eventMode}</h4>
                  <h4 style={{ margin: 0, padding: 0 }}>{item.itemName}</h4>
                  <h4 style={{ margin: 0, padding: 0 }}>time</h4>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <p>
              <span>
                <p>BHD{finalPrice}</p>
              </span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40%",
            alignItems: "center",
          }}
        >
          <div className={Styles.quantitybuttoncontainer}>
            <button style={{ marginLeft: "6px" }} onClick={decreaseQuantity}>
              -
            </button>
            <span style={{ marginLeft: "2px" }}>{quantity}</span>
            <button style={{ marginLeft: "2px" }} onClick={increaseQuantity}>
              +
            </button>
          </div>
          <div className={Styles.eventbuttoncontainer}>
            <button
              onClick={showDeleteConfirm}
              className={Styles.deletedcartcard}
            >
              Delete
            </button>
            <button
              onClick={openBookingDrawer}
              className={Styles.checkoutcartbutton}
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Eventcartcontainer;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
