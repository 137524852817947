import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import { Button, message, notification } from "antd";
import { Visibility } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Styles from "./Table.module.css";
import { _GET, _POST } from "../../../common/commonFunc";
import ProductDetailsDrawer from "./ProductDetailsDrawer";
import ProductReturnedDrawer from "./ProductReturnedDrawer";
import ProductDeliveredDrawer from "./ProductDeliveredDrawer";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import CancelPopUp from "../../../pages/SupplierScreens/ManageOrders/CancelPopUp";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";
import ProductCancelledDrawer from "./ProductCancelledDrawer";



const ProductsTable = ({
  headers,
  data,
  status,
  fetchData,
  activeTab,
}: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userOrderData, setUserOrderData] = useState<any>([]);
  const [deliveredDrawerData, setDeliveredDrawerData] = useState<any>([]);
  const [returnedDrawerData, setReturnedDrawerData] = useState<any>([]);
  const [cancelDrawerData, setCancelDrawerData] = useState<any>([]);
  const [userOrderDrawerVisible, setUserOrderDrawerVisible] = useState(false);
  const [orderDeliverDrawerVisible, setOrderDeliverDrawerVisible] = useState(false);
  const [orderReturnDrawerVisible, setOrderReturnDrawerVisible] = useState(false);
  const [orderCancelDrawerVisible, setOrderCancelDrawerVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>([]);
  const [mainTableData, setMainTableData] = useState<any>([])

  console.log("Data in products table : ", data)


  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const confirmCancelOrder = (row: any, event: any) => {
    console.log("🚀 ~ confirmCancelOrder ~ row:", row.id);
    event.preventDefault();
    setSelectedOrder(row);
    setIsModalOpen(true);
  };

  const formatPrice = (currency: any, amount: any, isCouponUsed : boolean) => {
    if(isCouponUsed){
      return (
      <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <div style={{display:"flex", flexDirection:"row"}}>
      <span>
      <span style={{ color: "#aaa" }}>{currency} </span>
      <span style={{ fontWeight: "bold" }}>{amount ?? 0}</span>
    </span>
    </div>
    <span
              className={Styles.gradientText}
              style={{
                WebkitBackgroundClip: "text",
              }}
            >
              Coupon discount
            </span>
    </div>)
    }else{
    return (
      <span>
        <span style={{ color: "#aaa" }}>{currency} </span>
        <span style={{ fontWeight: "bold" }}>{amount ?? 0}</span>
      </span>
    );
  }
  };

  const renderActionButtons = (row: any) => {
    console.log("ROW IS", row);
    switch (status) {
      case "Active":
        return (
          <div className={Styles.actionButtons}>
           {row.order_item_status === "ORDERED" && <Button
              className={Styles.cancel}
              onClick={(event) => confirmCancelOrder(row, event)}
            >
              Cancel
            </Button>}
            <Button
              className={Styles.eye}
              onClick={() => openOrderDrawerModal(row)}
            >
              <Visibility />
            </Button>
          </div>
        );
      case "Delivered":
        return (
          <div className={Styles.actionButtons}>
            {/* <Button
              onClick={() => refundEventBookingDrawer(row)}
              className={`${
                row.is_refund_requested ? Styles.rescheduled : Styles.reschedule
              }`}
              disabled={row.is_refund_requested}
            >
              {row.is_refund_requested
                ? "Refund Initiated"
                : "Request a Refund"}
            </Button> */}
            <Button
              className={Styles.eye}
              onClick={() => openDeliverDrawerModal(row)}
            >
              <Visibility />
            </Button>
          </div>
        );
      case "Cancelled":
        return (
          <Button
            className={Styles.eye}
            onClick={() => openCancelledDrawer(row)}
          >
            <Visibility />
          </Button>
        );
      case "Returned":
        return (
          <Button
            className={Styles.eye}
            onClick={() => openReturnedDrawer(row)}
          >
            <Visibility />
          </Button>
        );
      default:
        return null;
    }
  };


  const openOrderDrawerModal = async (order: any) => {
    console.log("order is", order);

    try {
      const res: any = await _GET(
        `supplier/customer/fetch/order/${order.order_id}`,
        true
      );
      if (res.status) {
        setUserOrderData(res.data);
        setUserOrderDrawerVisible(true);
        setMainTableData(order)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openDeliverDrawerModal = async (order: any) => {
    try {
      const res: any = await _GET(
        `supplier/customer/fetch/order/${order.order_id}`,
        true
      );
      if (res.status) {
        setDeliveredDrawerData(res.data);
        setOrderDeliverDrawerVisible(true);
        setMainTableData(order)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openReturnedDrawer = async (order: any) => {
    try {
      const res: any = await _GET(
        `supplier/customer/fetch/order/${order.order_id}`,
        true
      );
      if (res.status) {
        setReturnedDrawerData(res.data);
        setOrderReturnDrawerVisible(true);
        setMainTableData(order)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openCancelledDrawer = async (order: any) => {
    try {
      const res: any = await _GET(
        `supplier/customer/fetch/order/${order.order_id}`,
        true
      );
      if (res.status) {
        setCancelDrawerData(res.data);
        setOrderCancelDrawerVisible(true);
        setMainTableData(order)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeOrderDrawer = () => {
    setUserOrderDrawerVisible(false);
    setOrderDeliverDrawerVisible(false);
    setOrderReturnDrawerVisible(false);
    setOrderCancelDrawerVisible(false);
    setMainTableData([])
  };

  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const formatDate = (startTime: any, endTime: any) => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);
    const date = startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const time = `${startDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${endDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    return (
      <div>
        <div>{date}</div>
        <div>{time}</div>
      </div>
    );
  };

  const formatOrderDate = (unixTimestamp: number) => {

    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };


  const handleCancelOrder = async (orderId: any, order_item_id : any, reason: any, categoryId: any, category: any) => {
    const payload = {
      order_id: orderId,
      reason: reason,
      category: category,
      cancel_category_id: categoryId,
      order_item_id: order_item_id,
    };
    try {
      const cancelOrderResponse = await _POST(
        `supplier/customer/cancel/product/order`,
        payload,
        true
      );
      if (cancelOrderResponse.status && cancelOrderResponse.statusCode === 200) {
        showSuccessCustomModal({
          title: "Order cancelled successfully",
          smallText: "Your order has been cancelled successfully.",
          buttonText: "Continue",
        });
        fetchData()
      } else {
        console.error(
          cancelOrderResponse.error
            ? cancelOrderResponse.error
            : "Order cancellation failed"
        );
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
    }

  };

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Order ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Order ID" });
    }
    event.stopPropagation();
  };

  const formatOrderId = (id: any) => {
    const prefix = id?.substring(0, 2);
    const start = id?.substring(8, 10);
    const end = id?.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const formatDateOfOrder = (unixTimestamp: number) => {
    const date = moment.unix(unixTimestamp);
    return date.format('MMM DD, YYYY');
  };

  return (
    <>
      {/* <PopupForm
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(reason) => handleCancelOrder(selectedService.id, reason)}
        id={selectedService.id}
        text="order"
      /> */}

      <CancelPopUp
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(values) => handleCancelOrder(selectedOrder.order_id, selectedOrder.order_item_id, values.reason, values.categoryId, values.category)}
        id={selectedOrder.order_id}
      />
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map((header: any) => (
                  <TableCell
                    key={header.id}
                    style={{
                      width: header.width,
                      textAlign: "center",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <TableRow key={`${row.id}+${index}`}>
                      {headers.map((header: any) => (
                        <TableCell
                          key={`${header.id}-${index}`}
                          style={{
                            width: header.width,
                            textAlign: "center",
                          }}
                        >
                          {header.id === "paid" ? (
                             row.is_coupon_used ? 
                             formatPrice('BHD', row.amount_paid, true) 
                             : formatPrice('BHD', row?.amount_paid, false)
                          ) : header.id === "action" ? (
                            renderActionButtons(row)
                          ) : header.id === "orders" ? (
                            <div className={Styles.prodNameDiv}>
                              <img
                                src={row.product_images[0]}
                                alt={row.product_images[0] ? (row?.product_name[0] + row?.product_name[1]?.toUpperCase()) : "Default Alt Text"}
                                className={Styles.productImage}
                              />

                              <span>{row.product_name}</span>
                            </div>
                          ) : header.id === "shipping_address" ? (
                            typeof (row?.delivery_address) !== "object" ?
                              row.delivery_address : `${row?.delivery_address.floor}, 
                            ${row?.delivery_address.address}, 
                            ${row?.delivery_address.landmark}, 
                            ${row?.delivery_address.building_no}, 
                            ${row?.delivery_address.country}`
                          ) : header.id === "status" ? (
                            row.status
                          ) : header.id === "id" ? (
                            <LightTooltip
                              title={
                                <>
                                  <div style={{ fontWeight: "bold" }}>
                                    Click to Copy :
                                  </div>
                                  <br />
                                  <div>{row?.order_id}</div>
                                </>
                              }
                              placement="bottom"
                              arrow
                            >
                              <span
                                onClick={(event) =>
                                  copyToClipboard(row?.order_id, event)
                                }
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                {formatOrderId(row?.order_id)}
                              </span>
                            </LightTooltip>
                          ) : header.id === "order_date_delivered" ? (
                            formatOrderDate(row.purchase_date)
                          ) : header.id === "order_date" ? (
                            formatDateOfOrder(row.purchase_date)
                          ) : header.id === "delivered_on" ? (
                            "-"
                          ) : header.id === "return_reason" ? (
                            "-"
                          ) : header.id === "returned_on" ? (
                            "-"
                          ) : header.id === "cancelled_on" ? (
                            "-"
                          ) : header.id === "paid__delivered" ? (
                            <>
                              {row.amount_paid_ccy}{" "}{row.amount_paid}
                            </>
                          ) : header.id === "shipping_address__delivered" ? (
                            `${row.delivery_address.floor}, 
                            ${row.delivery_address.address}, 
                            ${row.delivery_address.landmark},
                             ${row.delivery_address.building_no}, 
                             ${row.delivery_address.country}`
                          )
                            : (
                              row[header.id]
                            )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
              {data.length > 3 && rowsPerPage > 3 && (
                <button onClick={handleShowLess} className={Styles.showLess}>
                  Show Less
                </button>
              )}
              {data.length > page * rowsPerPage + rowsPerPage && (
                <button onClick={handleViewMore} className={Styles.viewMore}>
                  View More
                </button>
              )}
            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>
      <ProductDetailsDrawer
        visible={userOrderDrawerVisible}
        onClose={closeOrderDrawer}
        data={userOrderData}
        mainTableData = {mainTableData}
      />
      <ProductReturnedDrawer
        visible={orderReturnDrawerVisible}
        onClose={closeOrderDrawer}
        data={returnedDrawerData}
        mainTableData={mainTableData}
      />

      <ProductCancelledDrawer
        visible={orderCancelDrawerVisible}
        onClose={closeOrderDrawer}
        data={cancelDrawerData}
        mainTableData={mainTableData}
      />

      <ProductDeliveredDrawer
        visible={orderDeliverDrawerVisible}
        onClose={closeOrderDrawer}
        data={deliveredDrawerData}
        fetchData={fetchData}
        mainTableData={mainTableData}
      />

    </>
  );
};

export default ProductsTable;
