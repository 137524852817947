import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Row, Space, Steps, Tag, notification } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import { Box, Step, StepIcon, StepLabel, Stepper, Typography, createTheme, makeStyles, styled } from "@mui/material";
import Styles from "./OrderTable.module.css"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import CancelPopUp from "./CancelPopUp";

interface ProductDrawerProps {
    visible: boolean;
    onClose: () => void;
    data: any;
    itemId : any;
}

const OrderDetails: React.FC<ProductDrawerProps> = ({
    visible,
    onClose,
    data,
    itemId
}) => {
    const [product, setProduct] = useState<any>([]);
    console.log("data in orderDetails supplier side : ", data)

    const LightTooltip = styled(({ className, ...props }: any) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.white,
          color: "#30263E",
          boxShadow: "0px 8px 24px 0px #422F9B1A",
          fontSize: 12,
          maxWidth: "271.44px",
        },
      }));

    const formatDate = (unixTimestamp: number) => {
        const date = new Date(unixTimestamp * 1000);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    const formatTime = (unixTimestamp: number) => {
        const date = new Date(unixTimestamp * 1000);
        return date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
    };

    const formatOrderId = (id: any) => {
        const prefix = id?.substring(0, 2);
        const start = id?.substring(8, 10);
        const end = id?.substring(id.length - 2);
        return `${prefix}${" : "}${start}..${end}`;
      };

      const copyToClipboard = async (text: any, event: any) => {
        event.stopPropagation();
        try {
          await navigator.clipboard.writeText(text);
          notification.success({ message: "Order ID copied to clipboard" });
        } catch (err) {
          notification.error({ message: "Failed to copy Order ID" });
        }
        event.stopPropagation();
      };

    const getStatusColor = (status: string) => {
        switch (status) {
            case "ORDERED":
                return "orange";
            case "PREPARED":
                return "blue";
            case "SHIPPED":
                return "purple";
            case "RETURNED":
                return "red";
            case "DELIVERED":
                return "green";
            case "COMPLETED":
                return "green";
            default:
                return "grey";
        }
    };

    const getActiveStep = (status : string) => {
        switch (status) {
            case 'ORDERED':
                return 1;
            case 'PREPARED':
                return 2;
            case 'SHIPPED':
                return 3;
            case 'DELIVERED':
                return 4;
            case 'COMPLETED':
                return 4;
            default:
                return 1; 
        }
    };

    const address = _.get(data, "delivery_address.address", "");
    const city = _.get(data, "delivery_address.city", "");
    const state = _.get(data, "delivery_address.state", "");
    const country = _.get(data, "delivery_address.country", "");
    const pincode = _.get(data, "delivery_address.pincode", "");
    const fullAddress = `${address}, ${city}, ${state}, ${country} - ${pincode}`;
    const steps = ['Order Placed', 'Order Shipped', 'Out for delivery', 'Delivered']


    
const CustomStepIcon = styled(StepIcon)({
    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`, 
    '&.Mui-active': {
       color: 'linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)'
    },
    '&.Mui-completed': {
        backgroundImage:
          "linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%);",
    },
    '&.MuiStepIcon-text': {
        color: 'linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)'
    }
    
   });

   console.log("item id is : ", itemId)

   useEffect(() => {
    setProduct(data?.productsOrdered?.filter((item : any) => item.order_item_id === itemId))
}, [data, itemId])

  
   useEffect(() => {
    
    console.log("Selected product ordered : ", product)
   },[product])
   

    return (
        product?.length > 0 ? product
        .map((item: any, index: any) => (
        <Drawer
            title="Order Details"
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            key="right"
            width={"550px"}
            height={"100%"}
            extra={
                <Space style={{ zIndex: 100 }}>
                    <Button onClick={onClose} className="elevatedElement iconBtn">
                        <Cross />
                    </Button>
                </Space>
            }
        >
            <div style={{ marginBottom: "30px" }}>
                <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "space-between" }}>
                    <Tag color={getStatusColor(item?.order_item_status)}>
                        {item?.order_item_status}
                    </Tag>
                    <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                        PAID  <div style={{ color: "grey", marginLeft: "3px", fontWeight: "200" }}>
                            {item?.amount_paid_ccy}
                        </div>
                        <div style={{ color: "black", marginLeft: "3px" }}>
                            {item?.amount_paid}
                        </div>
                    </Typography>
                </Box>
                <Box component="div" sx={{ display: "flex", flexDirection: "column" }} marginTop={1} marginBlockEnd={2}>
                    <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                        ID | <div style={{ color: "black", marginLeft: "3px" }}>
                        <LightTooltip
                                title={
                                  <>
                                    <div style={{ fontWeight: "bold" }}>
                                      Click to Copy :
                                    </div>
                                    <br />
                                    <div>{item?.order_item_id}</div>
                                  </>
                                }
                                placement="bottom"
                                arrow
                              >
                                <span
                                  onClick={(event) =>
                                    copyToClipboard(item?.order_item_id, event)
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {formatOrderId(item?.order_item_id)}
                                </span>
                              </LightTooltip>
                            </div>
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'} gutterBottom>
                        <div style={{ display: "flex", flexDirection: "row", marginRight: "40px" }}>
                            <div style={{ marginRight: "50px" }}> Order date: {formatDate(data?.purchase_date)}
                            </div>
                            {/* <div> Delivery date: Not Confirmed

                            </div> */}
                        </div>
                    </Typography>
                </Box>
                {/* dfljgfldkjg */}

                <Box component="div" sx={{ display: "flex", flexDirection: "column", background: "#f2f1fb", borderRadius: "15px" }}>

                    <Box component="div" sx={{ display: "flex", flexDirection: "column", background: "#e9e9fa", borderRadius: "15px" }} margin={2}>
                        <Typography variant="subtitle1" fontWeight={600} margin={2}> Item Ordered </Typography>
                        <Box component="div" sx={{ display: "flex", flexDirection: "row", background: "#e9e9fa", borderRadius: "15px" }}>
                            <Box marginX={2} marginY={1}>
                                <img
                                    src={
                                        item &&
                                            item?.product_images &&
                                            item?.product_images.length > 0
                                            ? item?.product_images[0]
                                            : "https://picsum.photos/200"
                                    }
                                    alt=""
                                    style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                                />
                            </Box>
                            <Box display={"flex"}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography variant="subtitle1" fontWeight={600} sx={{ display: "flex", flexDirection: "row" }}>
                                            {item?.product_name}
                                        </Typography>
                                        <Typography variant="caption" color={'text.secondary'} gutterBottom>
                                            {item.prod_category} | Quantity : {item?.units_ordered}
                                        </Typography>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                      { item?.prod_color && item?.prod_size && <Typography variant="caption" color={'text.secondary'} gutterBottom>
                                            {item?.product_color ?? item?.prod_color } |  {item?.product_size ?? item?.prod_size}
                                        </Typography>}
                                    </div>
                                 
                                </div>
                                    </div>
                                    <div style={{ alignItems: "flex-end", marginLeft: "150px", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "grey", marginLeft: "3px", fontWeight: "200" }}>{item?.amount_paid_ccy}
                                        </div>
                                        <div style={{ color: "black", marginLeft: "3px" }}> {item?.amount_paid}
                                        </div>
                                    </div>
                                </div>
                            </Box>
                            <Box display={"flex"}>
                              
                            </Box>
                        </Box>
                    </Box>
                    <Box marginBottom={2}>
                        <p
                            style={{
                                marginLeft: "15px",
                                color: "black",
                                fontSize: "12px",
                                fontWeight: "800",
                            }}
                        >
                            Customer Details
                        </p>
                        <p
                            style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                color: "gray",
                                fontSize: "14px",
                                fontWeight: "800",
                                textAlign: "left",
                            }}
                        >
                            Customer Name : {data?.customer_details?.first_name} {" "} {data?.customer_details?.last_name}
                        </p>
                        <p
                            style={{
                                marginLeft: "15px",
                                marginTop: "8px",
                                color: "gray",
                                fontSize: "14px",
                                fontWeight: "800",
                                textAlign: "left",
                            }}
                        >
                            Customer Phone Number : {data?.customer_details?.full_phone_number}
                        </p>
                    </Box>
                    <Box marginBottom={2}>
                        <p
                            style={{
                                marginLeft: "15px",
                                color: "black",
                                fontSize: "12px",
                                fontWeight: "800",
                            }}
                        >
                            Shipping Address
                        </p>
                        <p
                            style={{
                                marginLeft: "15px",
                                color: "gray",
                                fontSize: "14px",
                                fontWeight: "800",
                                textAlign: "center",
                            }}
                        >
                            {fullAddress}
                        </p>
                    </Box>
                </Box>
                {/* dlkfjj */}
                <Box marginY={3}>
                    <Typography variant="subtitle1" marginTop={2} fontWeight={700}>Track Order</Typography>
                </Box>
                <Box marginY={3}>
                <Stepper activeStep={getActiveStep(item?.order_item_status)} alternativeLabel
              sx={{
                "& .MuiStepConnector-line": {
                  borderColor: "#4E4DFF",
                  borderTopWidth: "2px",
                  opacity:"20%"
                },
                "& .MuiStepIcon-root" : {
                    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`
                },
                "& .MuiSvgIcon-root" : {
                    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`
                }
              }}
                >

{steps.map((label) => (
                                                                          <Step key={label}>
                                                                          <StepLabel
                                                                           
                                                                       //    StepIconComponent={CustomStepIcon}
                                                                             StepIconProps={{
                                                                               classes : {
                                                                                   root: Styles.customCompletedIcon,
                                                                               }
                                                                             }}
                                                                           >
                                                                               <div style={{display:"flex", flexDirection:"column"}}>
                                                                               {label}
                                                                               <Typography variant="caption" color={"text.secondary"}> {
                                                                               label === 'Order Placed' ? 
                                                                               formatDate(data?.purchase_date) :  
                                                                               label === 'Order Shipped' ? 
                                                                               item?.order_pickup_date_time_unix ? formatDate(item?.order_pickup_date_time_unix) : "-" :
                                                                               label === 'Out for delivery' ? 
                                                                               item?.order_handover_date_time_unix ? formatDate(item?.order_handover_date_time_unix) : "-":
                                                                               label === 'Delivered' ? 
                                                                               item?.expected_delivery_date_time_unix ? formatDate(item?.expected_delivery_date_time_unix) : 
                                                                               item?.expected_delivery_date_time_unix ? formatDate(item?.expected_delivery_date_time_unix) : "-":
                                                                                "-" } </Typography>
                                                                               </div>
                                                                               </StepLabel>
                                                                         </Step>
                                                                ))}
      </Stepper>
      </Box>
                <div>
                    <h2>Billing Summary</h2>
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    >
                        {" "}
                        <span>Subtotal</span>{" "}
                        <span
                            style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                        >
                            BHD{" "}
                            {item?.prod_total_price !== null
                                ? item?.prod_total_price
                                : "0"}
                        </span>
                    </p>
                    <br />
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    >
                        {" "}
                        <span>Product Discount</span>{" "}
                        <span
                            style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                        >
                            {"-"} BHD{" "}
                            {item?.prod_discount_amount}
                        </span>
                    </p>
                    <br />
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    >
                        {" "}
                        <span>Shipping Fee </span>{" "}
                        <span
                            style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                        >
                            {/* BHD {data?.price_per_unit !== null ? data?.price_per_unit : "0"} */}
                            BHD {item?.product_shipping_fees}
                        </span>
                    </p>
                    <br />
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    >
                        {" "}
                        <span>VAT Amount</span>{" "}
                        <span
                            style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                        >
                            + BHD { item?.prod_vat_amount}
                        </span>
                    </p>
                  
                    <Divider />
                    <br />
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    >
                        {" "}
                        <span>Total Paid </span>{" "}
                        <span
                            style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                        >
                            BHD {item?.amount_paid}
                        </span>
                    </p>

                    <br />
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    >
                        {" "}
                        <span>Freelancer Commission</span>{" "}
                        <span
                            style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                        >
                            - BHD { item?.commission_earned}
                        </span>
                    </p>
                    <br />
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    >
                        {" "}
                        <span>Platform Commission</span>{" "}
                        <span
                            style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                        >
                            - BHD { item?.prod_platform_fee_amount}
                        </span>
                    </p>
                    <br />
                    {/* <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "14px",
                            fontWeight: "800",
                        }}
                    > */}
                     <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                            fontSize: "18px",
                            fontWeight: "800",
                        }}
                    >
                        {" "}
                        <span>Supplier Final Amount</span>{" "}
                        <span
                            style={{ color: "gray", fontSize: "18px", fontWeight: "800" }}
                        >
                            BHD { item?.supplier_final_amount}
                        </span>
                    </p>

                    <Divider />
                   
                </div>

                {/* <div>
                    {item.order_item_status === "ORDERED" && <Button className={Styles.refundButton}>
                        Cancel Order
                    </Button>}
                </div> */}
            </div>
            {/* <CancelPopUp
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(values) => handleCancelOrder(selectedOrder.order_id, values.reason, values.categoryId, values.category)}
        id={selectedOrder.order_id}
      /> */}
        </Drawer>
        
        )) : ""
    );
};

export default OrderDetails;
