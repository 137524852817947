import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Button,
  Calendar,
  Divider,
  Form,
  Input,
  Modal,
  message,
  notification,
  Radio,
  Skeleton,
  Popconfirm,
} from "antd";
import "../ServiceBooking/Booking.css";
import styles from "../ServiceConfirmation/ServiceConfirmationPage.module.css";
import tickPop from "../../../assests/tickPop.png";
import confettiIMG from "../../../assests/confettiIMG.png";
import FailedTick from "../../../assests/customDeleteMark.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useHistory, useParams } from "react-router";
import { _DELETE, _GET, _MGET, _POST } from "../../../common/commonFunc";
import Styles from "../ServiceBooking/Service.module.css";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import LocationSelector from "./AddressSelection";
import axios from "axios";
import _ from "lodash";
import { APPLICATION_ROUTES, BASE_URL } from "../../../constant/ApplicationRoutes";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import OCPoint from "../../../assests/customIcons/OCPoint";
import Percentage from "../../../assests/customIcons/Percentage";
import PrivacyPolicy from "../../TermsAndConditions/PrivacypolicyModal";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import grdCirculeIcon from "../../../assests/circulegrd.svg";
import { useDispatch, useSelector } from "react-redux";
import { setServiceDetails } from "../redux/actions/action";
import PlusIcon from "../../../assests/PlusCustom.svg";
import MinusIcon from "../../../assests/MinusCustom.svg";
import { useLocation } from "react-router";
import { Category } from "@mui/icons-material";
import { deleteCartItem } from "../redux/actions/bookingActions";
import { IconButton, Typography } from "@mui/material";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import {
  fetchCartItems,
  resetCartFetched,
}from "../../../redux/actions/cartActions";
import DeleteRed from "../../../assests/customIcons/DeleteRed";
const { TextArea } = Input;

interface Cartitem {
  itemType: string;
  itemId: string;
  type: string;
  quantity: number;
}

const MultipleProducts = () => {
  const history = useHistory();
  const productOrderDetails = useSelector(
    (state: any) => state.productOrderDetails.productOrderSelected
  );
  const location = useLocation<any>();
  const [cards, setCards] = useState<any>([]);
  const [isTCChecked, setisTCChecked] = useState(false);
  const [isPPChecked, setisPPChecked] = useState(false);
  const [selectedPayType, setSelectedPayType] = useState("Card");
  const [selectedCard, setSelectedCard] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocationValue, setSelectedLocationValue] =
    useState("default address");
  const [currentDate, setCurrentDate] = useState("");
  const [selectedAddressValue, setSelectedAddressValue] = useState<any | null>(null);
  const [paySummary, setPaySummary] = useState<any>()
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [isPaidSucess, setIsPaidSucess] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  const [selectedProd, setSelectedProd] = useState<any | undefined>();

  const payDetails: any = location.state.billingSummary;
  let products: any = location.state.selectedProducts;

  const [ppModalVisible, ppSetModalVisible] = useState(false);
  const [visibleCardsCount, setVisibleCardsCount] = useState(3);
  const [addressMainData, setAddressMainData] = useState<any>([0]);

  const userdata: any = localStorage.getItem("userInfo");
  const parsedUserData = JSON.parse(userdata);
  const userId = parsedUserData?.id;



  useEffect(() => {
    console.log(" Products data in multi order", products);
    setSelectedProd(Object.values(products)
      .filter((item: any) => item?.productItemId)
      .map((item: any) => ({
        productId: item?.productItemId,
        productItemId: item?.itemId,
        quantity: item?.quantity,
        name: item?.itemName,
        category: item?.itemCategory,
        picture: item?.itemPic[0],
        price: item?.itemPrice,
        supplierId: item?.supplierId,
        freelancerId: item?.freelancerId,
        inventory: item?.inventory
      })))
  }, [products])

  useEffect(() => {
    console.log("Billing Summary in Multiproducts : ", payDetails);
    setPaySummary(payDetails);
  }, [payDetails])


  useEffect(() => {
    console.log("productOrderDetails", productOrderDetails);
  }, [productOrderDetails]);


  const getBillingSummary = async () => {
    const orderDetails = selectedProd
      .filter((item: any) => item?.productId)
      .map((item: any) => ({
        productId: item?.productId,
        quantity: item?.quantity
      }));

    const requestBody = {
      orderDetails: orderDetails
    };

    console.log("orderdetails is : ", orderDetails)
    console.log("RequestBody is : ", requestBody);

    try {
      const response = await _POST(
        `supplier/multipleOrderBillingSummary`,
        requestBody,
        true
      );

      if (response.statusCode === 200 && response.data) {
        console.log("API Response:", response.data);
        setPaySummary(response.data);
      }
      else {
        console.log("API request failed");
      }
    } catch (error) {
      console.error("Error while making API request:", error);
    }

  };

  useEffect(() => {
    if (selectedProd) {
      getBillingSummary();
    }
    console.log("Selected Prod in Multiproducts : ", selectedProd);
  }, [selectedProd]);


  const incrementCount = (id: any) => {
    setSelectedProd((prevItems: any) => {
      return prevItems.map((item: any) => {
        if (item.productId === id) {
          const availableInventory = item?.inventory[0]?.available_quantity ?? 0;

          if (item.quantity < availableInventory) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          } else {
            notification.warning({
              message: "Stock Limit Reached",
              description: "You cannot add more items than available inventory.",
            });
          }
        }
        return item;
      });
    });
  };


  const decrementCount = async (id: any) => {
    setSelectedProd((prevItems: any) =>
      prevItems.map((item: any) => {
        if (item.productId === id && item.quantity > 1) {
          return {
            ...item,
            quantity: item.quantity - 1
          };
        }
        return item;
      })
    );

  };

    const fetchCartItem = async () => {
      try {
        const res = await _GET(`cart/getItem/${userId}`, true, false);
        if (res?.status) {
          const itemsArray: Cartitem[] = Object.values(res.data)
            .map((item: any) => JSON.parse(item))
            .flat();
          const productItems: any = itemsArray.filter(
            (item) => item.itemType === "product"
          );
          setSelectedProd(
            productItems.map((item: any) => ({
              productId: item?.productItemId,
              productItemId: item?.itemId,
              quantity: item?.quantity,
              name: item?.itemName,
              category: item?.itemCategory,
              picture: item?.itemPic[0],
              price: item?.itemPrice,
              supplierId: item?.supplierId,
              freelancerId: item?.freelancerId,
              inventory: item?.inventory,
            }))
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    

   const handleDelete = async (itemId: any) => {
      try {
        const url = `cart/removeItem/product/${userId}/${itemId}`;
        const response = await _DELETE(url, true);
        fetchCartItem();
        dispatch(deleteCartItem(itemId));
        notification.success({
          message: "Item Deleted",
          description: "The item has been successfully removed from the cart.",
        });
        dispatch(resetCartFetched());
        dispatch(fetchCartItems())
      } catch (error) {
        console.error("Error in deleting item:", error);
      }
    };
  

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
  };
  const handleFailureModalClose = () => {
    setIsFailureModalVisible(false);
  };



  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const payTypes = ["Card", "Benefit Pay"];


  const myCards = async () => {
    try {
      const res = await _GET(`payment/getUserAllCard`, true);

      if (res.status === true) {
        const uniqueCards = _.uniqBy(res.data, "id");
        setCards(uniqueCards);
      } else {
        console.error("Error fetching cards: ", res.message);
      }
    } catch (error) {
      console.error("Error fetching cards: ", error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get("status");

    if (paymentStatus === "true") {
      setShowSuccessModal(true);
      setIsSuccessModalVisible(true);
    }

    const getTodaysDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();
      const formattedDate = `${year}-${month}-${day}`;
      setCurrentDate(formattedDate);
    };

    getTodaysDate();
    myCards();
    fetchCartItem()
  }, []);

  const CardComponent = ({ card, onSelect, isSelected }: any) => {
    if (!card) {
      return null;
    }

    const formattedCardNumber = `${card?.first_eight.substring(
      0,
      8
    )} **** ** ${card?.last_four.substring(2)}`;
    return (
      <div
        className={Styles.cardContainer}
        onClick={() => onSelect(isSelected ? null : card?.id)}
      >
        <div
          className={`${Styles.radioCircle} ${isSelected ? Styles.selectedCard : ""
            }`}
          dangerouslySetInnerHTML={{
            __html: isSelected
              ? `<img src="${grdCirculeIcon}" alt="Circle Icon" />`
              : "",
          }}
        />
        <div className={Styles.cardDetails}>
          <div className={Styles.cardName}>{card?.name}</div>
          <div className={Styles.cardInfo}>
            <span className={Styles.cardBrand}>{card?.brand} </span> |{" "}
            <span className={Styles.cardNumber}>{formattedCardNumber}</span>
          </div>
        </div>
      </div>
    );
  };
  const handleLocationChange1 = (location: string, address: string) => {
    console.log("location address", { address, location });
    setSelectedLocationValue(location);
    setSelectedAddressValue(address);
  };

  function addressStringToObject(address: string) {
    const parts = address?.split(",").map((part) => part?.trim());

    if (parts) {
       return {
      address_name: parts[0] || "",
      building_no: parts[1] || "",
      floor: parts[2] || "",
      address: parts[3] || "",
      landmark: parts[4] || "",
      pincode: parts[5] || "",
      city: parts[6] || "",
      state: parts[7] || "",
      country: parts[8] || "",
    };
  }else {
    return null
  }
  }
  const addressObject = addressStringToObject(selectedAddressValue);


  const productWiseBilling = paySummary?.productBillingDetails.reduce((item: any, details: any) => {
    item[details.productId] = details;
    return item;
  }, {});

  // console.log("Product wise billing is : ", productWiseBilling);

  const handleBooking = async () => {

    console.log("selectedAddressValue is : ", selectedAddressValue)
    if (!selectedAddressValue || selectedAddressValue == 0) {
      notification.error({
        message: "No Address Selected",
        description: "Please select an address before booking."
      });
      return;
    }
 
    const bookingData = {

      total_product_price_amount: paySummary.totalProductAmount,
      total_product_price_ccy: paySummary.totalAmountCurrency,
      total_discount_amount: paySummary.totalDiscountAmount,
      total_discount_ccy: paySummary.discountAmountToPayCurrency,
      products: selectedProd.map((product: any) => {
        return {
          product_id: product.productId,
          inventory_id: product?.inventory[0]?.inventory_id,
          product_size: product?.inventory[0]?.size,
          product_color: product?.inventory[0]?.color,
          supplier_id: product.supplierId,
          service_provider_id: product.freelancerId,
          ordered_quantity: product.quantity,
          product_total_price: productWiseBilling[product.productId]?.productTotalAmount,
          product_discounted_price: productWiseBilling[product.productId]?.productDiscountedAmount,
          product_vat_amount: productWiseBilling[product.productId]?.vatAmountToPay,
          product_discount_amount: productWiseBilling[product.productId]?.discountAmountToPay,
          product_discount_percentage: productWiseBilling[product.productId]?.discountPercentage,
          product_vat_percentage: productWiseBilling[product.productId]?.vatPercentage,
          product_amount_to_pay: productWiseBilling[product.productId]?.productAmountToPay,
          product_shipping_fees: productWiseBilling[product.productId]?.shippingFees
        };
      }),
      customer_address: addressObject,
      total_vat_amount: paySummary.totalVatAmount,
      total_vat_ccy: paySummary.vatAmountCurrency,
      total_shipping_amount: paySummary.totalShippingFees,
      total_shipping_amount_ccy: "BHD",
      total_amount_paid: paySummary.totalAmountTopay,
      total_amount_paid_ccy: paySummary.totalAmountTopayCurrency
    };

    console.log("booking details are : ", bookingData)

    const response = await _POST(
      `supplier/customer/buy/product`,
      bookingData,
      false
    );

    if (response && response.status) {
      if (response?.data?.payment_status === "Paid" && response?.data?.is_coupon_used) {

        showSuccessCustomModal({
          title: "Your order was placed successfully.",
          smallText:
            "Your order is sent to the service provider",
          buttonText: "Continue",
        });
        history.push(APPLICATION_ROUTES.ORDER);
      } else {
        const paymentPayloadData = {
          amount: paySummary?.totalAmountTopay,
          currency: "BHD",
          order_id: response.data.order_id,
        };

        const data = await _POST(
          `payment/product/createCharge`,
          paymentPayloadData,
          true
        );

        console.log("data of createCharge is : ", data)
        window.location.href = data.data.redirect_url;
      }
      selectedProd.map((item: any) => {
        try {
          const url = `cart/removeItem/product/${userId}/${item.productId}`;
          const response = _DELETE(url, true, false);
          dispatch(deleteCartItem(item.productId));
        } catch (error) {
          console.error("Error in deleting item:", error);
        }
      })
    }
  };


  return (
    <>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={`${Styles.serviceMainContainer} bookingPageContainer`}>
          <div className={Styles.infoContainer_multiple}>
            {selectedProd ? selectedProd?.map((item: any, index: any) => (
              <>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img
                      src={item?.picture}
                      className={Styles.productPicture}
                      alt=""
                      style={{ borderRadius: "50%" }}
                    />
                    <div className={Styles.serviceBookingInfo} style={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="subtitle2" onClick={() => history.goBack()} marginX={1}>
                        {item?.name}
                      </Typography>


                      <div >
                        <Typography variant="caption" marginX={1}>
                          <span className="currency">BHD</span>{" "}
                          <span className="titlePrice">
                            {" "}
                            {item?.price}
                          </span>{" "}

                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <Typography variant="caption" marginX={1}>  {item?.category} | </Typography>

                          {item?.inventory[0]?.color &&
                            <>
                              <div style={{
                                background: item?.inventory[0]?.colorCode,
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                border: "1px solid grey",
                                marginRight: "3px"
                              }}>
                              </div>
                              <Typography variant="caption"> {item.inventory[0]?.color} | </Typography>
                            </>
                          }
                          <Typography variant="caption" marginX={1}> Size : {item?.inventory[0]?.size}</Typography>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className={Styles.cartCount_multiple}>
                    <button onClick={() => decrementCount(item.productId)} className={Styles.CartButton_m}>
                      <img src={MinusIcon} alt="Decrease" />
                    </button>
                    <span className={Styles.countDisplay}>
                      {item?.count ?? item?.quantity}
                    </span>
                    <button onClick={() => incrementCount(item.productId)} className={Styles.CartButton2_m}>
                      <img src={PlusIcon} alt="Increase" />
                    </button>
                  </div>
                  <Popconfirm
                                  title="Are you sure you want to remove the item from cart?"
                                  onConfirm={() => handleDelete(item?.productItemId)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <IconButton className="elevatedElement iconBtn">
                                    <DeleteRed />
                                  </IconButton>
                                </Popconfirm>
                </div>
                <Divider />
              </>
            )) : ""}
          </div>

          <div className="bookingMainContainer">
            <div className="date-picker-containerPar">
              <div className="date-picker-container">
                <div className={Styles.locationPref}>
                  <h3 className="bold xlSize">Shipping Address</h3>

                  {addressMainData && addressMainData.length > 0 ? (
                    <LocationSelector
                      onLocationChange={handleLocationChange1}
                      serviceLocation={JSON.stringify(addressMainData[0])}
                      serviceAtCustomerLocation={"yes"}
                      setCoords={() => { }}
                    />
                  ) : (
                    <div
                      style={{
                        marginTop: "2rem",
                        marginBottom: "2rem",
                      }}
                    >
                      No Address Found
                    </div>
                  )}
                </div>
                <div>
                  <section
                    className="paySelection"
                    style={{ marginBottom: "1rem" }}
                  >
                    <h3 className="bold xlSize">Payment method</h3>
                    <div className="payTypeSelection">
                      {payTypes &&
                        payTypes.map((type) => (
                          <button
                            key={type}
                            className={`payTypeBtn ${type === selectedPayType ? "payActiveBtn" : ""
                              }`}
                            onClick={() => setSelectedPayType(type)}
                            style={{ marginBottom: "1rem" }}
                          >
                            {type}
                          </button>
                        ))}
                      {selectedPayType === "Card" &&
                        cards.slice(0, visibleCardsCount).map((card: any) => {
                          console.log("object", card);
                          return (
                            <CardComponent
                              key={card?.id}
                              card={card}
                              onSelect={setSelectedCard}
                              isSelected={selectedCard === card?.id}
                            />
                          );
                        })}

                      {selectedPayType === "Card" && cards.length === 0 && (
                        <p>Please Proceed to pay to add a new card</p>
                      )}

                      {selectedPayType === "Card" &&
                        cards.length > visibleCardsCount && (
                          <button
                            onClick={() => setVisibleCardsCount(cards.length)}
                            className={Styles.cardLoadMore}
                          >
                            Load All
                          </button>
                        )}

                      {selectedPayType === "Card" && visibleCardsCount > 3 && (
                        <button
                          onClick={() => setVisibleCardsCount(3)}
                          className={Styles.cardShowLess}
                        >
                          Show Less
                        </button>
                      )}
                    </div>
                    <Button
                      onClick={() => { }}
                      className="blackOutline addCardBtn"
                      style={{ marginTop: "20px" }}
                      disabled={true}
                    >
                      <p className="bold m-0">{`+ Add ${selectedPayType}`}</p>
                    </Button>
                  </section>
                </div>
              </div>
            </div>
            <div className="paymentContainer">
              {/* <div className="paymentTop">
                  <section>
                    <div className="couponheader">
                      <h3 className="bold xlSize">Apply Coupon</h3>
                      <p className="readMore">MY COUPONS</p>
                    </div>
                    <div
                      style={{ backgroundColor: "#f5f6f6" }}
                      className="inputBox applyBtn"
                    >
                      <Percentage />
                      <span
                        className="inputField applyCenter"
                        style={{
                          backgroundColor: "none !important",
                          background: "none !important",
                          boxShadow: "none !important",
                        }}
                      >
                        Enter Code
                      </span>
                      <button onClick={() => {}} className="applyText">
                        APPLY
                      </button>
                    </div>
                  </section>
                  <section>
                    <h3 className="bold xlSize">Apply OC Point</h3>
                    <div className="inputBox applyBtn useBtn">
                      <OCPoint />
                      <div className="inputField useCenter">
                        <p className="mainTitle">Saving BHD 10</p>
                        <p className="subTitle">From 100 OC balance</p>
                      </div>
                      <button onClick={() => {}} className="applyText">
                        USE
                      </button>
                    </div>
                  </section>
                </div> */}
              <div className="paymentBottom">
                <section>
                  <div className="costHeader">
                    <h3 className="bold xlSize">Product Price</h3>
                    <h3 className="bold xlSize">
                      <span className="grayText">BHD</span>{" "}
                      <span>{paySummary?.totalProductAmountAfterDiscount}</span>
                    </h3>
                  </div>
                  {paySummary?.discountPercentage &&
                    paySummary?.discountPercentage > 0 ? (
                    <>
                      <div className="costHeader">
                        <p>Product Discount (%)</p>
                        <p className="semibold">
                          <span className="grayText">
                            <b>-</b> {paySummary?.discountPercentage}
                            {"%"}
                          </span>
                        </p>
                      </div>
                      <div className="costHeader">
                        <p> Product Discount</p>
                        <p className="semibold">
                          <span className="grayText">
                            <b>-</b> BHD
                          </span>{" "}
                          <span>{paySummary?.totalDiscountAmount}</span>
                        </p>
                      </div>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="costHeader">
                    <p>Shipping Charges </p>
                    <p className="semibold">
                      <span className="grayText">BHD</span>{" "}
                      <span>{paySummary?.totalShippingFees}</span>
                    </p>
                  </div>

                  <div className="costHeader">
                    <p>Coupon Discount</p>
                    <p className="semibold">
                      <span className="grayText"> - BHD</span> <span>{paySummary?.couponDiscount ?? "0.0"}</span>
                    </p>
                  </div>

                  <div className="costHeader">
                    <p>VAT (@ {paySummary?.vatPercentage} %)</p>
                    <p className="semibold">
                      <span className="grayText">+ BHD</span>{" "}
                      <span>{paySummary?.totalVatAmount}</span>
                    </p>
                  </div>
                </section>
                <Divider />
                <section>
                  <div className="costHeader">
                    <h3 className="bold xlSize">Total Amount</h3>
                    <h3 className="bold xlSize">
                      <span className="grayText">BHD</span>{" "}
                      <span>{paySummary?.totalAmountTopay}</span>
                    </h3>
                  </div>

                  <>
                    <Divider />

                    <div className="costHeader">
                      <p className="grayText">
                        {" "}
                        Continue to accept our{" "}
                        <span
                          className="readMore"
                          onClick={() => ppSetModalVisible(true)}
                        >
                          {" "}
                          privacy policy
                        </span>{" "}
                        and{" "}
                        <span
                          className="readMore"
                          onClick={() => setModalVisible(true)}
                        >
                          terms & conditions.{" "}
                        </span>
                      </p>
                      <Button
                        shape="round"
                        className="buttonBG paySubmitBtn"
                        type="primary"
                        htmlType="button"
                        onClick={handleBooking}
                        disabled={isPaidSucess || selectedProd?.length === 0}
                        style={{
                          backgroundColor:
                            isPaidSucess === true ? "gray" : "",
                          borderColor: isPaidSucess ? "gray" : "",
                        }}
                      >
                        Pay BHD {paySummary?.totalAmountTopay}
                      </Button>
                    </div>
                  </>

                </section>
              </div>
            </div>
          </div>

        </div>
      </div>
      <PrivacyPolicy
        open={ppModalVisible}
        setOpen={ppSetModalVisible}
        setisTCChecked={setisPPChecked}
      />
      <TermsAndConditions
        open={modalVisible}
        setOpen={setModalVisible}
        setisTCChecked={setisTCChecked}
      />
      {failedMessage == "" && (
        <Dialog
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.successModalContent}
            style={{
              background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={tickPop} alt="" />
            <h2>Thank You!</h2>
            <p>Your booking & order is sent to service provider</p>
            <p>You've received 100 OC point</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleSuccessModalClose}
                style={{ marginTop: "24px" }}
              >
                Great!
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {failedMessage !== "" && (
        <Dialog
          open={isFailureModalVisible}
          onClose={handleFailureModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.failedModalContent}
            style={{
              // background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={FailedTick} alt="" />
            <h2>Payment Failed</h2>
            <p>{failedMessage}</p>
            {/* <p>You've received 100 OC point</p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Button
                type="primary"
                onClick={handleFailureModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button> */}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default MultipleProducts;
