export const APPLICATION_ROUTES = {
  HOME: "/home",
  EMAIL_VERIFY: "/emailVerify",
  VERIFY_OTP: "/verifyOtp",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CATEGORIES: "/categories",
  BANK_DETAILS: "/bankDetails",
  VERIFY_DOCUMENTS: "/verifyDocuments",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  LOGIN_HOME: "/",
  REGISTER: "/register",
  OTHER_DETAILS: "/otherDetails",
  SUPPLIER_REGISTRATION: "/supplier-registration",
  SUPPLIER_REGISTRATION_OLD: "/supplier-registration-old",
  E_KYC: "/E_Kyc",
  EKYCVerifyResult: "/EKYC",
  supplier: "/supplier",
  SERVICE: "/service",
  ORDER: "/order",
  CALENDAR: "/calendar",
  STORE: "/store",
  MAP: "/map",
  INSTA: "/insta",
  SERVICE_BOOKING: "/service-booking",
  SERVICE_QUOTE_BOOKING: "/service-booking-by-quote",
  SERVICE_CONFIRMATION: "/service-confirmation",
  SERVICE_QUOTE_CONFIRMATION: "/service-quote-confirmation",
  ORDER_BOOKING: "/OrderBooking",
  TERMSANDCONDITIONS: "/termsAndConditions",
  EVENTS: "/events",
  EVENT_DETAILS: "/eventDetails",
  EVENT_CONFIRMATION: "/event-confirmation",
  COLLABORATION_PAYMENT_CONFIRMATION:
    "/supplier/collaboration-advance-confirmation",
  PROFILE: "/profile",
  PERSONAL_INFO: "/personalInfo",
  PROFILE_SAVED: "/saved",
  MY_POSTS: "/myPosts",
  PREFERENCES: "/preferences",
  PROFILE_SAVED_SERVICES: "/profileSetting/savedServices",
  EVENT_DETAILS_NEW: "/eventDetailsNew",
  TERMS_AND_CONDITIONS: "/profileSetting/termsConditions",
  PRICAY_AND_POLICY: "/profileSetting/privacyPolicy",
  CHANGE_PASSWORD: "/profileSetting/changePassword",
  BANK_INFORMATION: "/profileSetting/bankInformation",
  MY_DOCUMENTS: "/profileSetting/MyDocuments",
  MY_SCHEDULE: "/profileSetting/mySchedule",
  PROFILE_ADDRESS: "/profileSetting/addresses",
  PAYMENT_METHODS: "/profileSetting/paymentMethods",
  ADDRESSES: "/profileSetting/addresses",
  SUPPLIER_HOME: "/supplier",
  MANAGE_SOCIAL_MEDIA: "/profileSetting/manageSocialMedia",
  COMMET_CHAT: "/commet/chat",
  LOGIN_CHAT: "/commet/login",
  BOOKINGS: "/bookings",
  COLLABORATIONS: "/collaborations",
  FREELANCERCOLLABDETAILS: "/freelancer/collab-details",
  PRODUCT_DETAILS: "/productDetails",
  PRODUCT_BOOKING: "/product-booking",
  BLOGS: "/profileSetting/blogs",
  AFFILIATE_PRODUCTS: "/profileSetting/affiliateProducts",
  HELP_AND_SUPPORT: "/profileSetting/helpSupport",
  COUPONS: "/profileSetting/coupons",
  MANAGE_NOTIFICATIONS: "/profileSetting/manageNotifications",
  CHAT_PAGE: "/chatsPage",
  SUPPLIER_CHAT_PAGE: "/supplier/chatPageSupplierHome",
  SUPPLIER_CHAT_FREELANER_PAGE: "/supplier/chatPageSupplier",
  PRODUCT_CONFIRMATION: "/product-order-confirmation",
  MULTI_CHECKOUT: "/multiple-booking",
  ProfileEKYC: "/ProfileEKYC",
  VerifyDocumentsProfile: "/verifyDocumentsProfile",
  freelancerProfile: "/freelancerProfile",
  SHAREHOLDER_SIGNUP: "/shareholder-signup",
  INSTANT_CHECKOUT : "/instant-checkout"
};

// export const BASE_URL = "https://ae6d-122-177-51-82.ngrok-free.app/";
// export const BASE_URL = "https://11a8-122-177-51-82.ngrok-free.app/";
// export const BASE_URL = "http://localhost:5002/";
// export const BASE_URL = " https://development.theoctopus.ai:8443/";
export const GOOGLE_MAP_API_KEY = "AIzaSyDCU3nFtgtGomHA4dnWCpDyzDvlJRnnpkE";
export const BASE_URL = process.env.REACT_APP_COMMON_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_COMMON_BASE_URL;
export const BASE_URL_2 = process.env.REACT_APP_BASE_URL_2;
