import { Col, Divider, Row, notification } from "antd";
import React from "react";
import Styles from "./Addresses.module.css";
import Edit from "../../../assests/customIcons/Edit";
import Delete from "../../../assests/customIcons/Delete";
import axios from "axios";
import { BASE_URL } from "../../../constant/ApplicationRoutes";
import { showConfirmCustomModal } from "../../../common/CustomModal/CustomModal";
import { _DELETE, _POST } from "../../../common/commonFunc";
export const AddressBox = (props: any) => {
  console.log("ADRESS BOX INFO IS", props);
  const onDeleteAddress = async () => {
    const userData = JSON.parse(localStorage.getItem("userInfo") || "");
    const address = props.addressAllDetails;
    const data = await _DELETE(
      `userProfile/deleteAddress/${userData.id}/${address.address_id}`,
      true
    );
    {
      props.onPageLoadHandler();
    }
  };
  const confirmDeleteAddress = (event: any) => {
    event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure you want to delete this Address?",
      smallText: "Deleting this address will delete all its data.",
      onOk: async () => {
        await onDeleteAddress();
      },
      okText: "Yes",
      cancelText: "No",
    });
  };
  const meaningfulAddress = [
    props.addressAllDetails.address_name,
    props.addressAllDetails.building_no,
    props.addressAllDetails.address,
    props.addressAllDetails.floor ? `Floor ${props.addressAllDetails.floor}` : "",
    props.addressAllDetails.landmark,
    props.addressAllDetails.region,
    props.addressAllDetails.governance,
    props.addressAllDetails.country
  ]
    .filter(Boolean) // Remove null, undefined, or empty values
    .join(", "); // Join with a comma and space
  


  const setDefaultAddress = async () => {
    const userData = JSON.parse(localStorage.getItem("userInfo") || "");
    const addressId = props.addressAllDetails.address_id;
    const userId = userData.id;

    try {
      const response = await _POST(
        `userProfile/setDefaultAddress/${userId}/${addressId}`,
        {},
        true
      );

      if (response.statusCode === 200) {
        notification.open({
          type: "success",
          message: "Default address set successfully",
          description: "From now this will be your default address.",
        });
        props.onPageLoadHandler();
      } else {
        notification.open({
          type: "error",
          message: "Error in making default address",
          description: "Cannot create this address as default address.",
        });
      }
    } catch (error) {
      console.error("Something went wrong !!", error);
    }
  };

  return (
    <>
      <Row className={Styles.addressBoxMainContainer}>
        <Col span={24}>
          <Row>
            <Col span={10}>
              <div className={Styles.labelConatiner}>
                <div>{props.home}</div>
              </div>
            </Col>
            <Col span={14}>
              <div className="cardActions">
                <button
                  onClick={confirmDeleteAddress}
                  className="elevatedElement iconBtn"
                  style={{
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <Delete />
                </button>
                <button
                  onClick={() => {
                    props.setOpen(true);
                    props.onEditAddress(props.addressAllDetails);
                  }}
                  className="elevatedElement iconBtn"
                  style={{
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <Edit />
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className={Styles.addressLineContainer}>
                {meaningfulAddress}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              {props.is_default ? (
                <button className={Styles.defaultButtonBlue}>
                  <span> DEFAULT</span>
                </button>
              ) : (
                <button
                  onClick={setDefaultAddress}
                  className={Styles.defaultButton}
                >
                  <span> SET AS DEFAULT</span>
                </button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider></Divider>
        </Col>
      </Row>
    </>
  );
};
