import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { EditorState, convertToRaw } from "draft-js";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
  Radio,
  Slider,
  notification,
  Modal,
} from "antd";
import type { SliderMarks } from "antd/es/slider";
import { _POST, getRole } from "../commonFunc";
import Cross from "../../assests/customIcons/Cross";
import FileUpload from "../../components/InputComponents/FileUpload";
import RichText from "../../components/RichText/RichText";
import RichTextTest from "../../components/RichText/rtextTest";
import SelectWithAdd from "../../components/InputComponents/SelectWithAdd";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import { getSelectionList } from "../../utils/helpers";
import "./AddServiceDrawer.css";
import { getRichText } from "../../utils/dataTransformers";
import RichTextEditor from "../../components/RichText/Quill";
import CustomRichTextEditor from "../../components/RichText/CustomRichTextEditor";
import draftToHtml from "draftjs-to-html";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import FileUploadCopy from "../../components/InputComponents/FileUploadcopy";
import { EyeOutlined } from "@ant-design/icons";
import DynamicSelectComponent from "../DynamicSelectcomponent/DynamicSelectComponent";
import DynamicSelectAddComponent from "../DynamicSelectcomponent/DynamicSelectAddComponent";

const AddServiceDrawer = ({
  openDrawer,
  closeToAdd,
  openToAddLoc,
  setUpdateList,
  categoriesData,
  addedLocations,
  getAllCategoriesData,
  coords,
  onServiceCreated,
}: any) => {
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  console.log("addedLocations", addedLocations);

  const [selectedAddress, setSelectedAddress] = useState<any | null>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [percentage, setPercentage] = useState(10);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(null);

  const [editorContent, setEditorContent] = useState<any>(null);
  const [html, setHtml] = useState<any>(null);
  // const converter = new QuillDeltaToHtmlConverter(editorContent.ops, {});
  // const html = converter.convert();

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
    setHtml(html);
  };

  const handlePercentageChange = (value: any) => {
    setPercentage(value);
  };
  const handleDiscountPercentageChange = (value: any) => {
    setDiscountPercentage(value)
  }

  const { Option } = Select;

  const [serviceImages, setServiceImages] = useState<any>([]);
  const [duration, setDuration] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
  });
  const list2 = [
    { value: "10", label: "10%" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "100", label: "100%" },
  ];
  const [list24, setList24] = useState([{ value: "00", label: "00" }]);
  const [list60, setList60] = useState([{ value: "00", label: "00" }]);
  const [categories, setCategories] = useState([{ value: "", label: "" }]);
  const [subCategories, setSubCategories] = useState([
    { value: "", label: "" },
  ]);
  const [serviceNames, setServiceNames] = useState([{ value: "", label: "" }]);
  const [percentages, setPercentages] = useState(list2);
  const [descEditorState, setDescEditorState] = useState(
    EditorState.createEmpty()
  );

  const [selectedCategory, setSelectedCategory] = useState("");
  console.log("🚀 ~ selectedCategory:", selectedCategory);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedServiceName, setSelectedServiceName] = useState("");
  const [selectedPayModel, setSelectedPayModel] = useState("10%");
  const [durationError, setDurationError] = useState(false);
  const [selectedServiceLocation, setSelectedServiceLocation] =
    useState<any>(null);

  console.log("🚀 ~ selectedServiceLocation:", selectedServiceLocation);

  // const [locationSelected, setLocationSelected] = useState(false);
  const [providingCustomLoc, setProvidingCustomLoc] = useState(false);
  const [allowedQuotes, setAllowedQuotes] = useState(false);
  const [price, setPrice] = useState("");

  // const [form] = Form.useForm();

  const formattedPrice = price.endsWith(".") ? price : `${price}.000`;

  const userRole = getRole();

  // useEffect(() => {
  //   if (allowedQuotes) {
  //     form.setFieldsValue({ service_fees: 0 });
  //   }
  // }, [allowedQuotes, form]);

  const showDrawer = (address: any) => {
    setSelectedAddress(address);
    setIsDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const updateServiceImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setServiceImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = serviceImages;
      updatedImages.splice(index, 1);
      setServiceImages(updatedImages);
    }
  };

  const marks: SliderMarks = {
    0: "0KM",
    5: "5KM",
    10: "10KM",
    15: "15KM",
    20: "20KM",
    25: "25+KM",
  };

  const getAddressName = (location: any) => {
    return location.address_name;
  };

  const getAllCategories = () => {
    const categories: string[] = categoriesData.map(
      (dataObj: any) => dataObj.service_category_main
    );
    const uniqueCategories = [...new Set(categories)];
    return uniqueCategories;
  };

  const getRelatedSubCategories = () => {
    const filteredObjects = categoriesData.filter(
      (dataObj: any) => dataObj.service_category_main === selectedCategory
    );
    const subCategories: string[] = filteredObjects.map(
      (dataObj: any) => dataObj.service_category_sub
    );
    return subCategories;
  };

  const getRelatedServiceNames = () => {
    const filteredObjects = categoriesData.filter(
      (dataObj: any) =>
        dataObj.service_category_main === selectedCategory &&
        dataObj.service_category_sub === selectedSubCategory
    );
    return filteredObjects[0]?.service_name || [];
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const getUpdatedImages = async () => {
    if (serviceImages.length > 0) {
      const allValues = {
        images: serviceImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      if (userRole === "supplier" || userRole === "freelancer") {
        const response = await _POST(
          "services/uploadServiceImagesToS3",
          mainValues,
          false
        );
        if (response) {
          if (response.data?.length > 0) {
            return response.data;
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to upload your images",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    } else {
      return [];
    }
  };

  const formatDuration = (duration: any) => {
    const days = duration.days.padStart(2, "0");
    const hours = duration.hours.padStart(2, "0");
    const minutes = duration.minutes.padStart(2, "0");

    return `${days}d:${hours}h:${minutes}m`;
  };

  const onSubmitHandler = async (values: any) => {
    const formattedDuration = formatDuration(duration);

    if (selectedCategoryId === null) {
      notification.open({
        type: "error",
        message: "Category Required",
        description: "Please select a category to proceed",
      });
      return;
    }

    if (selectedSubCategoryId === null) {
      notification.open({
        type: "error",
        message: "Subcategory Required",
        description: "Please select a subcategory to proceed",
      });
      return;
    }

    if (selectedServiceId === null) {
      notification.open({
        type: "error",
        message: "Service Required",
        description: "Please select a service name to proceed",
      });
      return;
    }

    const converter = new QuillDeltaToHtmlConverter(editorContent.ops, {});
    const htmlText = converter.convert();

    if (!selectedServiceLocation) {
      return notification.open({
        type: "error",
        message: "Service Location Required",
        description: "Please select a service location before proceeding",
      });
    }

    if (formattedDuration === "00d:00h:00m") {
      setDurationError(true);
      return;
    }
    if (!descEditorState) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter a service description",
      });
    }

    const uploadedImages: any = await getUpdatedImages();

    if (!uploadedImages || uploadedImages.length === 0) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Atleast one image required",
      });
    }

    // const formattedPrice = parseFloat(price).toFixed(3);
    // const serviceFees = Number(parseFloat(price).toFixed(3));
    // console.log("🚀 ~ onSubmitHandler ~ serviceFees:", serviceFees);
    // if (!allowedQuotes) {
    //   if (Number.isNaN(serviceFees)) {
    //     return notification.open({
    //       type: "error",
    //       message: "Price Required",
    //       description: "Please enter a correct price to proceed",
    //     });
    //   }
    // }
    const allValues = {
      ...values,
      service_loc_fee:
        values?.service_loc_fee !== null && !isNaN(values.service_loc_fee)
          ? Number(parseFloat(values.service_loc_fee))
          : 0,
      service_fees: allowedQuotes
        ? 0
        : Number(parseFloat(values.service_fees).toFixed(3)),
      service_images: uploadedImages,
      provider_id: storedUserInfo.id,
      service_duration: formattedDuration,
      service_category_main: selectedCategory,
      service_category_sub: selectedSubCategory,
      service_name: selectedServiceName,
      category_id: selectedCategoryId,
      subcategory_id: selectedSubCategoryId,
      service_name_id: selectedServiceId,
      coordinate: {
        latitude: coords.lat ? JSON.stringify(coords.lat) : "0",
        longitude: coords.lng ? JSON.stringify(coords.lng) : "0",
      },
      service_discount_percentage: discountPercentage !== null
        ? parseInt(discountPercentage, 10)
        : 0,
      service_desc: editorContent,
      service_at_customer_location: providingCustomLoc ? "yes" : "no",
      service_area_range: providingCustomLoc
        ? `${values.service_at_customer_location}km`
        : "0km",
      service_fees_ccy: "BHD",
      service_address_id: selectedServiceLocation.address_id,
      allowed_quotes: allowedQuotes,
    };

    console.log("all values : ", allValues);

    if (userRole === "supplier" || userRole === "freelancer") {
      const response = await _POST("services/createService", allValues, false);
      if (response) {
        if (response.data && !response.ZohoError) {
          showSuccessCustomModal({
            title: "Service added successfully.",
            smallText: "Your service has been added successfully.",
            buttonText: "Continue",
          });

          setUpdateList(true);
          closeToAdd();
          onServiceCreated();
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to add your service",
          });
        }
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Login as Service Provider to use this service",
      });
    }
  };

  useEffect(() => {
    setCategories(getSelectionList(getAllCategories()));
    setSubCategories(getSelectionList(getRelatedSubCategories()));
    setServiceNames(getSelectionList(getRelatedServiceNames()));
  }, [categoriesData, selectedCategory, selectedSubCategory]);

  const generateNumberList = (start: any, end: any) => {
    const list = [];
    for (let i = start; i <= end; i++) {
      list.push(i.toString().padStart(2, "0"));
    }
    return list;
  };

  const daysList = generateNumberList(0, 10);
  const hoursList = generateNumberList(0, 23);
  const minutesList = generateNumberList(0, 59);

  const [expanded, setExpanded] = useState<string | null>(null);

  const [resetSubCategory, setResetSubCategory] = useState(false);
  const [resetServiceName, setResetServiceName] = useState(false);
  const [error, setError] = useState(false);

  const handleSelectionChange = (value: any, type: any) => {
    console.log("🚀 ~ handleSelectionChange ~ value:", value);
    if (type === "category") {
      setSelectedCategoryId(value);
      setSelectedSubCategoryId(null);
      setResetSubCategory(true);
      setResetServiceName(true);
      setSelectedSubCategory("");
      setSelectedServiceName("");
    } else if (type === "subcategory") {
      setSelectedSubCategoryId(value);
      setSelectedServiceId(null);
      setResetServiceName(true);
      setSelectedServiceName("");
    } else {
      setSelectedServiceId(value);
    }
  };

  const clearResetSubCategory = () => setResetSubCategory(false);
  const clearResetServiceName = () => setResetServiceName(false);

  const validatePrice = async (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a price!"));
    }
    const numValue = parseFloat(value);
    if (numValue <= 0) {
      return Promise.reject(new Error("Price must be greater than zero."));
    }
    const regex = /^\d{1,13}(\.\d{0,3})?$/;
    if (!regex.test(value)) {
      return Promise.reject(new Error("Price must be less than 1 trillion."));
    }
    return Promise.resolve();
  };

  const validatePriceLoc = async (_: any, value: any) => {
    if (value === undefined || value === "") {
      return Promise.reject(new Error("Please enter a price!"));
    }
    const numValue = parseFloat(value);
    if (numValue < 0) {
      return Promise.reject(new Error("Price must be zero or greater."));
    }
    const regex = /^\d{1,13}(\.\d{0,3})?$/;
    if (!regex.test(value)) {
      return Promise.reject(
        new Error(
          "Price must be less than 1 trillion and can include up to 3 decimal places."
        )
      );
    }
    return Promise.resolve();
  };

  return (
    <Drawer
      title="Add Service"
      placement="right"
      closable={false}
      onClose={closeToAdd}
      open={openDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={closeToAdd} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <p
        className="mainTitle"
        style={{ textAlign: "left", padding: "20px", paddingTop: 0 }}
      >
        Service Info.
      </p>

      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onSubmitHandler}
        initialValues={{
          service_pay_model: 10, // Default value for service_pay_model
          service_loc_fee: 0, // Default value for service_loc_fee
          // ... other initial values
        }}
      >
        <Row style={{ marginTop: "-50px", marginBottom: "25px" }}>
          <p className="subTitle">
            <span style={{ color: "red" }}>*</span> Service Images
          </p>
          <Col span={24}>
            <FileUpload
              title1="Upload Image"
              description="JPG, PNG file with max size of 10mb."
              setData={updateServiceImages}
              name="serviceImages"
              uploadMultiple
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <DynamicSelectComponent
              endpoint="services/categories"
              type="category"
              onChange={handleSelectionChange}
              disabled={false}
              selectedCategoryId={selectedCategoryId}
              setSelected={setSelectedCategory}
              reset={resetSubCategory}
              clearReset={clearResetSubCategory}
              setError={setError}
              error={error}
              showAddOption={false}
            />
          </Col>
          <Col span={12}>
            <DynamicSelectComponent
              endpoint={`services/subcategories/${selectedCategoryId}`}
              type="subcategory"
              onChange={handleSelectionChange}
              disabled={!selectedCategoryId}
              selectedCategoryId={selectedCategoryId}
              setSelected={setSelectedSubCategory}
              reset={resetSubCategory}
              clearReset={clearResetSubCategory}
              value={selectedSubCategory}
              setError={setError}
              error={error}
              showAddOption={false}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <DynamicSelectAddComponent
              endpoint={`services/serviceNames/${selectedCategoryId}/${selectedSubCategoryId}`}
              type="service"
              onChange={handleSelectionChange}
              disabled={!selectedSubCategoryId}
              selectedCategoryId={selectedCategoryId}
              selectedSubCategoryId={selectedSubCategoryId}
              setSelected={setSelectedServiceName}
              reset={resetServiceName}
              clearReset={clearResetServiceName}
              value={selectedServiceName}
              setError={setError}
              error={error}
              showAddOption={true}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ marginBottom: "20px" }}>
            <Checkbox onChange={(e) => setAllowedQuotes(e.target.checked)}>
              Request a quote from customer
            </Checkbox>
          </Col>
          <Col span={12}>
            {allowedQuotes ? (
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> Price (BHD)
                  </span>
                }
                name="service_fees"
              >
                <Input
                  type="Number"
                  placeholder="Price"
                  className="drawerInput"
                  value={allowedQuotes ? 0 : formattedPrice}
                  // onChange={handlePriceChange}
                  // min={0}
                  disabled={allowedQuotes}
                  onWheel={(event) => event.currentTarget.blur()}
                  prefix={<span>BHD</span>}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> Price (BHD)
                  </span>
                }
                name="service_fees"
                rules={[
                  { required: true, message: "" },

                  {
                    validator: validatePrice,
                  },
                ]}
              >
                <Input
                  type="Number"
                  placeholder="Price"
                  className="drawerInput"
                  value={allowedQuotes ? 0 : formattedPrice}
                  // onChange={handlePriceChange}
                  // min={0}
                  disabled={allowedQuotes}
                  onWheel={(event) => event.currentTarget.blur()}
                  prefix={<span>BHD</span>}
                />
              </Form.Item>
            )}{" "}
          </Col>

          <Col span={12}>
            <Form.Item
              name="service_pay_model"
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Advance Amount Percentage
                </span>
              }
            >
              <Select
                value={percentage}
                onChange={handlePercentageChange}
                style={{ width: 200 }}
                className="drawerInput"
              >
                <Option value={10}>10%</Option>
                <Option value={25}>25%</Option>
                <Option value={50}>50%</Option>
                <Option value={75}>75%</Option>
                <Option value={100}>100%</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              name="service_discount_percentage"
              label={
                <span>
                  Discount Percentage
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter a discount percentage",
                },
                {
                  validator: (_, value) => {
                    if (!value || (Number(value) >= 0 && Number(value) <= 100)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Discount percentage must be between 0 and 100"));
                  },
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter discount percentage"
                style={{ width: 200 }}
                className="drawerInput"
                onChange={(e) => handleDiscountPercentageChange(Number(e.target.value))}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  <span style={{ color: "red" }}>*</span> Description
                </span>
              }
              hasFeedback
            >
              <RichTextEditor
                initialContent={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <p
            className="mainTitle"
            style={{ textAlign: "left", padding: "10px", paddingTop: "0" }}
          >
            <span style={{ color: "red" }}>*</span> Service Duration
          </p>
          <Col span={24} className="duration-picker">
            <div className="duration-box">
              <p style={{ margin: 0 }}>Days</p>
              <Select
                value={duration.days}
                onChange={(value: any) => {
                  setDuration({ ...duration, days: value });
                  setDurationError(false);
                }}
              >
                {daysList.map((option, index) => (
                  <Select.Option key={index} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="duration-box">
              <p style={{ margin: 0 }}>Hours</p>
              <Select
                value={duration.hours}
                onChange={(value: any) => {
                  setDurationError(false);
                  setDuration({ ...duration, hours: value });
                }}
              >
                {hoursList.map((option, index) => (
                  <Select.Option
                    key={index}
                    value={option.value}
                    className="durationOptions"
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="duration-box">
              <p style={{ margin: 0 }}>Minutes</p>
              <Select
                value={duration.minutes}
                onChange={(value: any) => {
                  setDurationError(false);
                  setDuration({ ...duration, minutes: value });
                }}
              >
                {minutesList.map((option, index) => (
                  <Select.Option key={index} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          {durationError && (
            <p style={{ color: "red", marginTop: "5px" }}>
              Service Time Duration is Required
            </p>
          )}
        </Row>
        <Row
          gutter={16}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <p
            className="mainTitle"
            style={{ textAlign: "left", padding: "10px" }}
          >
            <span style={{ color: "red" }}>*</span> Service Location
          </p>
          <Col span={24}>
            {addedLocations.length > 0 && (
              <div className="slotsDiv">
                <Form.Item name="service_location">
                  <Radio.Group
                    className="serviceInner"
                    onChange={(e) => setSelectedServiceLocation(e.target.value)}
                  >
                    {addedLocations.map((location: string) => (
                      <Radio
                        value={location}
                        key={location}
                        className={`serviceRadio ${expanded === location ? "active square" : ""
                          }`}
                      // onClick={() => showDrawer(location)}
                      >
                        {/* <span className="text">{getAddressName(location)}</span> */}

                        <span className="text">{getAddressName(location)}</span>
                        <EyeOutlined
                          className="eyeAddOne"
                          onClick={() => showDrawer(location)}
                        />
                      </Radio>
                    ))}
                  </Radio.Group>

                  {/* <Radio.Group className="serviceInner">
                    {addedLocations.map((location: string) => (
                      <Radio
                        value={location}
                        key={location}
                        className={`serviceRadio ${
                          expanded === location ? "active square" : ""
                        }`}
                        onClick={() => showDrawer(location)}
                      >
                        <span className="text">{getAddressName(location)}</span>
                      </Radio>
                    ))}
                  </Radio.Group> */}
                </Form.Item>
              </div>
            )}
            <Drawer
              title="Address Details"
              placement="right"
              onClose={onCloseDrawer}
              open={isDrawerVisible}
              width={"550px"}
              closable={false}
              extra={
                <Space style={{ zIndex: 100 }}>
                  <Button
                    onClick={onCloseDrawer}
                    className="elevatedElement iconBtn"
                  >
                    <Cross />
                  </Button>
                </Space>
              }
            >
              {selectedAddress && (
                <div>
                  <Row>
                    <Col span={24}>
                      <p>Address Name</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.address_name}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <p>Building name/no.</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.building_no}</p>
                      </div>
                    </Col>
                    <Col span={12}>
                      <p>Floor, apartment or villa no.</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.floor}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <p>Street name </p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.address}</p>
                      </div>
                    </Col>
                    <Col span={12}>
                      <p>Country</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.country}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <p>Nearest landmark</p>
                      <div className="drawerInputAd">
                        <p>{selectedAddress.landmark}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Drawer>
          </Col>
          <Col span={24}>
            <div className="newAddress">
              <p style={{ margin: 0 }}>Add new address of your choice</p>
              <Button
                shape="round"
                type="primary"
                htmlType="button"
                className="buttonBG"
                onClick={openToAddLoc}
              >
                Add
              </Button>
            </div>
          </Col>
          <Col span={24} style={{ marginTop: "20px" }}>
            <Checkbox onChange={(e) => setProvidingCustomLoc(e.target.checked)}>
              I provide this service at customer location
            </Checkbox>
          </Col>
          {providingCustomLoc && (
            <>
              <Col span={24}>
                <Form.Item name="service_at_customer_location">
                  <Slider step={null} marks={marks} max={25} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Service Location Fee (BHD)"
                  name="service_loc_fee"
                  rules={[
                    { required: true, message: "" },
                    {
                      validator: validatePriceLoc,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Price"
                    className="drawerInput"
                    prefix={<span className="prefix">BHD</span>}
                    onWheel={(event) => event.currentTarget.blur()}
                    defaultValue={0}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={{ display: "none" }}>
                <Form.Item
                  label="Service Location Fee Currency"
                  name="service_loc_fee_ccy"
                // rules={[{ required: true, message: "Please enter price!" }]}
                >
                  <Input
                    disabled
                    placeholder="BHD"
                    className="drawerInput"
                    defaultValue={"BHD"}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={16} style={{ marginTop: "30px", marginBottom: "-60px" }}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
            >
              Send for an approval
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddServiceDrawer;
