import React, { useEffect, useState } from "react";
import { Button, DatePicker, Drawer, Form, Space, TimePicker, message, Radio, notification, Row, Col } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _PATCH, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import Styles from "./OrderTable.module.css"

import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";
import AddStoreAddress from "./AddStoreAddress";


interface ReadyToDispatchProps {
    visible: boolean;
    onClose: () => void;
    data: any;
}

const ReadyToDispatchOwn: React.FC<ReadyToDispatchProps> = ({
    visible,
    onClose,
    data,
}) => {

      const [selectedServiceLocation, setSelectedServiceLocation] = useState<any>(null);
  
      const [selectedAddress, setSelectedAddress] = useState<any | null>(null);
      const [isDrawerVisible, setIsDrawerVisible] = useState(false);
      const [address, setAddress] = useState<any>([]);
      const [openLocDrawer, setOpenLocDrawer] = useState(false);
      const [addedLocations, setAddedLocations] = useState([]);
      const [coords, setCoords] = useState<any>(null);


    const dateFormat = "DD/MM/YYYY";
    const timeFormat = "HH:mm";
    const [form] = Form.useForm();

    console.log("data in ready to dispatch data : ", data, visible)

    const disabledDate = (current: any) => {
        return current && current < moment().startOf("day");
    };

    const orderId = Object.keys(data)

    const onCloseMainDrawer = () => {
        form.resetFields();
        onClose();
    }

    const handleSubmit = async (values: any) => {
        try {
            const { pickupDate, pickupTime } = values;
            console.log("Pickupdate and time values are : ", pickupDate, pickupTime);

            const formattedDate = pickupDate.format("DD/MM/YYYY");
            const formattedTime = pickupTime.format("HH:mm");

            const [day, month, year] = formattedDate.split("/").map(Number); 
            const [hour, minute] = formattedTime.split(":").map(Number);
            const dateObject = new Date(year, month - 1, day, hour, minute, 0, 0);
            const delivery_date_time = Math.floor(dateObject.getTime() / 1000);

            const payload = {
                order_item_ids: orderId,
                expected_delivery_date_time : delivery_date_time,
                pickup_address_id: selectedServiceLocation.address_id
            };

            // console.log('Payload:', payload);

            const response = await _PATCH(
                `supplier/order/ready-to-dispatch`,
                payload,
                true
            );

            if (response.status === true) {
                message.success("Order dispatched successfully!");
                onCloseMainDrawer();
            } else {
                message.error(
                    response.data && response.message
                        ? response.message
                        : "Error in dispatching order"
                );
            }

        } catch (error) {
            console.error("Error in dispatching order:", error);
        }
    };

    const getAddressName = (location: any) => {
        return location.address_name;
    };

    const showDrawer = (address: any) => {
        setSelectedAddress(address);
        setIsDrawerVisible(true);
    };

    const onCloseDrawer = () => {
        setIsDrawerVisible(false);
    };
    const openToAddLoc = () => {
        setOpenLocDrawer(true);
    };

    const closeToAddLoc = () => {
        setOpenLocDrawer(false);
        getStoreAddress();
    };

       const getStoreAddress = async () => {
            let response;
    
            response = await _GET("supplier/allProductsAddress", true, false);
    
            if (response) {
                if (response.data) {
                    setAddress(response.data);
                } else {
                    return notification.open({
                        type: "error",
                        message: "Something Went Wrong",
                        description: "Error in fetching addresses.",
                    });
                }
            }
    
        }
    
        useEffect(() => {
            getStoreAddress();
        }, [])


    return (
        <>
            <Drawer
                title="Set Courier Company & Schedule"
                placement="right"
                closable={false}
                onClose={onCloseMainDrawer}
                open={visible}
                key="right"
                width={"550px"}
                height={"100%"}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onCloseMainDrawer} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                <div style={{ marginBottom: "30px" }}>

                    {/* dfljgfldkjg */}
                    <div style={{
                        backgroundColor: "rgba(255, 184, 0, 0.15)",
                        marginBottom: "20px"

                    }}>
                        <Typography variant="subtitle1" marginX={2}> <span style={{ color: "blue" }} >({Object.keys(data).length})</span> products ready to dispatch</Typography>
                    </div>
                    <Typography variant="caption" color={"grey"} margin={3}>Courier service to deliver the products to customers</Typography>

                    <Box component="div" sx={{ display: "flex", flexDirection: "column", background: "#e9e9fa", borderRadius: "15px" }} margin={2}>

                        <Typography margin={2} variant="h6">Supplier's Courier Service</Typography>
                        {/* <Typography margin={2} variant="caption">
                            This is the courier service description.
                            Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.
                        </Typography> */}
                    </Box>


                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                    >
                             
                        <Typography variant="caption" color={"grey"}>Set a schedule for the courier company to recieve your products</Typography>
               
                        {address.length > 0 && (
                                                    <div className="slotsDiv">
                                                        <Form.Item
                                                            label="Select Pickup Address"
                                                            name="pickup_location"
                                                            style={{ marginTop: "20px" }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please select a pickup address",
                                                                },
                                                            ]}
                                                        >
                                                            <Radio.Group
                                                                className="dispatchInner"
                                                                onChange={(e) => setSelectedServiceLocation(e.target.value)}
                                                            >
                                                                {address.map((location: any, index: any) => (
                                                                    <Radio
                                                                        value={location}
                                                                        key={index}
                                                                        className={"serviceRadio"}
                        
                        
                                                                    >
                                                                        <span className="text">{getAddressName(location)}</span>
                        
                                                                        <EyeOutlined
                                                                            className="eyeAddOne"
                                                                            onClick={() => showDrawer(location)}
                                                                        />
                                                                    </Radio>
                                                                ))}
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                )}
                              <div className="newAddress">
                                                    <p style={{ margin: 0 }}>Add new store address</p>
                                                    <Button
                                                        shape="round"
                                                        type="primary"
                                                        htmlType="button"
                                                        className="buttonBG"
                                                        onClick={openToAddLoc}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                            <Form.Item
                                label="Select delivery date"
                                name="pickupDate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select the delivery date!",
                                    },
                                ]}
                            >
                                <DatePicker
                                    disabledDate={disabledDate}
                                    format={dateFormat}
                                    className={Styles.pickupInput}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Select a delivery time"
                                name="pickupTime"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a delivery time",
                                    },
                                ]}
                            >
                                <TimePicker
                                    format={timeFormat}
                                    disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                    hideDisabledOptions
                                    className={Styles.pickupInput}
                                />
                            </Form.Item>
                        </div>

                        <div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className={Styles.refundButton}
                            >
                                Continue
                            </Button>
                        </div>
                    </Form>

                </div>
            </Drawer>
            <Drawer
                title="Address Details"
                placement="right"
                onClose={onCloseDrawer}
                open={isDrawerVisible}
                width={"550px"}
                closable={false}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button
                            onClick={onCloseDrawer}
                            className="elevatedElement iconBtn"
                        >
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                {selectedAddress && (
                    <div>
                        <Row>
                            <Col span={24}>
                                <p>Address Name</p>
                                <div className="drawerInputAd">
                                    <p>{selectedAddress.address_name}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <p>Building name/no.</p>
                                <div className="drawerInputAd">
                                    <p>{selectedAddress.building_no}</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <p>Floor, apartment or villa no.</p>
                                <div className="drawerInputAd">
                                    <p>{selectedAddress.floor}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <p>Street name </p>
                                <div className="drawerInputAd">
                                    <p>{selectedAddress.address}</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <p>Country</p>
                                <div className="drawerInputAd">
                                    <p>{selectedAddress.country}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <p>Nearest landmark</p>
                                <div className="drawerInputAd">
                                    <p>{selectedAddress.landmark}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Drawer>
            <AddStoreAddress
                openLocDrawer={openLocDrawer}
                closeToAddLoc={closeToAddLoc}
                setAddedLocations={setAddedLocations}
                isAddAddressModalVisible={true}
                coords={coords}
                setCoords={setCoords}
            />


        </>
    );
};

export default ReadyToDispatchOwn;
