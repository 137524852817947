import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Drawer,
  Space,
  Form,
  Row,
  Col,
  Radio,
  Checkbox,
} from "antd";
import "./Address.css";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _POST } from "../../../common/commonFunc";
import LocationPicker from "../../Map/LocationPicker";
import { RequestType, geocode } from "react-geocode";
import { EyeOutlined } from "@ant-design/icons";
import AddLocationDrawer from "./AdressLocationDrawer";

interface Address {
  address: string;
  selected: boolean;
}

function LocationSelector({
  onLocationChange,
  serviceLocation,
  prefFee,
  serviceAtCustomerLocation,
  coords,
  setCoords,
}: {
  onLocationChange: (location: string, address: string) => void;
  serviceLocation: any;
  prefFee?: string;
  serviceAtCustomerLocation?: any;
  coords?: { lat: number; lng: number };
  setCoords?: any;
}) {
  const [selectedLocation, setSelectedLocation] = useState("service-provider");
  const [homeAddresses, setHomeAddresses] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedHomeIndex, setSelectedHomeIndex] = useState("-1");
  const [isAddAddressModalVisible, setIsAddAddressModalVisible] =
    useState(false);
  const [form] = Form.useForm();

  const userData: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userData).id;

  useEffect(() => {
    if (serviceAtCustomerLocation === "no") {
      setSelectedLocation("service-provider");
      setSelectedAddress(serviceLocation);
      onLocationChange("service-provider", serviceLocation);
    }
  }, [serviceAtCustomerLocation, serviceLocation, onLocationChange]);


  const getAddressData = () => {
    const apiUrl = `userProfile/getUserAllAddress/${userId}`;
    _GET(apiUrl, true)
      .then((data) => {
        if (data.status && data.data && data.data.length > 0) {
          const userAddresses = data.data[0].user_addresses;
          console.log("🚀 ~ .then ~ userAddresses:", userAddresses);
          const allAddresses: Address[] = [];
          userAddresses.forEach((addressData: any) => {
            // const concatenatedAddress = `${addressData.address_name}, ${addressData.building_no}, ${addressData.floor}, ${addressData.address}, ${addressData.landmark}, ${addressData.pinCode}, ${addressData.city}, ${addressData.state}, ${addressData.country}, ${addressData.address_id}`;
            const concatenatedAddress = [
              addressData.address_name,
              addressData.building_no,
              addressData.address,
              addressData.floor ? `Floor ${addressData.floor}` : null,
              addressData.landmark,
              addressData.region,
              addressData.governance,
              addressData.country
            ]
              .filter(Boolean) // Remove null, undefined, or empty values
              .join(", "); // Join with a comma and space
            
            allAddresses.push({
              address: concatenatedAddress,
              selected: addressData.isDefault,
            });
          });

          if (allAddresses.length > 0) {
            const selectedHomeAddress = allAddresses.find(
              (address) => address.selected
            );
            const address = selectedHomeAddress
              ? selectedHomeAddress.address
              : allAddresses[0].address || "";
            let index = -1;

            allAddresses.forEach((address, i) => {
              if (address.selected) {
                index = i;
                return;
              }
            });

            if (index === -1) {
              index = 0;
            }
            setSelectedAddress(address);
            onLocationChange("home", address);
          }
          setHomeAddresses(allAddresses);
        }
      })
      .catch((error) => {
        console.error("Error fetching addresses:", error);
      });
  }
  useEffect(() => {
    if (serviceAtCustomerLocation === "yes") {
      getAddressData();
    }
  }, [serviceAtCustomerLocation]);

  const handleLocationChange = (event: any) => {
    console.log("🚀 ~ handleLocationChange ~ event:", event);
    const location = event.target.value;
    let address = "";

    if (location === "service-provider") {
      address = serviceLocation;
      setSelectedHomeIndex("-1");
    } else if (location === "home") {
      if (homeAddresses.length > 0) {
        const selectedHomeAddress = homeAddresses.find(
          (address) => address.selected
        );
        address = selectedHomeAddress
          ? selectedHomeAddress.address
          : homeAddresses[0].address || "";
        let index = -1;
        homeAddresses.forEach((address, i) => {
          if (address.selected) {
            index = i;
            return;
          }
        });
        if (index === -1) {
          index = 0;
        }
        setSelectedHomeIndex(`${index}`);
      }
    }

    setSelectedLocation(location);
    setSelectedAddress(address);
    onLocationChange(location, address);
  };

  const handleAddAddress = () => {
    setIsAddAddressModalVisible(true);
  };
  useEffect(() => {
    const storedAddresses = localStorage.getItem("userAddresses");
    if (storedAddresses) {
      setHomeAddresses(JSON.parse(storedAddresses));
    }
  }, [selectedAddress]);

  function generateUUID() {
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );

    return uuid;
  }

  const newUUID = generateUUID();

  const handleCancel = () => {
    setIsAddAddressModalVisible(false);
    getAddressData();
  };

  useEffect(() => {
    if (selectedLocation === "service-provider") {
      onLocationChange("service-provider", serviceLocation);
    }
  }, [selectedLocation, serviceLocation, onLocationChange]);

  const handleAddressSelection = (index: number) => {
    const updatedAddresses = homeAddresses.map((address, i) => ({
      ...address,
      selected: i === index,
    }));
    setHomeAddresses(updatedAddresses);
    setSelectedAddress(updatedAddresses[index].address);
    setSelectedLocation("home");
    setSelectedHomeIndex(`${index}`);
    onLocationChange("home", updatedAddresses[index].address);
  };

  useEffect(() => { }, [selectedHomeIndex]);

  useEffect(() => {
    if (selectedLocation === "home") {
      handleLocationChange({ target: { value: "home" } });
      form.setFieldsValue({ selectedLocation: "home" });
    }
  }, [selectedLocation]);

  return (
    <div className="mainDivAddress">
      <div className="mainDivAddress">
        <div className="mainDivAddressOne">
          <Form className="location-form" form={form}>
            <Form.Item name="selectedLocation">
              <Radio.Group
                className="radio-col"
                value={selectedLocation}
                onChange={(e) => handleLocationChange(e)}
                defaultValue={selectedLocation}
              >
                {serviceAtCustomerLocation === "yes" && (
                  <Radio
                    value="home"
                    key="home"
                    className="radio-info raio-hidden"
                  >
                    <div className="home-selection">
                      <div className="title-add">
                        <p className="mainTitle">At Home</p>
                        <div className="home-fee-text-fee">
                          <span className="extraText bold mediumSize">
                            + BHD
                          </span>{" "}
                          <span className="bold mediumSize">{prefFee}</span>
                        </div>
                      </div>
                      {homeAddresses.length > 0 && (
                        <div className="home-extra">
                          <p className="subTitle">Select Address</p>
                        </div>
                      )}

                      {homeAddresses.length > 0 && (
                        <Form.Item>
                          <Radio.Group
                            value={selectedHomeIndex}
                            onChange={(e) =>
                              handleAddressSelection(e.target.value)
                            }
                          >
                            {homeAddresses.map((addressObj, index) => {
                              console.log("adress obj is", addressObj);
                              const { address, selected } = addressObj;

                              const cleanAddressParts = address
                                .split(", ")
                                .filter(
                                  (part) =>
                                    part !== "undefined" &&
                                    !part.match(
                                      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
                                    )
                                );

                              const cleanAddress = cleanAddressParts.join(", ");

                              return (
                                <div className="addressCapsule" key={index}>
                                  <Radio
                                    value={index.toString()}
                                    checked={
                                      selectedLocation === "home"
                                        ? selected
                                        : false
                                    }
                                  >
                                    <p
                                      className={`subTitle multiLine ${selected ? "bold" : ""
                                        }`}
                                    >
                                      {cleanAddress}
                                    </p>
                                  </Radio>
                                </div>
                              );
                            })}
                          </Radio.Group>
                        </Form.Item>
                      )}

                      <Button
                        onClick={handleAddAddress}
                        className="blackOutline"
                      >
                        <p className="bold m-0">+ Add Address</p>
                      </Button>
                    </div>
                  </Radio>
                )}

                <Radio
                  value="service-provider"
                  key="service-provider"
                  className="radio-info"
                >
                  <div className="home-selection">
                    <div className="title-add">
                      <p className="mainTitle">
                        At Service Provider's Location
                      </p>
                    </div>
                    <p className="subTitle multiLine">{serviceLocation}</p>
                  </div>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
      </div>
      <AddLocationDrawer
        openLocDrawer={isAddAddressModalVisible}
        closeToAddLoc={handleCancel}
        setAddedLocations
        coords={coords}
        setCoords={setCoords}
      />
    </div>
  );
}

export default LocationSelector;
