import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Button,
  Calendar,
  Divider,
  Form,
  Input,
  Modal,
  message,
  notification,
  Typography,
  Radio,
  Skeleton,
  Breadcrumb,
} from "antd";
import "./Booking.css";
import styles from "../ServiceConfirmation/ServiceConfirmationPage.module.css";
import tickPop from "../../../assests/tickPop.png";
import confettiIMG from "../../../assests/confettiIMG.png";
import FailedTick from "../../../assests/customDeleteMark.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Confetti from "react-confetti";
import { useHistory, useParams } from "react-router";
import { _GET, _MGET, _POST } from "../../../common/commonFunc";
import { useLocation } from "react-router";
import Styles from "./Service.module.css";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getRichText } from "../../../utils/dataTransformers";
import LocationSelector from "./AddressSelection";
import CardDrawer from "../CardAdd/cardDrawer";
import axios from "axios";
import _ from "lodash";
import { APPLICATION_ROUTES, BASE_URL } from "../../../constant/ApplicationRoutes";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import OCPoint from "../../../assests/customIcons/OCPoint";
import Percentage from "../../../assests/customIcons/Percentage";
import PrivacyPolicy from "../../TermsAndConditions/PrivacypolicyModal";
import { AnyARecord } from "dns";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import grdCirculeIcon from "../../../assests/circulegrd.svg";
import { useDispatch, useSelector } from "react-redux";
import { setServiceDetails } from "../redux/actions/action";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";

const { TextArea } = Input;

const BookingUiNew = () => {
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedApiTime, setApiSelectedTime] = useState<string | null>(null);
  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { serviceId } = useParams<{ serviceId: string }>();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [cards, setCards] = useState<any>([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isTCChecked, setisTCChecked] = useState(false);
  const [isPPChecked, setisPPChecked] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [selectedPayType, setSelectedPayType] = useState("Card");
  const [selectedCard, setSelectedCard] = useState(null);
  const [serviceData, setServiceData] = useState<any>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocationValue, setSelectedLocationValue] =
    useState("service-provider");
  const [selectedAddressValue, setSelectedAddressValue] = useState("");
  const [selectedButton, setSelectedButton] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [calendarDisabled, setCalendarDisabled] = useState(false);
  const [billingSummary, setBillingSummary] = useState<any>([]);
  const [locationSelected, setLocationSelected] = useState(false);
  const [availbleSlots, setAvailabelSlots] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [isPaidSucess, setIsPaidSucess] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isReservationSuccessful, setIsReservationSuccessful] = useState(false);
  const [reserveID, setReserveID] = useState("");

  const [ppModalVisible, ppSetModalVisible] = useState(false);
  const [visibleCardsCount, setVisibleCardsCount] = useState(4);
  const [coords, setCoords] = useState<any>(null);

  const currentTime = Math.floor(Date.now() / 1000);

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
  };
  const handleFailureModalClose = () => {
    setIsFailureModalVisible(false);
  };

  const getCurrentLocation = () => {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          setCoords({ lat: latitude, lng: longitude });
        }
      );
    };
  
    useEffect(() => {
 getCurrentLocation();
    }, []);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const payTypes = ["Card", "Benefit Pay"];

  const fetchBillingSummary = async () => {
    const requestBody = {
      isCustomerLocationAdded: selectedLocationValue == "home",
      couponCode: "",
    };

    try {
      const response = await axios.post(
        `${BASE_URL}services/getBookingBillingSummary/${serviceId}`,
        requestBody,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );
      if (response.data) {
        setBillingSummary(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching billing summary:", error);
    }
  };

  useEffect(() => {
    fetchBillingSummary();
  }, [selectedLocationValue]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get("status");
    const startTime: any = queryParams.get("start_time");
    const endTime: any = queryParams.get("end_time");

    if (paymentStatus === "true") {
      setShowSuccessModal(true);
      setIsSuccessModalVisible(true);

      const startDate = new Date(parseInt(startTime) * 1000);
      setSelectedDate(startDate);
      setSelectedTime(formatTime(startDate.getTime() / 1000));
      setApiSelectedTime(startTime);

      // Highlight the selected slot
      const timeSlot: any = formatTime(parseInt(startTime) * 1000);
      setSelectedButton(timeSlot);
      setIsPaidSucess(true);
    } else if (paymentStatus === "false") {
      // Handle failure scenario
      setIsFailureModalVisible(true);
      setFailedMessage("Payment failed. Please try again.");
      const startDate = new Date(parseInt(startTime) * 1000);
      setSelectedDate(startDate);
      setSelectedTime(formatTime(startDate.getTime() / 1000));
      setApiSelectedTime(startTime);

      // Highlight the selected slot
      const timeSlot: any = formatTime(parseInt(startTime) * 1000);
      setSelectedButton(timeSlot);
    }
  }, [location]);

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await _GET(
          `services/getServiceDetailsbyId/${serviceId}`,
          true,
          false
        );

        if (response.data && response.data) {
          setServiceData(response.data[0]);
          setSelectedLocationValue("service-provider");
          fetchBillingSummary();
        } else {
          console.error("Service data not found in response");
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    };

    fetchServiceData();
  }, [serviceId]);

  const handleAdditionalNotesChange = (notes: any) => {
    setAdditionalNotes(notes);
  };

  const showTermsModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  // useEffect(() => {}, [serviceData]);
  // useEffect(() => {
  //   if (serviceData) {
  //   }
  // }, [serviceData]);

  const myCards = async () => {
    try {
      const res = await _GET(`payment/getUserAllCard`, true);

      if (res.status === true) {
        const uniqueCards = _.uniqBy(res.data, "id");
        setCards(uniqueCards);
      } else {
        console.error("Error fetching cards: ", res.message);
      }
    } catch (error) {
      console.error("Error fetching cards: ", error);
    }
  };

  useEffect(() => {
    myCards();
  }, []);

  const CardComponent = ({ card, onSelect, isSelected }: any) => {
    if (!card) {
      return null;
    }

    const formattedCardNumber = `${card?.first_eight.substring(
      0,
      8
    )} **** ** ${card?.last_four.substring(2)}`;
    return (
      <div
        className={Styles.cardContainer}
        onClick={() => onSelect(isSelected ? null : card?.id)}
      >
        <div
          className={`${Styles.radioCircle} ${
            isSelected ? Styles.selectedCard : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: isSelected
              ? `<img src="${grdCirculeIcon}" alt="Circle Icon" />`
              : "",
          }}
        />
        <div className={Styles.cardDetails}>
          <div className={Styles.cardName}>{card?.name}</div>
          <div className={Styles.cardInfo}>
            <span className={Styles.cardBrand}>{card?.brand} </span> |{" "}
            <span className={Styles.cardNumber}>{formattedCardNumber}</span>
          </div>
        </div>
      </div>
    );
  };

  const convertSlotsToDate = (slots: any[]) => {
    return slots.map((slot) => {
      const date = new Date(slot.slotTime * 1000);
      return {
        ...slot,
        date,
      };
    });
  };

  const TimeSlotSkeleton = () => {
    return (
      <div className="time-slots-container">
        {Array.from({ length: 16 }).map((_, index) => (
          <Skeleton.Button
            key={index}
            active
            size="default"
            shape="round"
            style={{ width: 110, height: 35 }}
          />
        ))}
      </div>
    );
  };

  const getAllAppointments = async () => {
    const selectedUnixTimestamp = Math.floor(selectedDate.getTime() / 1000);
    setIsLoading(true);
    setTimeSlots([]);

    if (serviceId && serviceData) {
      const params = {
        buffer_time: 900,
        serviceTimeDuration: parseDuration(serviceData.service_duration),
        date: selectedUnixTimestamp.toString(),
        serviceId: serviceData.id,
        providerId: serviceData.provider_id,
        start_time: selectedUnixTimestamp,
      };
      try {
        const response = await _MGET(
          "services/getAllAppointment",
          params,
          false
        );
        if (
          response === undefined ||
          response.remainingSlotsWith.length === 0
        ) {
          setAvailabelSlots(false);
        }

        if (response.status) {
          const convertedSlots = convertSlotsToDate(
            response.remainingSlotsWith
          );
          setAvailabelSlots(true);
          setTimeSlots(convertedSlots);
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("serviceId or service is not defined");
    }
  };
  useEffect(() => {
    if (selectedDate && serviceData) {
      getAllAppointments();
    }
  }, [selectedDate, serviceData]);

  const handleLocationChange1 = (location: string, address: string) => {
    setSelectedLocationValue(location);
    setSelectedAddressValue(address);
  };

  function addressStringToObject(address: string) {
    const parts = address.split(",").map((part) => part.trim());

    return {
      address_name: parts[0] || "",
      building_no: parts[1] || "",
      floor: parts[2] || "",
      address: parts[3] || "",
      landmark: parts[4] || "",
      pincode: parts[5] || "",
      city: parts[6] || "",
      state: parts[7] || "",
      country: parts[8] || "",
      service_booking_address_id: parts[9] || "",
    };
  }

  const handleSlotReserveClick = () => {
    setShowConfirmationModal(true);
  };

  const parseDuration = (durationStr: any) => {
    const regex = /(\d+)d:(\d+)h:(\d+)m/;
    const matches = durationStr.match(regex);
    if (!matches) return 0;

    const days = parseInt(matches[1]);
    const hours = parseInt(matches[2]);
    const minutes = parseInt(matches[3]);

    return (days * 24 * 60 + hours * 60 + minutes) * 60;
  };

  const serviceDetailsRedux = useSelector((state: any) => state);

  const reserveSlot = async () => {
    try {
      const startTimeStore: any =
        selectedApiTime !== null ? parseInt(selectedApiTime) : null;
      const endTimeStore: any =
        startTimeStore !== null
          ? startTimeStore + parseDuration(serviceData.service_duration)
          : null;

      const bookSlotData = {
        serviceTimeDuration: parseDuration(serviceData.service_duration),
        start_time: selectedApiTime !== null ? selectedApiTime : "",
        buffer_time: 900,
        end_time:
          selectedApiTime !== null
            ? selectedApiTime + parseDuration(serviceData.service_duration)
            : "",
        serviceId: serviceData.id,
        providerId: serviceData.provider_id,
      };

      const reserveResponse = await _POST(
        `services/reserveSlot`,
        bookSlotData,
        false
      );
      console.log("🚀 ~ reserveSlot ~ reserveResponse:", reserveResponse);

      if (reserveResponse && reserveResponse.status === false) {
        notification.open({
          type: "error",
          message: `${reserveResponse.message}`,
          description: _.get(reserveResponse, "response.message", ""),
        });
        setIsReservationSuccessful(false);
      } else {
        notification.success({
          message: "Reservation Successful",
          description: "You have successfully reserved the time slot.",
        });
        setIsReservationSuccessful(true);
        setReserveID(reserveResponse?.reserveObject?.reserved_id);
        dispatch(
          setServiceDetails(serviceData.id, startTimeStore, endTimeStore)
        );
      }
    } catch (error) {
      console.error("Error reserving slot:", error);
      notification.error({
        message: "Something Went Wrong",
        description: "An error occurred while reserving the time slot.",
      });
      setIsReservationSuccessful(false);
    }
  };

  useEffect(() => {
    console.log("Updated Service Details:", serviceDetailsRedux);
  }, [serviceDetailsRedux]);

  const handleConfirmation = (confirmed: any) => {
    if (confirmed) {
      reserveSlot();
    }

    setShowConfirmationModal(false);
  };
  const addressObject = addressStringToObject(selectedAddressValue);

  function formatTime(timestamp: number): string {
    const slotTimeDate = new Date(timestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(slotTimeDate);
  }

  const handleBooking = async () => {
    if (!selectedTime || !isReservationSuccessful) {
      notification.error({
        message: "No Time Slot Selected",
        description: "Please select a Time before booking.",
        placement: "topLeft",
      });
      return;
    }

    if (!selectedAddressValue) {
      notification.error({
        message: "No Address Selected",
        description: "Please select an address before booking.",
        placement: "topLeft",
      });
      return;
    }
    const selectedUnixTimestamp = Math.floor(selectedDate.getTime() / 1000);
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

    const bookingData = {
      service_start_time: (selectedApiTime !== null
        ? selectedApiTime
        : ""
      ).toString(),
      service_end_time: (selectedApiTime !== null
        ? selectedApiTime + parseDuration(serviceData.service_duration)
        : ""
      ).toString(),
      customer_id: userInfo.id,
      service_time_duration: parseDuration(
        serviceData.service_duration
      ).toString(),
      service_id: serviceData.id,
      service_buffer_time: "900",
      additional_notes: additionalNotes,
      service_booking_address_id: addressObject.service_booking_address_id,
      booking_slot_id: reserveID,
    };

    const response = await _POST(`services/bookService`, bookingData, false);

    if (response && response.status) {
    if(response?.data?.status === "Paid" && response?.data?.is_coupon_used){

        showSuccessCustomModal({
          title: "Your booking completed successfully.",
          smallText:
            "Your booking & order is sent to the service provider",
          buttonText: "Continue",
              });
          history.push(APPLICATION_ROUTES.ORDER);
          }else{
          const paymentPayloadData = {
            email: userInfo.email,
            amount: billingSummary.amountToPay,
            currency: billingSummary.amountToPayCurrency,
            description: "This is my first and last payment",
            booking_id: _.get(response, "data.id", ""),
            card_id: selectedCard,
          };
    
          const data = await _POST(
            `payment/CreateCharge`,
            paymentPayloadData,
            true
          );
    
          window.location.href = data.data.redirect_url;}
    }
    const checkPayload: any = {
      buffer_time: "900",
      serviceTimeDuration: parseDuration(serviceData.service_duration),
      date: selectedUnixTimestamp.toString(),
      serviceId: serviceData.id.toString(),
      providerId: serviceData.provider_id.toString(),
      start_time: selectedApiTime !== null ? selectedApiTime : "",
      end_time:
        selectedApiTime !== null
          ? selectedApiTime + parseDuration(serviceData.service_duration)
          : "",
      bookingId: _.get(response, "data.id", ""),
    };

    try {
      const response = await _MGET(
        "services/checkSelectedDate",
        checkPayload,
        false
      );
    } catch (error) {}
  };

  const history = useHistory();

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  return (
    <>
      <MobileTopDashboardNavBar />
      <Modal
        open={showConfirmationModal}
        onCancel={() => handleConfirmation(false)}
        onOk={() => handleConfirmation(true)}
        className={Styles.confirmationModal}
        centered
        okText="Yes"
        cancelText="No"
      >
        <p className={Styles.confirmationText}>
          {" "}
          Are you sure you want to book this time slot?
        </p>
      </Modal>
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div style={{ marginTop: "50px", width: "95vw" }}>
          <Breadcrumb
            style={{ margin: "60px 0px 0px 0px" }}
            separator={<img src={breadcrumbIcon} alt=">" />}
          >
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/service")}
            >
              Services
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/service/" + serviceId)}
            >
              Service Details
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() =>
                handleBreadcrumbClick("/service-booking/" + serviceId)
              }
            >
              Service Booking
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className={`${Styles.serviceMainContainer} bookingPageContainer`}
            style={{ marginTop: "0 !important" }}
          >
            {serviceData && (
              <div className="infoContainer">
                {serviceData &&
                  serviceData.service_images !== null &&
                  serviceData.service_images.length > 0 && (
                    <img
                      src={serviceData.service_images[0]}
                      className="smallimg"
                      alt=""
                    />
                  )}
                <div className="serviceBookingInfo">
                  <h3 className="bold xlSize">{serviceData.service_name}</h3>
                  <p className="largeSize">
                    <span className="currency">BHD</span>{" "}
                    <span className="titlePrice">
                      {" "}
                      {serviceData.service_fees}
                    </span>{" "}
                    | {serviceData.service_category_main}
                  </p>
                </div>
              </div>
            )}
            {serviceData && (
              <div className="bookingMainContainer">
                <div className="date-picker-containerPar">
                  <div className="date-picker-container">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateCalendar
                        value={selectedDate}
                        onChange={(date: any) => setSelectedDate(date)}
                        className="date-picker"
                        minDate={today}
                        disabled={calendarDisabled}
                      />
                    </LocalizationProvider>
                    <p className="startContainer">
                      <h3 className="bold xlSize">Start Time</h3>
                      <Divider
                        orientation="left"
                        className="textdivider"
                      ></Divider>
                      <p className="extraText">
                        Duration{" "}
                        <span className="semibold">
                          {serviceData.service_duration}
                        </span>
                      </p>
                    </p>
                    {isLoading ? (
                      <TimeSlotSkeleton />
                    ) : (
                      <div
                        className={`${
                          availbleSlots === true
                            ? "time-slots-container"
                            : "time-slots-containerNull"
                        } `}
                      >
                        {availbleSlots === true ? (
                          timeSlots.map((slot: any) => (
                            <button
                              key={slot.slotTime}
                              className={`time-slot-button ${
                                slot.isBooked ? "booked" : ""
                              } ${
                                selectedButton === slot.slotTime
                                  ? "selected"
                                  : ""
                              } ${
                                slot.slotTime < currentTime
                                  ? "disabledSlot"
                                  : ""
                              }`}
                              disabled={
                                slot.isBooked || slot.slotTime < currentTime
                              }
                              onClick={() => {
                                if (
                                  slot.slotTime >= currentTime &&
                                  !slot.isBooked
                                ) {
                                  setApiSelectedTime(slot.slotTime);
                                  setSelectedTime(formatTime(slot.slotTime));
                                  setSelectedButton(slot.slotTime);
                                  handleSlotReserveClick();
                                }
                              }}
                            >
                              {formatTime(slot.slotTime)}
                            </button>
                          ))
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>
                              No slots available. Please try to select another
                              date!
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                    <div className={Styles.locationPref}>
                      <h3 className="bold xlSize">Location Preference</h3>

                      {serviceData.service_location ? (
                        <LocationSelector
                          onLocationChange={handleLocationChange1}
                          serviceLocation={
                            typeof serviceData?.service_location === "string"
                              ? JSON.parse(serviceData.service_location).address
                              : serviceData.service_location.address
                          }
                          serviceAtCustomerLocation={
                            serviceData?.service_at_customer_location
                          }
                          coords={coords}
                          setCoords={setCoords}
                        />
                      ) : (
                        <div
                          style={{
                            marginTop: "2rem",
                            marginBottom: "2rem",
                          }}
                        >
                          No location from service provider
                        </div>
                      )}
                    </div>
                  </div>
                  <TextArea
                    placeholder="Additional Notes..."
                    className="notesBox"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={(e) =>
                      handleAdditionalNotesChange(e.target.value)
                    }
                    value={additionalNotes}
                  />
                </div>
                <div className="paymentContainer">
                  <div className="paymentTop">
                    <section className="paySelection">
                      <h3 className="bold xlSize">Payment method</h3>
                      <div className="payTypeSelection">
                        {payTypes.map((type) => (
                          <button
                            key={type}
                            className={`payTypeBtn ${
                              type === selectedPayType ? "payActiveBtn" : ""
                            }`}
                            onClick={() => setSelectedPayType(type)}
                            style={{ marginBottom: "1rem" }}
                          >
                            {type}
                          </button>
                        ))}
                        {selectedPayType === "Card" &&
                          cards.slice(0, visibleCardsCount).map((card: any) => {
                            return (
                              <CardComponent
                                key={card?.id}
                                card={card}
                                onSelect={setSelectedCard}
                                isSelected={selectedCard === card?.id}
                              />
                            );
                          })}
                        {cards.length === 0 ? (
                          <p>Please Proceed to pay to add a new card</p>
                        ) : (
                          ""
                        )}
                        {selectedPayType === "Card" &&
                          cards.length > visibleCardsCount && (
                            <button
                              onClick={() => setVisibleCardsCount(cards.length)}
                              className={Styles.cardLoadMore}
                            >
                              Load All
                            </button>
                          )}
                        {selectedPayType === "Card" &&
                          cards.length === visibleCardsCount && (
                            <button
                              onClick={() => setVisibleCardsCount(3)}
                              className={Styles.cardLoadMore}
                            >
                              Show Less
                            </button>
                          )}
                      </div>
                      <Button
                        onClick={() => {}}
                        className="blackOutline addCardBtn"
                        style={{ marginTop: "20px" }}
                        disabled={true}
                      >
                        <p className="bold m-0">{`+ Add ${selectedPayType}`}</p>
                      </Button>
                    </section>
                    {/* <section>
                    <div className="couponheader">
                      <h3 className="bold xlSize">Apply Coupon</h3>
                      <p className="readMore">MY COUPONS</p>
                    </div>
                    <div
                      style={{ backgroundColor: "#f5f6f6" }}
                      className="inputBox applyBtn"
                    >
                      <Percentage />
                      <span
                        className="inputField applyCenter"
                        style={{
                          backgroundColor: "none !important",
                          background: "none !important",
                          boxShadow: "none !important",
                        }}
                      >
                        Enter Code
                      </span>
                      <button onClick={() => {}} className="applyText">
                        APPLY
                      </button>
                    </div>
                  </section>
                  <section>
                    <h3 className="bold xlSize">Apply OC Point</h3>
                    <div className="inputBox applyBtn useBtn">
                      <OCPoint />
                      <div className="inputField useCenter">
                        <p className="mainTitle">Saving BHD 10</p>
                        <p className="subTitle">From 100 OC balance</p>
                      </div>
                      <button onClick={() => {}} className="applyText">
                        USE
                      </button>
                    </div>
                  </section> */}
                  </div>

                  <div className="paymentBottom">
                    <section>
                      <div className="costHeader">
                        <h3 className="bold xlSize">Service fee</h3>
                        <h3 className="bold xlSize">
                          <span className="grayText">BHD</span>{" "}
                          <span>{billingSummary.subTotal}</span>
                        </h3>
                      </div>
                    { billingSummary?.service_discount_percentage > 0 && <div className="costHeader">
                        <p>Amount After Discount (@ {billingSummary?.service_discount_percentage} %) </p>
                        <p className="semibold">
                          <span className="grayText">BHD</span>{" "}
                          <span>
                            {billingSummary.service_price_after_discount}
                          </span>
                        </p>
                      </div>}
                      <div className="costHeader">
                        <p>Your location fee </p>
                        <p className="semibold">
                          <span className="grayText">BHD</span>{" "}
                          <span>
                            {selectedLocationValue === "home"
                              ? billingSummary.locationFee
                              : 0}
                          </span>
                        </p>
                      </div>
                      <div className="costHeader">
                        <p>Coupon discount</p>
                        <p className="semibold">
                          <span className="grayText">- BHD</span> <span>{billingSummary?.couponDiscount ?? "0.0"}</span>
                        </p>
                      </div>
                      <div className="costHeader">
                        <p>VAT (@ {billingSummary?.serviceVatPercentage})</p>
                        <p className="semibold">
                          <span className="grayText">+ BHD</span>{" "}
                          <span>{billingSummary.vatAmountToPay}</span>
                        </p>
                      </div>
                    </section>
                    <Divider />
                    <section>
                      <div className="costHeader">
                        <h3 className="bold xlSize">Total Amount</h3>
                        <h3 className="bold xlSize">
                          <span className="grayText">BHD</span>{" "}
                          <span>{billingSummary.totalPay}</span>
                        </h3>
                      </div>

                      <>
                        <div className="costHeader">
                          <p className="grayText">
                            Advance pay {billingSummary.servicePayPercentage}
                          </p>
                          <p>
                            <span className="grayText">BHD </span>
                            {billingSummary.serviceAdvanceAmount}
                          </p>
                        </div>
                        <Divider />
                        <div className="costHeader">
                          <h3 className="bold xlSize">
                            Total Remaining Payment
                          </h3>
                          <h3 className="bold xlSize">
                            <span className="grayText">BHD</span>{" "}
                            <span>
                              {Number(billingSummary.remainingAmount)}
                            </span>
                          </h3>
                        </div>
                        <div className="costHeader">
                          <p className="grayText">
                            {" "}
                            Continue to accept our{" "}
                            <span
                              className="readMore"
                              onClick={() => ppSetModalVisible(true)}
                            >
                              {" "}
                              privacy policy
                            </span>{" "}
                            and{" "}
                            <span
                              className="readMore"
                              onClick={() => setModalVisible(true)}
                            >
                              terms & conditions.{" "}
                            </span>
                          </p>
                          <Button
                            shape="round"
                            className="buttonBG paySubmitBtn"
                            type="primary"
                            htmlType="button"
                            onClick={handleBooking}
                            disabled={isPaidSucess}
                            style={{
                              backgroundColor:
                                isPaidSucess === true ? "gray" : "",
                              borderColor: isPaidSucess ? "gray" : "",
                            }}
                          >
                            Pay BHD {billingSummary.amountToPay}
                          </Button>
                        </div>
                      </>
                    </section>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <PrivacyPolicy
        open={ppModalVisible}
        setOpen={ppSetModalVisible}
        setisTCChecked={setisPPChecked}
      />
      <TermsAndConditions
        open={modalVisible}
        setOpen={setModalVisible}
        setisTCChecked={setisTCChecked}
      />
      {failedMessage == "" && (
        <Dialog
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.successModalContent}
            style={{
              background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={tickPop} alt="" />
            <h2>Thank You!</h2>
            <p>Your booking & order is sent to service provider</p>
            {/* <p>You've received 100 OC point</p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleSuccessModalClose}
                style={{ marginTop: "24px" }}
              >
                Great!
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {failedMessage !== "" && (
        <Dialog
          open={isFailureModalVisible}
          onClose={handleFailureModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.failedModalContent}
            style={{
              // background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={FailedTick} alt="" />
            <h2>Payment Failed</h2>
            <p>{failedMessage}</p>
            {/* <p>You've received 100 OC point</p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Button
                type="primary"
                onClick={handleFailureModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button> */}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default BookingUiNew;
