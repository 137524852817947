import React, { useState } from "react";
import { useHistory } from "react-router";

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Input,
  Form,
  Button,
  Modal,
  notification,
  Typography,
  Space,
  Col,
  Row,
} from "antd";
import _ from "lodash";
import "./login.css";
import OctopusImage from "../../assests/logo.svg";
import backImg from "../../assests/Back.png";

import GoogleBtn from "../../common/GoogleBtn/GoogleBtn";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import ModalPopup from "../../common/Modal/Modal";
import { _POST, getRole } from "../../common/commonFunc";
import bcrypt from "bcryptjs";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import { getHashPassword } from "../../common/gethashPassword";
import mnLG from "../../assests/mnLgOct.svg";
import axios from "axios";
import { setCookie } from "../../utils/tokenService";
import InstantCheckout from "./InstantCheckout";

const LoginPopUp = ({ open, onClose, actionMessage }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInstantCheckout, setIsInstantCheckout] = useState(false)
  const userRole = localStorage.getItem("octo_role");
  const history = useHistory();

  const openInstantCheckout = () => {
    onClose()
    setIsInstantCheckout(true)
  }

  const closeInstanCheckout = () => {
    setIsInstantCheckout(false) 
  }

  const sdkConfig = {
    clientId: "5058pgkb5g57aoi8tonoaonp5j",
    clientSecret: "1dccsevrdvrphsti163v94dc78ss7u7l1r5l06nbgijmteb1tjtl",
    theme: {
      mainColor: "#FF5733",
      secondaryColor: "#2ECC71",
      backgroundColor: "#F4F4F4",
      cardBackgroundColor: "#FFFFFF",
      headingTextColor: "#333333",
      secondaryTextColor: "#777777",
      secondaryBorderColor: "#DDDDDD",
      iconFillColor: "#555555",
      iconBorderColor: "#888888",
      iconTextColor: "#FFFFFF",
      logo: "https://example.com/logo.png",
      disableGuidance: false,
      failedText: "Operation failed. Please try again.",
      successText: "Operation successful!",
      buttonbg: "#F8B427",
      textBg: "#EFEFEF",
      verificationProcessingText: "Processing verification...",
    },
    onSuccess: (data: any) => {},
    onFail: (data: any) => {
      console.error("SDK operation failed:", data);
    },
  };
  const onLogin = async (values: any) => {
    setIsModalOpen(true);

    try {
      const hashedPassword = await getHashPassword(values.password);
      const res: any = await _POST(
        "login",
        { ...values, password: hashedPassword },
        false,
        false
      );
      console.log("🚀 ~ onLogin ~ res:", res);
      console.log(
        " res?.data.is_document_updated",
        res?.data?.is_document_updated
      );

      if (res && res.status === false) {
        // console.log("000000000000");
        notification.info({
          message: res.message,
        });
        // return;
      }

      if (res && !res.status) {
        localStorage.setItem("userEmail", res?.data?.email);
        localStorage.setItem("octo_role", res?.data?.role);
        localStorage.setItem("businessType", res?.data?.businessType);

        const userInfo = {
          id: res?.data?.id || res?.data?.user?.id,
          first_name: res?.data?.first_name || res?.data?.user?.first_name,
          last_name: res?.data?.last_name || res?.data?.user?.last_name,
          email: res?.data?.email || res?.data?.user?.email,
          phone_number:
            res?.data?.phone_number || res?.data?.user?.phone_number,
          birthdate: res?.data?.birthdate || res?.data?.user?.birthdate,
          gender: res?.data?.gender || res?.data?.user?.gender,
          country: res?.data?.country || res?.data?.user?.country,
          register_type:
            res?.data?.register_type || res?.data?.user?.register_type,
          zoho_user_id:
            res?.data?.zoho_user_id || res?.data?.user?.zoho_user_id,
          countryCode: res?.data?.country_code || res?.data?.user?.country_code,
          phoneNumber: res?.data?.phone_number || res?.data?.user?.phone_number,
          userType: res?.data?.user_type || res?.data?.user?.user_type,
          token: res?.data?.token || res?.data?.user?.token,
          phone_no: res?.data?.phone_no || res?.data?.user?.phone_no,
          country_code:
            res?.data?.country_code || res?.data?.user?.country_code,
          is_document_updated:
            res?.data?.is_document_updated ||
            res?.data?.user?.is_document_updated,
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        console.log("res.data.is_phone_verified", res.data.is_phone_verified);

        console.log("data?.data", res?.data);
        const userRoleNew = res?.data?.user?.role || res?.data?.role;
        const isUserOrSupplier =
          userRoleNew === "user" || userRoleNew === "supplier";
        console.log("isUserOrSupplier", isUserOrSupplier);
        const isDocumentNotUpdated =
          res?.data?.is_document_updated === false ||
          res?.data?.user?.is_document_updated === false;

        // if(res?.data?.user_type || res?.data?.user?.user_type === "ShareHolder"){
        //   // Check if docs verified or not
        //   if(res?.data?.is_document_updated === false || res?.data?.user?.is_document_updated === false){
        //     history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
        //     return;
        //   }

        // }

        if (
          (res.data && res.data.is_email_verified === false) ||
          res.data?.user?.is_email_verified === false
        ) {
          history.push(APPLICATION_ROUTES.EMAIL_VERIFY);
          return;
        }

        if (
          (res.data && res.data.is_phone_verified === false) ||
          res.data?.user?.is_phone_verified === false
        ) {
          history.push(APPLICATION_ROUTES.VERIFY_OTP);
          return;
        }

        if (!isUserOrSupplier && isDocumentNotUpdated) {
          console.log("Not A User", res?.data?.user?.ekyc_response_id);
          if (
            res?.data?.user?.ekyc_response_id !== null &&
            res?.data?.user?.ekyc_response_id !== undefined
          ) {
            localStorage.setItem(
              "ekyc_response_id",
              res?.data?.user?.ekyc_response_id
            );
          }
          history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
          return;
        }

        if (
          (userRole !== "supplier" &&
            res?.data &&
            res?.data.agreed_to_terms_and_condition === false) ||
          res?.data?.user?.agreed_to_terms_and_condition === false
        ) {
          history.push(APPLICATION_ROUTES.CATEGORIES);
          return;
        }

        if (
          (userRole === "supplier" || res?.data?.role === "supplier") &&
          res.data &&
          (res.data.agreed_to_terms_and_condition === false ||
            res.data.agreed_to_terms_and_condition === null)
        ) {
          history.push(APPLICATION_ROUTES.SUPPLIER_HOME);
          console.log("Failed to login");
          localStorage.setItem(
            "supplier_form_step",
            res?.data?.supplier_form_step
          );
          localStorage.setItem("Temp_jwtToken", res.data.token);
          localStorage.setItem("supplier_id", res?.data?.supplier_id);
          localStorage.setItem("userEmail", res?.data?.email);
          localStorage.setItem("businessType", res?.data?.businessType);
          return;
        }
        if (
          (userRole === "supplier" || res?.data?.role === "supplier") &&
          res.data &&
          res.data.supplier_form_step < 4
        ) {
          history.push(
            `${APPLICATION_ROUTES.SUPPLIER_REGISTRATION}?step=${res.data.supplier_form_step}`
          );
          localStorage.setItem(
            "supplier_form_step",
            res.data.supplier_form_step.toString()
          );
          localStorage.setItem("Temp_jwtToken", res.data.token);
          localStorage.setItem("supplier_id", res.data.supplier_id);
          localStorage.setItem("userEmail", res.data.email);
          return;
        }

        if (
          (userRole !== "supplier" || res?.data?.role !== "supplier") &&
          res.data &&
          res.data.agreed_to_terms_and_condition === false
        ) {
          history.push(APPLICATION_ROUTES.CATEGORIES);
          return;
        }
      }

      if (res && res?.data.token) {
        showSuccessCustomModal({
          title: "Login SuccessFully",
          smallText: "You are logged in successfully",
          buttonText: "Continue",
        });
        try {
          localStorage.setItem("jwtToken", res?.data?.token);
          console.log("Token stored in localStorage:", res?.data?.token);
        } catch (error) {
          console.error("Failed to store token:", error);
        }

        setCookie("refreshToken", res?.data?.refreshToken, 720);
        // axios.defaults.headers.common["Authorization"] = res?.data?.token;
        localStorage.setItem("octo_zoho_user_id", res?.data?.user.zoho_user_id);
        localStorage.setItem("octo_role", res?.data?.user.role);
        localStorage.setItem("userInfo", JSON.stringify(res?.data?.user));
        localStorage.setItem("businessType", res?.data?.businessType);

        const currentTime = Date.now().toString();
        localStorage.setItem("loginTime", currentTime);
        console.log("Login time set in localStorage:", currentTime);

        const roleofUser = getRole();
        const userRole = res?.data?.user.role;
        if (userRole === "supplier") {
          console.log("res?.data?", res?.data);
          history.push(`${APPLICATION_ROUTES.SUPPLIER_HOME}/dashboard`);
        } else {
          window.location.reload();
        }

        // history.push(APPLICATION_ROUTES.DASHBOARD);
      }
    } catch (error) {
      console.log("🚀 ~ onLogin ~ error:", error);
      return notification.open({
        type: "info",
        message: "Something went wrong!. Please try again",
        description: _.get(error, "message", "error"),
      });
    }
  };
  return (
    <>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      className="loginPopUp"
      sx={{
        "& .MuiPaper-root": { borderRadius: 6 },
        // background: "linear-gradient(151deg, #8200FF 0%, #00B0FF 60.52%)",
        "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .css-y2taew .MuiPaper-root": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .loginPopUp .MuiDialog-paperWidthSm": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .MuiDialog-paperWidthSm": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            backgroundColor: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginTop: "12%",
            width: "100%",
            height: "100%",
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            align="start"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "100px",
                height: "100px",
                marginBottom: "8px",
                marginTop: "8px",
              }}
              src={OctopusImage}
              alt=""
            />
            {/* <Typography.Title
              level={2}
              style={{
                textAlign: "left",
                fontFamily: "Ubuntu",
                fontWeight: 600,
                marginBottom: "8px",
              }}
            >
             Please log in or sign up to continue.
            </Typography.Title> */}
            <Typography.Text
              style={{
                textAlign: "left",
                fontFamily: "Ubuntu",
                fontWeight: "bold",
                marginTop: "0px",
                fontSize: "20px",
              }}
            >
              {actionMessage
                ? actionMessage
                : "Please login or sign up to continue."}
            </Typography.Text>
          </Space>

          <Form
            size="large"
            name="basic"
            onFinish={onLogin}
            style={{ margin: "8% 15%", width: "70%", height: "80%" }}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                prefix={<MailOutlined className="preFixAntdClass" />}
                placeholder="Email"
                style={{ height: "40px", borderRadius: "67px" }}
              />
            </Form.Item>

            <Form.Item
              // label='Password'
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="preFixAntdClass" />}
                placeholder="Password"
                style={{ height: "40px", borderRadius: "67px" }}
              />
            </Form.Item>
            <Form.Item>
              <div className="extraButtonSectionLogin">
                <Button
                  style={{ color: "black", fontWeight: "bold" }}
                  type="link"
                  onClick={() =>
                    history.push(APPLICATION_ROUTES.FORGOT_PASSWORD)
                  }
                >
                  Forgot Password ?
                </Button>
              </div>
            </Form.Item>
            <div className="loginButtons">
              <Form.Item
                style={{ width: "100%", minWidth: "180px", marginBottom: 0 }}
              >
                <Button
                  shape="round"
                  className="buttonBG"
                  type="primary"
                  htmlType="submit"
                  // loading={loginLoading}
                  style={{ width: "100%" }}
                >
                  Login
                </Button>
              </Form.Item>

              <div style={{ width: "100%" }}>
                <GoogleBtn pageType="Login" text="string" />
              </div>
            </div>
            <div className="extraButtonSectionLogin">
                <Button
                  style={{ color: "black", fontWeight: "bold" }}
                  type="link"
                  onClick={() =>
                   openInstantCheckout()
                  }
                >
                  {"Quick Checkout >"}
                </Button>
              </div>
            <div className="extraButtonSectionLogin">
              <div className="extrasCenter">
                <span>You dont have account ?</span>
                <Button
                  style={{ color: "black", fontWeight: "bold", padding: 0 }}
                  type="link"
                  onClick={() => history.push(APPLICATION_ROUTES.HOME)}
                >
                  Sign Up?
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </DialogContent>
    </Dialog>

 <InstantCheckout open={isInstantCheckout} onClose={closeInstanCheckout} />
 </>
  );
};

export default LoginPopUp;
