import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Chip,
  Avatar,
  Divider,
  Card,
  CardContent,
  IconButton,
  CardMedia,
  TextField
} from "@mui/material";
import Styles from "./ProdStyleTable.module.css";
import { Button, Drawer, Input, Select, Space, notification, message, Popconfirm, Radio } from "antd";
import Delete from "../../../assests/customIcons/Delete";
import { _GET, _POST, _PATCH, _DELETE } from "../../../common/commonFunc";
import {
  showSuccessCustomModal,
} from "../../../common/CustomModal/CustomModal";
import ClearIcon from '@mui/icons-material/Clear';
import Cross from "../../../assests/customIcons/Cross";
import { useParams } from "react-router";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";


const ProductDetailsTable = ({ productData, onRowSelection, getProductDetails }: any) => {
  console.log("🚀 ~ ProductDetailsTable ~ productData:", productData);
  const [selectedDrawerService, setSelectedDrawerService] = useState<any>([]);
  const [editedRow, setEditedRow] = useState<any>([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [assignees, setAssignees] = useState<any>([])
  const [showCard, setShowCard] = useState(false)
  const [selectedAssignee, setSelectedAssignee] = useState<any | undefined>();
  const [assignButton, setAssignButton] = useState(false)
  const [showSave, setShowSave] = useState(false);
  const [EditedQty, setEditedQty] = useState({});
  const [quantityDifference, setQuantityDifference] = useState(0);
  const [editingRowId, setEditingRowId] = useState(null);
  const { id }: any = useParams();
  const [selectedAssigneeContract, setSelectedAssigneeContract] = useState<any | null>([]);
  const [contractType, setContractType] = useState<string | null>()
  const [advancePayPercent, setAdvancePayPercent] = useState(10);
  const [clickedRowId, setClickedRowId] = useState(0)
  // const saveVisibility = (row : any) => {

  //   console.log("value setting in setSelectedRow is : ",row)
  //   setShowSave(true);
  //   setSelectedRow(row)
  // };

  const {Option} = Select;

  const saveVisibility = (row: any) => {
    setEditingRowId(row.inventory_id);
    console.log("value setting in editedRow is : ", row)
    setShowSave(true);
    setEditedRow(row)
  };

  const handleCancelEdit = () => {
    setShowSave(false);
    setEditingRowId(null);
  }

  const getAssigneesData = async () => {
    try {
      const res = await _GET(`supplier/allVerifiedCollaborations`, true, false);
      // console.log("response is : ", res.data);
      return res.data;
    } catch (error) {
      console.error("Error fetching product data:", error);
      return {};
    }
  };

  useEffect(() => {
    getAssigneesData().then((data) => {
      if (data && data?.length > 0) {
        const filteredAssignee = data.filter((d: any) => d?.contracts && d?.contracts?.length > 0);
        setAssignees(filteredAssignee);
      } else {
        setAssignees([]);
      }
      // console.log("Assignees Data is : ", data)
    })
  }, []);

  useEffect(() => {
    console.log("Selected Assignees is : ", selectedAssignee)
    const contract = selectedAssignee?.contracts?.filter((c: any) => c?.contract_product_id === id);
    setSelectedAssigneeContract(contract)
    // console.log("selected contract id : ", selectedAssigneeContract)
  }, [selectedAssignee])

  useEffect(() => {
    console.log("Selected Assignees is : ", selectedDrawerService)
  }, [selectedDrawerService])


  const handleContractType = (event: any) => {
    console.log("selected radio is : ", event.target.value)
    setContractType(event.target.value);
  };


  if (!productData || !productData.inventory || !productData.collaborations) {
    return <div>Loading...</div>;
  }

  const handleRowClick = (rowId: number) => {
    const selectedRow = productData.inventory.find((row: any) => row.inventory_id === rowId);
    let colorObj = {
      color : selectedRow.color,
      colorCode :  selectedRow.colorCode ?? "#fff"
    }
    if (selectedRow) {
      console.log("images in the row are : ", selectedRow.inventory_images, colorObj);
      onRowSelection(selectedRow.inventory_images, colorObj);

    }
    setClickedRowId(rowId)
  };

  const handleCommissionChange = (commissionRate: any) => {
    // console.log("commission rate from handle commission change is ", commissionRate);

    setSelectedDrawerService((prev: any) => ({
      ...prev,
      commission_rate: commissionRate
    }))
  };

  const handleFixedAmountChange = (fixedAmount: any) => {
    // console.log("fixed amount from handle fixed amt change is ", fixedAmount);

    setSelectedDrawerService((prev: any) => ({
      ...prev,
      fixed_amount: fixedAmount
    }))
  };

  const handleQtyChange = (assignedQty: any) => {
    // console.log(" assigned quantity from handle qty change is ", assignedQty);

    setSelectedDrawerService((prevState: any) => ({
      ...prevState,
      assigned_quantity: assignedQty,
    }));

    setAssignButton(true);
  };

  const handleEditQty = (editedQty: any, originalQty: any) => {

    setEditedRow((prev: any) => ({
      ...prev,
      quantity: editedQty
    }))
    setQuantityDifference(editedQty - originalQty);
  };

  const handleQuantityChange = (editedQty: any, originalQty: any) => {
    if (editedQty.length <= 8 && /^\d*$/.test(editedQty)) {
      handleEditQty(editedQty, originalQty);
    }
  };

  const handleAdvancePayPercentage = (value: any) => {
    setAdvancePayPercent(value);
  };

  const handleSelectChange = (value: any) => {
    // console.log("assignee selection changed - ", value);

    const assignee = assignees.find((a: any) => a.collaborationRequestId === value);


    setSelectedAssignee(assignee);
    setSelectedDrawerService((prev: any) => ({
      ...prev,
      // commission_rate: assignee?.contracts?.[0]?.commission_rate
      commission_rate: assignee?.contracts?.find((contract: any) => contract.contract_product_id === id)?.commission_rate
    }));
    setShowCard(true);
    setContractType(null);
    setAdvancePayPercent(10)
  };

  const openModal = (item: any) => {
    setSelectedDrawerService(item);
    setVisibleModal(true);
  };
  const closeModal = () => {
    setVisibleModal(false);
    setSelectedAssignee(undefined);
    setSelectedDrawerService([]);
    setShowCard(false);
    setAssignButton(false);
    setSelectedAssigneeContract(null);
    setContractType(null);
    setAdvancePayPercent(10);
  };

  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

 

  const handleDelete = async (inventoryId: any) => {
    try {

      const url = `supplier/deleteProductInventory/${inventoryId}`;
      const response = await _DELETE(url, true);

      if (response.status && response.statusCode === 200) {
        notification.success({
          message: "Product Inventory Deleted",
          description: "The inventory has been successfully deleted.",
        });
        getProductDetails();
      } else {
        notification.error({
          message: "Error",
          description: "There was a problem in deleting the inventory.",
        });
      }
    } catch (error) {
      console.error("Error in deleting inventory:", error);
    }

  };

  const handleAssign = async (newCollab: boolean, contract : string) => {

    if (selectedDrawerService?.assigned_quantity === 0) {
      notification.error({
        message: "Error",
        description: "Assign Quantity cannot be 0",
      });
      return;
    }

    if (selectedDrawerService?.assigned_quantity === "0") {
      notification.error({
        message: "Error",
        description: "Assign Quantity cannot be 0"
      });
      return;
    }
    if (selectedDrawerService?.assigned_quantity === "") {
      notification.error({
        message: "Error",
        description: "Please Assign Some Quantiy"
      });
      return;
    }
    if (selectedDrawerService.assigned_quantity > selectedDrawerService.unassigned_quantity) {
      notification.error({
        message: "Error",
        description: "Assign Quantity cannot be more than In Store Quantity"
      });
      return;
    }

    if (selectedDrawerService?.assigned_quantity < 0) {
      notification.error({
        message: "Error",
        description: "Assign Quantity cannot be negative"
      });
      return;
    }

    if (newCollab) {

      if(contract === 'commission_percentage'){

      if (selectedDrawerService?.commission_rate === "" || selectedDrawerService?.commission_rate === undefined || selectedDrawerService?.commission_rate === null) {
        notification.error({
          message: "Error",
          description: "Please enter commission rate"
        });
        return;
      }

      if (selectedDrawerService.commission_rate > 100) {
        notification.error({
          message: "Error",
          description: "Please enter a valid comission rate"
        });
        return;
      }

      if (selectedDrawerService?.commission_rate < 0) {
        notification.error({
          message: "Error",
          description: "Comission Rate cannot be negative"
        });
        return;
      }

    }else if (contract = 'fixed_amount'){

    if (selectedDrawerService.fixed_amount < 0 || selectedDrawerService.fixed_amount > 100000 ) {
      notification.error({
        message: "Error",
        description: "Please enter a valid fixed amount"
      });
      return;
    }
  }
 
  }
    let payload = {}
    if (newCollab) {
      payload = {
        request_id: selectedAssignee.collaborationRequestId,
        freelancer_id: selectedAssignee.collaborated_freelancers.id,
        product_inventory_id: selectedDrawerService.inventory_id,
        assigned_quantity: selectedDrawerService?.assigned_quantity,
        commission_rate: selectedDrawerService?.commission_rate ?? 0.00,
        fixed_amount: Number(selectedDrawerService?.fixed_amount) ?? 0.00,
        advance_amount_percentage: advancePayPercent,
        contract_type : contractType
      }
    } else {
      payload = {
        request_id: productData.collaborations[0].collaboration_id,
        freelancer_id: productData.collaborations[0].collaborated_freelancer.id,
        product_inventory_id: selectedDrawerService.inventory_id,
        assigned_quantity: selectedDrawerService?.assigned_quantity,
        commission_rate: selectedDrawerService?.commission_rate,
      }
    }

    // console.log("Payload is : ", payload)
    try {
      const response: any = await _POST(
        `supplier/assignProductInventory`,
        payload,
        false
      );
      if (response.status === true && response.statusCode === 200) {
        showSuccessCustomModal({
          title: "Products Assigned Successfully",
          smallText: "Your Assign Product Request has been sent successfully.",
          buttonText: "Continue",
        });
        getProductDetails();
        setVisibleModal(false);
        closeModal();
      } else {
        notification.error({
          message: "Error",
          description: response.message,
        });
        getProductDetails();
      }
    } catch (error) {
      console.log("Error in assignment : ", error);
    }
  }


  const handleSave = async (id: any, assignedQty: any) => {


    if (editedRow.quantity === "0") {
      notification.error({
        message: "Error",
        description: " Quantity cannot be 0"
      });
      return;
    }

    if (quantityDifference < 0) {
      if (editedRow.quantity < assignedQty) {
        notification.error({
          message: "Error",
          description: "Cannot remove assigned quantity"
        });
        return;
      }
    }

    if (editedRow?.quantity === "" || editedRow?.quantity === undefined || editedRow?.quantity === null || editedRow?.quantity < 0) {
      notification.error({
        message: "Error",
        description: "Please enter a valid quantity"
      });
      return;
    }

    const payload = {
      quantityAdded: quantityDifference
    };
    try {
      const response = await _PATCH(
        `supplier/updateInventory/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Quantity updated Sucessfully!");
      } else {
        message.error(
          response.data && response.message
            ? response.message
            : "Error in updating quantity"
        );
      }
      setShowSave(false);
      setEditingRowId(null);
      getProductDetails();
    } catch (error) {
      console.error("Error in updating Quantity:", error);
    }
  }
  const dropdownRender = (menu: any) => (
    <Space
      style={{
        // padding: "0 8px 4px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <div>
        {menu}
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            padding: 8,
            borderBottom: "2px solid #f0f0f0",
            flexDirection: "column",
            alignItems: "center",
          }}
        ></div>
      </div>
    </Space>
  );

  const handleScroll = (event : any) => {
    event.target.blur();
  }

  return (
    <Box sx={{ padding: 2 }}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto", boxShadow: "none !important" }}
        className={Styles.tablecontainer}
      >
        <Table stickyHeader className={Styles.table}>
          <TableHead className={Styles.tableHeader}>
            <TableRow>
              {/* <TableCell>Size</TableCell> */}
              <TableCell>COLOR</TableCell>
              <TableCell> STORE QTY</TableCell>
              <TableCell>SIZE</TableCell>
              <TableCell>ASSIGNED QUANTITY</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={Styles.tableBody}>
            {productData?.inventory?.map((inventoryItem: any) => (
              <TableRow key={inventoryItem.inventory_id} onClick={() => handleRowClick(inventoryItem.inventory_id)} 
              style={{cursor:"pointer"}}
              className={clickedRowId === inventoryItem.inventory_id ? Styles.highlightedRow : ""}
              >
                {/* <TableCell>{inventoryItem.size}</TableCell> */}
                <TableCell className={Styles.colorChip}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><Chip
                    style={{
                      backgroundColor: inventoryItem.colorCode,
                      border: "1px solid #c4c4c4",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      marginRight: "10px"
                    }}
                  /> {inventoryItem?.color}</div>
                </TableCell>


                {editingRowId === inventoryItem.inventory_id ? (
                  <TableCell className={Styles.colorChip2}>
                    <Input
                      className={Styles.formInput1}
                      placeholder="Enter Quantity"
                      type="number"
                      onWheel={handleScroll}
                      value={
                        editedRow.quantity
                      }
                      onChange={(e) => handleQuantityChange(e.target.value, inventoryItem.quantity)}
                      maxLength={8}
                    />
                  </TableCell>

                ) : (
                  <TableCell className={Styles.colorChip}>
                    {inventoryItem.quantity}
                  </TableCell>
                )
                }


                <TableCell className={Styles.colorChip}>
                  {/* <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {inventoryItem.assigned_freelancer.map((freelancer: any, index: any) => (
                      <Box key={index}>
                        {freelancer.profile_pic ? (
                          <Avatar
                            key={index}
                            alt={`${freelancer.first_name} ${freelancer.last_name}`}
                            src={freelancer.profile_pic}
                            sx={{
                              width: 30,
                              height: 30,
                              border: "2px solid white",
                            }}
                          />
                        ) : (
                          freelancer?.first_name &&
        
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "darkslategrey",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {freelancer?.first_name[0]?.toUpperCase()}{freelancer?.last_name[0]?.toUpperCase()}
        </div>
                        )}
                      </Box>
                    ))}
                  </Box> */}
                   {inventoryItem.size}
                </TableCell>
                <TableCell className={Styles.colorChip}>
                  {inventoryItem.assigned_quantity}/{inventoryItem.quantity}
                </TableCell>
                {editingRowId === inventoryItem.inventory_id ? (
                  <TableCell className={Styles.colorChip1}>
                    <Popconfirm
                      title={quantityDifference < 0 ? `Are you sure you want to remove ${Math.abs(quantityDifference)}? items` : `Are you sure you want to add ${quantityDifference} items?`}
                      onConfirm={() => handleSave(inventoryItem.inventory_id, inventoryItem.assigned_quantity)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className={Styles.addButton}>Save</Button>
                    </Popconfirm>

                    <IconButton onClick={handleCancelEdit}>
                      <ClearIcon />
                    </IconButton>
                  </TableCell>) : (<TableCell className={Styles.colorChip}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems:"center" }}>
                      {assignees.length > 0 && <Button className={Styles.addButton}
                        disabled={inventoryItem.assigned_quantity === inventoryItem.quantity || inventoryItem.assigned_quantity > inventoryItem.quantity}
                        onClick={() => openModal(inventoryItem)}
                      >
                        {(inventoryItem.assigned_quantity === inventoryItem.quantity) || (inventoryItem.assigned_quantity > inventoryItem.quantity) ? 'Assigned' : 'Assign'}
                      </Button>}
                      <IconButton onClick={() => saveVisibility(inventoryItem)}>
                        <EditOutlinedIcon />
                      </IconButton>
                      <Popconfirm
                        title="Are you sure you want to Delete the inventory?"
                        onConfirm={() => handleDelete(inventoryItem.inventory_id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <IconButton style={{ display: inventoryItem.assigned_quantity > 0 ? "none" : "block" }}>
                          <Delete />
                        </IconButton>
                      </Popconfirm>

                    </Box>
                  </TableCell>)
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* drawer section */}

      <Drawer
        title="Assign Quantity"
        placement="right"
        closable={false}
        open={visibleModal}
        width={550}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={closeModal} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >

<div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "15px", marginTop:"0px"}}>
              {selectedDrawerService?.inventory_images?.length === 0 ?
                <img
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    marginRight: "5px"
                  }}
                  src="https://via.placeholder.com/300x200?text=Image+Not+Available"
                  alt={"Product Image"}
                />
                :
                selectedDrawerService?.inventory_images?.length >= 1 &&
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      marginRight: "5px"
                    }}
                    src={selectedDrawerService?.inventory_images[0]}
                    alt={"Product Image"}
                  />
              }
              <Typography variant="h6" ml={2}>{productData.product_name}</Typography>

            </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div>
          <div
                 style={{
                  fontSize: "12px",
                  color: "#30263E",
                  fontWeight: "500",
                  opacity: "50%",
                }}
            >Inventory Color : </div> 
            <div className={Styles.dgRowDiv}>
              <div
                className={Styles.productImage}
                style={{
                  backgroundColor: selectedDrawerService.colorCode,
                }}
              ></div>
              {selectedDrawerService.color}
            </div>
          </div>
          <div>
          <div
                 style={{
                  fontSize: "12px",
                  color: "#30263E",
                  fontWeight: "500",
                  opacity: "50%",
                }}
            >Size : </div> 
            <div className={Styles.dgRowDiv}>
              {selectedDrawerService.size}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
                <div
              style={{
                fontSize: "12px",
                color: "#30263E",
                fontWeight: "500",
                opacity: "50%",
              }}
            >
              In store Qty : {selectedDrawerService.quantity}
            </div>
            <div
              style={{ fontSize: "15px", color: "#30263E", fontWeight: "700" }}
            >
              Unassigned Qty: {selectedDrawerService.unassigned_quantity}
            </div>
        
          </div>
        </div>
        <Divider />

        <Box>
          {productData?.collaborations.length > 0 ? (
            productData?.collaborations[0].contract_type === 'commission_percentage' || productData?.collaborations[0].contract_type === 'fixed_amount' ?
            <>
              <Box marginTop={3}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography component="div" variant="button" color="text.secondary" gutterBottom>
                    Assignee :
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
                    <img
                      src={productData.collaborations[0].collaborated_freelancer.profile_pic}
                      alt={""}
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "8px",
                        verticalAlign: "middle",
                        borderRadius: "50%",
                        border:"1px solid #d8d8d8"
                      }}
                    />
                    {productData.collaborations[0].collaborated_freelancer.first_name} {productData.collaborations[0].collaborated_freelancer.last_name}
                  </div>
                </div>
              </Box>
              <Box marginTop={3}>
                <Card sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                        ASSIGN TO
                      </Typography>
                      <Divider />
                      <Typography component="div" variant="subtitle2" marginTop={3} style={{ overflow: "hidden", whiteSpace: "normal", textOverflow: "ellipsis", maxWidth: "150px" }}>
                        {productData.collaborations[0].collaborated_freelancer.first_name} {productData.collaborations[0].collaborated_freelancer.last_name}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" component="div">
                        {formatDate(productData.collaborations[0].collaboration_start_date)} {"to"} {formatDate(productData.collaborations[0].collaboration_end_date)}
                      </Typography>
                    </CardContent>
                  </Box>
                  {productData?.collaborations[0].contract_type === 'commission_percentage' ?
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                          COMMISSION RATE

                        </Typography>
                        <Divider />
                        <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                          <Input
                            className={Styles.formInput}
                            placeholder="Enter commission rate"
                            type="number"
                            onWheel={handleScroll}
                            disabled={
                              !!productData?.collaborations[0].commssion_rate
                            }
                            value={
                              productData?.collaborations[0].commssion_rate ?? ""
                            }
                            onChange={(e) => handleCommissionChange(e.target.value)}
                          />

                        </Box>
                      </CardContent>
                    </Box>
                    :
                    <>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                          AMOUNT

                        </Typography>
                        <Divider />
                        <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                          <Input
                            className={Styles.formInput}
                            placeholder="Enter Fixed Amount"
                            type="number"
                            onWheel={handleScroll}
                            disabled={
                              productData?.collaborations[0].contract_fixed_amount > 0
                            }
                            value={
                              productData?.collaborations[0].contract_fixed_amount ?? ""
                            }
                            onChange={(e) => handleFixedAmountChange(e.target.value)}
                          />

                        </Box>
                      </CardContent>
                      
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                          ADVANCE %

                        </Typography>
                        <Divider />
                        <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                        <Select
                         value={advancePayPercent}
                         onChange={handleAdvancePayPercentage}
                         style={{ width: 100 }}
                         className={Styles.formSelect}
                         disabled={
                          productData?.collaborations[0].contract_fixed_amount > 0
                         }
                       >
                         <Option value={10}>10%</Option>
                         <Option value={25}>25%</Option>
                         <Option value={50}>50%</Option>
                         <Option value={75}>75%</Option>
                         <Option value={100}>100%</Option>
                       </Select>

                        </Box>
                      </CardContent>
                      
                    </Box>
                    </>
                 
                  }
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                         QUANTITY

                      </Typography>
                      <Divider />
                      <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                        <input
                          className={Styles.formInput}
                          placeholder="Enter Qty"
                          type="number"
                          onWheel={handleScroll}
                          onChange={(e) => handleQtyChange(e.target.value)}
                        />

                      </Box>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
              <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={5}>
                <Button className={Styles.addButton}
                  style={{ display: assignButton ? 'block' : 'none' }}
                  onClick={() => handleAssign(false, productData?.collaborations[0].contract_type)}
                >Assign</Button>
              </Box>
            </>
            :""
            // else condition now
          ) : (<>
            <Box marginTop={3}>
              <Select
                placeholder="Please Select An Assignee"
                style={{ width: "100%" }}
                dropdownRender={dropdownRender}
                className={Styles.dropdownMain}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={selectedAssignee?.collaborationRequestId}
                onChange={handleSelectChange}
              >
                {assignees && assignees?.map((freelancer: any) => (
                  <Select.Option
                    key={freelancer.collaborationRequestId}
                    value={freelancer.collaborationRequestId}
                  >
                    {freelancer.collaborated_freelancers.profile_pic ? (
                      <img
                        src={freelancer.collaborated_freelancers.profile_pic}
                        alt={freelancer.collaborated_freelancers.first_name}
                        style={{
                          width: "25px",
                          height: "25px",
                          marginRight: "8px",
                          verticalAlign: "middle",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          marginRight: "8px",
                          verticalAlign: "middle",
                          background:
                            "linear-gradient(92.54deg, #8800FF 0%, #00B2FF 100%)",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {freelancer.collaborated_freelancers.first_name.toUpperCase()}
                      </span>
                    )}

                    {`${freelancer.collaborated_freelancers.first_name} ${freelancer.collaborated_freelancers.last_name}`}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            {selectedAssignee && selectedAssigneeContract?.length > 0 ?
              <>
                <Box marginTop={3}>
                  <Card sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', visibility: showCard ? 'visible' : 'hidden' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                          Assign To
                        </Typography>
                        <Divider />
                        <Typography component="div" variant="subtitle2" marginTop={3}>
                          {selectedAssignee ? `${selectedAssignee.collaborated_freelancers.first_name} ${selectedAssignee.collaborated_freelancers.last_name}` : ''}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" component="div">
                          {selectedAssignee ? `${formatDate(selectedAssignee.collaboration_start_date)} to ${formatDate(selectedAssignee.collaboration_end_date)}` : ''}
                        </Typography>
                      </CardContent>
                    </Box>

                    {selectedAssigneeContract[0]?.contract_type === 'commission_percentage' ?
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                          <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                            COMMISSION RATE

                          </Typography>
                          <Divider />
                          <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                            <Input
                              className={Styles.formInput}
                              placeholder="Enter commission rate"
                              type="number"
                              onWheel={handleScroll}
                              disabled={
                                !!selectedAssigneeContract[0]?.commission_rate
                              }
                              value={
                                selectedAssigneeContract[0]?.commission_rate ?? ""
                              }
                              onChange={(e) => handleCommissionChange(e.target.value)}
                            />

                          </Box>
                        </CardContent>
                      </Box>
                      : selectedAssigneeContract[0]?.contract_type === 'fixed_amount' ?
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                              AMOUNT

                            </Typography>
                            <Divider />
                            <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                              <Input
                                className={Styles.formInput}
                                placeholder="Enter Fixed Amount"
                                type="number"
                                onWheel={handleScroll}
                                disabled={
                                  !!selectedAssigneeContract[0]?.fixed_amount
                                }
                                value={
                                  selectedAssigneeContract[0]?.fixed_amount ?? ""
                                }
                                onChange={(e) => handleFixedAmountChange(e.target.value)}
                              />

                            </Box>
                          </CardContent>
                        </Box>
                               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                               <CardContent sx={{ flex: '1 0 auto' }}>
                                 <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                                   ADVANCE %
         
                                 </Typography>
                                 <Divider />
                                 <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>
         
                                 <Select
                                  value={advancePayPercent}
                                  onChange={handleAdvancePayPercentage}
                                  style={{ width: 100 }}
                                  className={Styles.formSelect}
                                  disabled={selectedAssigneeContract[0]?.fixed_amount > 0}
                                >
                                  <Option value={10}>10%</Option>
                                  <Option value={25}>25%</Option>
                                  <Option value={50}>50%</Option>
                                  <Option value={75}>75%</Option>
                                  <Option value={100}>100%</Option>
                                </Select>
         
                                 </Box>
                               </CardContent>
                               
                             </Box>
                             </>
                        : ""
                    }


                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                          QUANTITY

                        </Typography>
                        <Divider />
                        <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                          <input
                            className={Styles.formInput}
                            placeholder="Enter Qty"
                            type="number"
                            onWheel={handleScroll}
                            onChange={(e) => handleQtyChange(e.target.value)}
                          />

                        </Box>
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
                <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={5}>
                  <Button className={Styles.addButton}
                    style={{ display: assignButton ? 'block' : 'none' }}
                    onClick={() => handleAssign(true, selectedAssigneeContract[0]?.contract_type)}
                  >Assign</Button>

                </Box>
              </>
              :
              <>

                {selectedAssignee &&
                  <>
                    <Typography variant="body2" m={3}>Please select the contract type</Typography>
                    <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>

                      <Radio.Group onChange={handleContractType} value={contractType}>
                        <Radio value="commission_percentage"> Commission Percentage Contract</Radio>
                        <Radio value="fixed_amount">Fixed Amount Contract</Radio>
                      </Radio.Group>
                    </div>
                    {contractType &&
                      <>
                        <Box marginTop={3}>
                          <Card sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', visibility: showCard ? 'visible' : 'hidden' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                                  ASSIGN TO
                                </Typography>
                                <Divider />
                                <Typography component="div" variant="subtitle2" marginTop={3}>
                                  {selectedAssignee ? `${selectedAssignee.collaborated_freelancers.first_name} ${selectedAssignee.collaborated_freelancers.last_name}` : ''}
                                </Typography>
                                <Typography variant="caption" color="text.secondary" component="div">
                                  {selectedAssignee ? `${formatDate(selectedAssignee.collaboration_start_date)} to ${formatDate(selectedAssignee.collaboration_end_date)}` : ''}
                                </Typography>
                              </CardContent>
                            </Box>

                            {contractType === 'commission_percentage' ?
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                  <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                                    COMMISSION RATE

                                  </Typography>
                                  <Divider />
                                  <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                                    <Input
                                      className={Styles.formInput}
                                      placeholder="Enter commission rate"
                                      type="number"
                                      onWheel={handleScroll}
                                      onChange={(e) => handleCommissionChange(e.target.value)}
                                    />

                                  </Box>
                                </CardContent>
                              </Box>
                              : contractType === 'fixed_amount' ?
                              <>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                                      AMOUNT

                                    </Typography>
                                    <Divider />
                                    <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                                      <Input
                                        className={Styles.formInput}
                                        placeholder="Enter Fixed Amount"
                                        type="number"
                                        onWheel={handleScroll}
                                        onChange={(e) => handleFixedAmountChange(e.target.value)}
                                      />

                                    </Box>
                                  </CardContent>
                                </Box>
                                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                                      ADVANCE %
            
                                    </Typography>
                                    <Divider />
                                    <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>
            
                                    <Select
                                     value={advancePayPercent}
                                    onChange={handleAdvancePayPercentage}
                                     style={{ width: 100 }}
                                     className={Styles.formSelect}
                                   >
                                     <Option value={10}>10%</Option>
                                     <Option value={25}>25%</Option>
                                     <Option value={50}>50%</Option>
                                     <Option value={75}>75%</Option>
                                     <Option value={100}>100%</Option>
                                   </Select>
            
                                    </Box>
                                  </CardContent>
                                  
                                </Box>
                                </>
                                : ""
                            }


                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="caption" color="text.secondary" gutterBottom>
                                  QUANTITY

                                </Typography>
                                <Divider />
                                <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={3}>

                                  <input
                                    className={Styles.formInput}
                                    placeholder="Enter Qty"
                                    type="number"
                                    onWheel={handleScroll}
                                    onChange={(e) => handleQtyChange(e.target.value)}
                                  />

                                </Box>
                              </CardContent>
                            </Box>
                          </Card>
                        </Box>
                        <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} marginTop={5}>
                          <Button className={Styles.addButton}
                            style={{ display: assignButton ? 'block' : 'none' }}
                            onClick={() => handleAssign(true,contractType)}
                          >Assign</Button>

                        </Box>
                      </>
                    }
                  </>
                }
              </>
            }
          </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};


export default ProductDetailsTable;