import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Popconfirm,
  Space,
} from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _PATCH, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import Styles from "./ProdStyleTable.module.css";
import { ClearIcon } from "@mui/x-date-pickers";

interface ViewIntentoryDetails {
  visible: boolean;
  onClose: () => void;
  data: any;
  id: string;
}

const ViewIntentoryDetails: React.FC<ViewIntentoryDetails> = ({
  visible,
  onClose,
  data,
  id,
}) => {
  console.log("data in inventory drawer is : ", data)

  function getPlainText(text: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.body.textContent || "";
  }
  return (
    <>
      <Drawer
        title="Product Inventory Details"
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        key="right"
        width={"550px"}
        height={"100%"}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "350px",
            overflowY: "scroll",
            background: "#f2f0ff",
            padding: "10px",
            borderRadius: "10px"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "5px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "15px" }}>
              {data?.product_images?.length > 4 ?
                <img
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    marginRight: "5px"
                  }}
                  src={data?.product_images[0]}
                  alt={"Product Image"}
                />
                :
                data?.product_images?.map((p: any) => (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      marginRight: "5px"
                    }}
                    src={p}
                    alt={"Product Image"}
                  />))
              }

            </div>
            <Typography
              variant="subtitle2"
              sx={{ display: "flex", flexDirection: "row" }}
              fontWeight={700}
              gutterBottom
            >
              Product Name:{" "}
              <div style={{ color: "grey", marginLeft: "5px" }}>
                {data?.product_name}
              </div>
            </Typography>
            {data?.commission_rate > 0 ? <Typography
              variant="subtitle2"
              sx={{ display: "flex", flexDirection: "row" }}
              fontWeight={700}
              gutterBottom
            >
              Commission rate:{" "}
              <div style={{ color: "grey", marginLeft: "5px" }}>
                {data?.commission_rate} %
              </div>
            </Typography>
              :
              <Typography
                variant="subtitle2"
                sx={{ display: "flex", flexDirection: "row" }}
                fontWeight={700}
                gutterBottom
              >
                Product Unit Price:{" "}
                {data?.prod_discount > 0 ? <div style={{ color: "grey", marginLeft: "5px" }}>
                  <b>BHD </b>{data?.product_price_after_discount?.toFixed(2)}
                  {<span style={{ textDecoration: "line-through" }}>
                    /
                    {data?.product_unit_price}
                  </span>}
                </div> : <div style={{ color: "grey", marginLeft: "5px" }}>
                  <b>BHD </b>{data?.product_unit_price}
                </div>}
              </Typography>}

              <Typography
              variant="subtitle2"
              sx={{ display: "flex", flexDirection: "row" }}
              fontWeight={700}
              gutterBottom
            >
              Available Quantity:{" "}
              <div style={{ color: "grey", marginLeft: "5px" }}>
                {data?.available_unit}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ display: "flex", flexDirection: "row" }}
              fontWeight={700}
              gutterBottom
            >
              Courier Choice:{" "}
              <div style={{ color: "grey", marginLeft: "5px" }}>
                {data?.product_courier_service_choice === "supplier_courier_service" ? "Own Courier Service" : "Octopus Courier Service"}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ display: "flex", flexDirection: "row" }}
              fontWeight={700}
              gutterBottom
            >
              Courier Charges:{" "}
              <div style={{ color: "grey", marginLeft: "5px" }}>
                BHD {data?.product_courier_charges_per_delivery}
              </div>
            </Typography>
            <Typography variant="subtitle2" fontWeight={700} gutterBottom>
              Product Description:{" "}
            </Typography>
            <li>
              <Typography variant="caption" gutterBottom>{getPlainText(data?.product_description ?? data?.description)}</Typography>
            </li>
            <Typography variant="subtitle2" fontWeight={700} gutterBottom>
              Product Specification:{" "}
            </Typography>
            <li>
              <Typography variant="caption" gutterBottom>{getPlainText(data?.product_other_specs ?? data?.other_specs)}</Typography>
            </li>
          </Box>
        </Box>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", }}>
        <Typography variant="body1" fontWeight={"bold"} mt={3}>Inventory Details</Typography>
        <Typography variant="body1" fontWeight={"bold"} mt={3}>Total Units : <span style={{color:"grey"}}> {data?.available_unit} </span></Typography>
        </div>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "400px",
            overflowY: "scroll",
            background: "#f2f2f2",
            padding: "10px",
            borderRadius: "10px",
            marginTop: "10px"
          }}
        >
 <TableContainer
        component={Paper}
        sx={{ overflowX: "auto", boxShadow: "none !important" }}
        className={Styles.tablecontainer}
      >
        <Table stickyHeader className={Styles.table_}>
          <TableHead className={Styles.tableHeader}>
            <TableRow>
              {/* <TableCell>Size</TableCell> */}
              <TableCell>COLOR</TableCell>
              <TableCell> SIZE</TableCell>
              <TableCell>UNASSIGNED QUANTITY</TableCell>
              <TableCell>ASSIGNED QUANTITY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={Styles.tableBody}>
            {data?.inventory?.map((inventoryItem: any) => (
              <TableRow key={inventoryItem.inventory_id}>
                {/* <TableCell>{inventoryItem.size}</TableCell> */}
                <TableCell className={Styles.colorChip}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><Chip
                    style={{
                      backgroundColor: inventoryItem.colorCode,
                      border: "1px solid #c4c4c4",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      marginRight: "10px"
                    }}
                  /> {inventoryItem?.color}</div>
                </TableCell>
                <TableCell className={Styles.colorChip}>
                  {inventoryItem.size}
                </TableCell>
                <TableCell className={Styles.colorChip}>
                  {inventoryItem.unassigned_quantity}
                </TableCell>
                <TableCell className={Styles.colorChip}>
                  {inventoryItem.assigned_quantity}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

        </Box>

      </Drawer>
    </>
  );
};


export default ViewIntentoryDetails;
