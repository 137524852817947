import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Select, Space, notification } from "antd";
import { useHistory } from "react-router";
import {
  _POST,
  getRole,
  getUserType,
} from "../../common/commonFunc";
import { API_BASE_URL, APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import Link from "antd/es/typography/Link";
import countries from "../../data/countries.json";
import Styles from "../VerifyOtp/VerifyOtp.module.css";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OctopusImage from "../../assests/logo.svg";
import { setCookie } from "../../utils/tokenService";

const VerifyOtpGuest = ({ open, onClose, source }: any) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const inputRef1 = useRef<any>(null);
  const inputRef2 = useRef<any>(null);
  const inputRef3 = useRef<any>(null);
  const inputRef4 = useRef<any>(null);
  const inputRef5 = useRef<any>(null);
  const inputRef6 = useRef<any>(null);

  const [counter, setCounter] = useState(60);
  const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    number: user.phone_no || "",
    countryCode: user.country_code || "+973",
  });

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const updatePhoneNumber = async () => {
    const userID = user.id;
    if (phoneNumber.number === "") {
      notification.error({
        message: "Error",
        description: "Please enter your mobile number",
      });
      return;
    }
    const payload = {
      country_code: phoneNumber.countryCode,
      phone_no: phoneNumber.number,
      mobileNumberWithCountryCode: `${phoneNumber.countryCode}${phoneNumber.number}`,
    };
    const token = user.token;

    try {
      const response = await axios.patch(
        `${API_BASE_URL}updateMobileNumber?userId=${userID}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `${token}`,
          },
        }
      );

      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "Your mobile number has been updated successfully.",
        });

        const updatedUser = {
          ...user,
          phone_no: phoneNumber.number,
          country_code: phoneNumber.countryCode,
          full_phone_number: `${phoneNumber.countryCode}${phoneNumber.number}`,
        };
        localStorage.setItem("userInfo", JSON.stringify(updatedUser));
        setPhoneNumberUpdated(true);
        setShowUpdateModal(false);
        setCounter(60);
      } else {
        notification.error({
          message: "Error",
          description: "Failed to update mobile number. Please try again.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to update mobile number. Please try again.",
      });
    }
  };

  const inputChangeHandler = (temp: any, index: number) => {
    const setOtpFns = [setOtp1, setOtp2, setOtp3, setOtp4, setOtp5, setOtp6];
    setOtpFns[index](temp);

    if (/[^0-9]/.test(temp) || !temp) {
      return;
    }

    const inputRefs = [
      inputRef1,
      inputRef2,
      inputRef3,
      inputRef4,
      inputRef5,
      inputRef6,
    ];
    if (index < 5) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleBackspaceNavigation = (event: any, index: number) => {
    const inputRefs = [
      inputRef1,
      inputRef2,
      inputRef3,
      inputRef4,
      inputRef5,
      inputRef6,
    ];
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };

  const verifyMobileHandler = async (values: any) => {
    if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter All the OTP first",
      });
    }
    const otpValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    const userEmail = user.email;
    const userPhoneNumber = user.full_phone_number

    if (userEmail) {
      // console.log("userEmail is : ", userEmail)
      const response = await _POST(
        "verifyMobile",
        { email: userEmail, otp: otpValue },
        false
      );
      if (response?.status) {
        onClose();
        setOtp1("")
        setOtp2("")
        setOtp3("")
        setOtp4("")
        setOtp5("")
        setOtp6("")
        form.resetFields();
        const updatedUser = {
          ...user,
          id: response?.data?.id
        }
        showSuccessCustomModal({
          title: "Your mobile number is verified successfully.",
          smallText:
            "Your Mobile number Verification is successfully completed",
          buttonText: "Continue",
        });

        localStorage.setItem("octo_zoho_user_id", response?.data?.zoho_user_id);
        localStorage.setItem("octo_role", response?.data?.role);
        localStorage.setItem("userInfo", JSON.stringify(updatedUser));

        const currentTime = Date.now().toString();
        localStorage.setItem("loginTime", currentTime);
        // console.log("Login time set in localStorage:", currentTime);
        window.location.reload();
      }

      else {
        notification.open({
          type: "error",
          message: "Unable to verify OTP",
          description: `${response.message}`,
        });
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description:
          "Your Email Id not Found!. Please go back to the sign-up page",
      });
    }
  };

  const verfiyLoginHandler = async (values: any) => {
    if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter All the OTP first",
      });
    }
    const otpValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    // const userEmail = user.email;
    const userPhoneNumber = user.full_phone_number

    if (userPhoneNumber) {
      // console.log("userEmail is : ", userEmail)
      const response = await _POST(
        "octopus/guest/verifyLogin",
        { phone_number: userPhoneNumber, otp: otpValue },
        false
      );
      if (response?.status) {
        onClose();
        form.resetFields();
        const updatedUser = {
          ...user,
          id: response?.data?.user.id,
          first_name: response?.data?.user.first_name,
          last_name: response?.data?.user.last_name,
          phone_no: response?.data?.user.phone_no,
          email: response?.data?.user.email,
          country_code: response?.data?.user.country_code
        }
        showSuccessCustomModal({
          title: "Your mobile number is verified successfully.",
          smallText:
            "Your Mobile number Verification is successfully completed",
          buttonText: "Continue",
        });
        localStorage.setItem("jwtToken", response?.data?.accessToken);
        setCookie("refreshToken", response?.data?.refreshToken, 720);

        localStorage.setItem("octo_zoho_user_id", response?.data?.user?.zoho_user_id);
        localStorage.setItem("octo_role", response?.data?.user?.role);
        localStorage.setItem("userInfo", JSON.stringify(updatedUser));

        const currentTime = Date.now().toString();
        localStorage.setItem("loginTime", currentTime);
        // console.log("Login time set in localStorage:", currentTime);
        window.location.reload();
      }

      else {
        notification.open({
          type: "error",
          message: "Unable to verify OTP",
          description: `${response.message}`,
        });
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description:
          "Your Email Id not Found!. Please go back to the sign-up page",
      });
    }
  };


  const resendMobileOTPHandler_email = async () => {
    const userEmail = user.email;
    if (userEmail) {
      const response = await _POST("resendMobileOTP/" + userEmail, {}, false);
      if (response.status) {
        showSuccessCustomModal({
          title: "OTP Sent Successfully",
          smallText: "Your OTP sent to your Mobile Number for verification.",
          buttonText: "Continue",
        });
        setCounter(60);
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description:
          "Your Email Id not Found!. Please go back to the sign-up page",
      });
    }
  };

  const resendMobileOTPHandler = async () => {
    const userPhoneNumber = user.full_phone_number
    if (userPhoneNumber) {
      const response = await _POST("resendMobileOTPByPhoneNumber", { phone_number : userPhoneNumber}, false);
      if (response.status) {
        showSuccessCustomModal({
          title: "OTP Sent Successfully",
          smallText: "Your OTP sent to your Mobile Number for verification.",
          buttonText: "Continue",
        });
        setCounter(60);
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description:
          "Your Email Id not Found!. Please go back to the sign-up page",
      });
    }
  };

  

  const onPhoneNumberChange = (name: any, value: any) => {
    setPhoneNumber((prev) => ({ ...prev, [name]: value }));
  };


  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      className="loginPopUp"
      sx={{
        "& .MuiPaper-root": { borderRadius: 6 },
        // background: "linear-gradient(151deg, #8200FF 0%, #00B0FF 60.52%)",
        "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .css-y2taew .MuiPaper-root": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .loginPopUp .MuiDialog-paperWidthSm": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
        "& .MuiDialog-paperWidthSm": {
          background: "linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%)",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            backgroundColor: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginTop: "3%",
            width: "100%",
            height: "100%",
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            align="start"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "100px",
                height: "100px",
                marginBottom: "8px",
                marginTop: "8px",
              }}
              src={OctopusImage}
              alt=""
            />
          </Space>
          <div className={Styles.verifyOtpContainerGuest}>
            {phoneNumberUpdated ? (
              <Form
                size="small"
                className="digit-group verifyOtpContainerGuest"
                onFinish={updatePhoneNumber}
              >
                <div className={Styles.verifyOtpContainerGuest}>
                  <Form.Item name="contactPersonPhone" label="">
                    <Input.Group compact className={Styles.FormInput}>
                      <Select
                        defaultValue={phoneNumber.countryCode}
                        onChange={(value) =>
                          onPhoneNumberChange("countryCode", value)
                        }
                        showSearch
                        optionFilterProp="children"
                        className={Styles.FormInputNumber}
                      >
                        {countries.map((country) => (
                          <Option key={country.code} value={country.dial_code}>
                            <img
                              src={country.flag}
                              alt={country.name}
                              style={{ marginRight: 8, width: 20 }}
                            />
                            {country.dial_code}
                          </Option>
                        ))}
                      </Select>
                      <Input
                        onChange={(e) =>
                          onPhoneNumberChange("number", e.target.value)
                        }
                        placeholder="Enter phone number"
                        className={Styles.FormInputNumber}
                        defaultValue={phoneNumber.number}
                      />
                    </Input.Group>
                  </Form.Item>
                </div>
                <Button
                  shape="round"
                  type="primary"
                  className={Styles.buttonBG}
                  htmlType="submit"
                >
                  Update
                </Button>
              </Form>
            ) : (
              <Form
                size="small"
                className={Styles.verifyOtpContainerGuest}
                onFinish={verifyMobileHandler}
              >
                {/* <div className={Styles.updatePhoneNumber}>
              {showUpdateModal ? null : (
                <span>If you entered the wrong number, </span>
              )}
              <Link onClick={() => setShowUpdateModal((value) => !value)}>
                {showUpdateModal ? "Not now" : "change number"}
              </Link>
              .
            </div> */}

                {showUpdateModal ? (
                  <div className={Styles.verifyOtpContainerGuest}>
                    <Form.Item name="contactPersonPhone">
                      <Input.Group compact className={Styles.FormInput}>
                        <Select
                          defaultValue={phoneNumber.countryCode}
                          onChange={(value) =>
                            onPhoneNumberChange("countryCode", value)
                          }
                          showSearch
                          optionFilterProp="children"
                          className={Styles.FormInputNumber}
                        >
                          {countries.map((country) => (
                            <Option key={country.code} value={country.dial_code}>
                              <img
                                src={country.flag}
                                alt={country.name}
                                style={{ marginRight: 8, width: 20 }}
                              />
                              {country.dial_code}
                            </Option>
                          ))}
                        </Select>
                        <Input
                          onChange={(e) =>
                            onPhoneNumberChange("number", e.target.value)
                          }
                          placeholder="Enter phone number"
                          className={Styles.FormInputNumber}
                          defaultValue={phoneNumber.number}
                        />
                      </Input.Group>
                    </Form.Item>
                  </div>
                ) : (
                  <Form.Item name="otp" className={Styles.otpContainer}>
                    <div className={Styles.otpInputGroup}>
                      {[otp1, otp2, otp3, otp4, otp5, otp6].map((otp, index) => (
                        <div key={index} className={Styles.otpInputWrapperGuest}>
                          <Input
                            type="text"
                            maxLength={1}
                            ref={
                              [
                                inputRef1,
                                inputRef2,
                                inputRef3,
                                inputRef4,
                                inputRef5,
                                inputRef6,
                              ][index]
                            }
                            className={Styles.otpInput}
                            value={otp}
                            onChange={(e) =>
                              inputChangeHandler(e.target.value, index)
                            }
                            onKeyDown={(e) => handleBackspaceNavigation(e, index)}
                            placeholder="0"
                          />
                        </div>
                      ))}
                    </div>
                  </Form.Item>
                )}

                <Button
                  shape="round"
                  type="primary"
                  className={Styles.buttonBG}
                  htmlType="button"
                  onClick={
                    showUpdateModal || phoneNumberUpdated
                      ? updatePhoneNumber
                      : source === "register" ? verifyMobileHandler : verfiyLoginHandler
                  }
                >
                  {showUpdateModal || phoneNumberUpdated
                    ? "Update"
                    : "Verify OTP"}
                </Button>

                {counter > 0 && (
                  <span className={Styles.remainingTimeEmailVerify}>
                    00:{counter < 10 ? `0${counter}` : counter}
                  </span>
                )}

                {counter === 0 && !showUpdateModal && (
                  <Button
                    shape="round"
                    type="link"
                    htmlType="button"
                    onClick={source === "register" ? resendMobileOTPHandler_email : resendMobileOTPHandler }
                    style={{ marginTop: "10px" }}
                  >
                    Resend OTP
                  </Button>
                )}
              </Form>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyOtpGuest;
