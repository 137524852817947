import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Checkbox,
} from "antd";
import axios from "axios";
import Cross from "../../assests/customIcons/Cross";
import { GovernoratesData } from "../../data/GovernoratesData"; // Adjust path as needed
import "./AddLocationDrawer.css";
import { GOOGLE_MAP_API_KEY } from "../../constant/ApplicationRoutes";
import { _POST, getRole, getUserType } from "../commonFunc";
import { geocode, RequestType, setKey } from "react-geocode";
import LocationPicker from "../../pages/Map/LocationPicker";
import countries from "../../data/countries.json";
import CountriesData from "../../data/CountriesData.json";
import _ from "lodash";

// Set Google Maps API key
setKey(GOOGLE_MAP_API_KEY);

const { Option } = Select;

const AddLocationDrawer = ({
  openLocDrawer,
  closeToAddLoc,
  setAddedLocations,
  coords = { lat: 26.2285161, lng: 50.5860497 },
  setCoords,
}: any) => {
  const [form] = Form.useForm();
  const [saveDefault, setSaveDefault] = useState(false);
  const [selectedGovernorate, setSelectedGovernorate] = useState(null);
  const [regions, setRegions] = useState<any>([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [newLocation, setNewLocation] = useState<any>({});
  const userType = getRole();

  const userData = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const userId = userData.id;


  const FlagUrl = `https://hatscripts.github.io/circle-flags/flags/`;
  const countriesWithFlagUrls = CountriesData.map((country: any) => ({
    ...country,
    flag: `${FlagUrl}${country.code.toLowerCase()}.svg`,
  }));

  function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  const newUUID = generateUUID();

  const onSubmitHandler = async (values: any) => {
    const requestBody = {

      address_id: newUUID,
      address_name: values.address_name,
      building_no: values.building_no,
      floor: values.floor,
      address: values.address,
      landmark: values.landmark,
      pinCode: values.pinCode,
      city: values.city,
      state: values.state,
      governance: values.governorate,
      region: values.region,
      country: values.country,
      isDefault: saveDefault,
      coords: {
        lat: newLocation.coords?.lat || coords.lat,
        lng: newLocation.coords?.lng || coords.lng,
      },
    };

    try {
      const response = await _POST(
        `userProfile/${userId}/add_address`,
        requestBody,
        true
      );

      if (response.statusCode === 200) {
        setAddedLocations((prevLocations: any) => [
          requestBody,
          ...prevLocations,
        ]);
        console.log("VALUES ADDED SUCCRSSFULLY DRAWER CLOSED");
        closeToAddLoc();
      } else {
        console.log("API request failed");
        console.log("VALUES ADDED SUCCRSSFULLY DRAWER REMIANED OPEN");
      }
    } catch (error) {
      console.error("Error while making API request:", error);
    }
  };

  const handleGovernorateChange = (value: any) => {
    setSelectedGovernorate(value);
    const selectedGov = GovernoratesData.Governorates.find(
      (gov) => gov.value === value
    );
    if (selectedGov) {
      setRegions(selectedGov.regions);
      setSelectedRegion(null);
    }
  };

  const getLocationData = async () => {
    const latitude = coords?.lat ?? 0;
    const longitude = coords?.lng ?? 0;
    try {
      geocode(RequestType.LATLNG, `${latitude},${longitude}`).then(
        ({ results }) => {
          const data = results[0];

          if (data) {
            const address = data.formatted_address;
            const { governorate, region, city, state, country, pinCode, landmark, building_no, street_name } =
              data.address_components.reduce((acc: any, component: any) => {
                if (component.types.includes("administrative_area_level_3"))
                  acc.city = component.long_name;
                else if (
                  component.types.includes("administrative_area_level_1")
                ) {

                  acc.state = component.long_name;
                  acc.governorate = component.long_name;
                }
                else if (
                  component.types.includes("administrative_area_level_2")
                )
                  acc.region = component.long_name;
                else if (component.types.includes("country"))
                  acc.country = component.long_name;
                else if (component.types.includes("postal_code"))
                  acc.pinCode = component.long_name;
                else if (component.types.includes("locality"))
                  acc.landmark = component.long_name;
                else if (component.types.includes("street_number"))
                  acc.building_no = component.long_name;
                else if (component.types.includes("route")) // Extract street name
                  acc.street_name = component.long_name;
                return acc;
              }, {});
            form.setFieldsValue({
              governorate,
              region,
              address: street_name,
              landmark,
              pinCode,
              city,
              state,
              country,
              building_no,
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleRegionChange = async (value: any) => {
    setSelectedRegion(value);
    console.log("Selected region:", value);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&components=country:BH&key=${GOOGLE_MAP_API_KEY}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.status === "ZERO_RESULTS") {
        console.warn("No location found for the selected region.");
        const defaultLocation = {
          latitude: 26.0667,
          longitude: 50.5577,
          address: "Bahrain",
          city: "Bahrain",
          state: "",
          country: "Bahrain",
          addressName: "",
          streetNumber: "",
          floor: "",
          landmark: "",
          pinCode: "",
        };
        setNewLocation(defaultLocation);
        return;
      }

      const { lat, lng } = data.results[0].geometry.location;
      // console.log(`Latitude: ${lat}, Longitude: ${lng}`);

      const addressComponents: any = data?.results[0]?.address_components;

      const getAddressComponent = (type: any) => {
        return (
          (
            addressComponents.find((component: any) =>
              component.types.includes(type)
            ) || {}
          ).long_name || ""
        );
      };

      const formattedAddress = {
        addressName: "",
        buildingNumber: getAddressComponent("street_number"),
        floor: "",
        address: `${data?.results[0]?.formatted_address}`.trim(),
        landmark: getAddressComponent("locality"),
        pinCode: getAddressComponent("postal_code"),
        city: getAddressComponent("administrative_area_level_3"),
        state: getAddressComponent("administrative_area_level_1"),
        country: getAddressComponent("country"),
        coords: { lat, lng },
      };

      setNewLocation({
        ...formattedAddress,
        address_name:
          data?.results[0]?.address_components?.long_name ||
          data?.results[0]?.address_components?.short_name,
        coordinate: {
          latitude: lat,
          longitude: lng,
        },
      });

      form.setFieldsValue({
        address: formattedAddress.address,
        landmark: formattedAddress.landmark,
        pinCode: formattedAddress.pinCode,
        city: formattedAddress.city,
        state: formattedAddress.state,
        country: formattedAddress.country,
        building_no: formattedAddress.buildingNumber,
      });
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };

  return (
    <Drawer
      title="Add New Address"
      placement="right"
      closable={false}
      onClose={closeToAddLoc}
      open={openLocDrawer}
      key="right"
      width={"550px"}
      extra={
        <Space>
          <Button onClick={closeToAddLoc} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" onFinish={onSubmitHandler} form={form}>
        {userType === "user" && (
          <>
            <LocationPicker coords={coords} setCoords={setCoords} />
            <Row
              style={{
                width: "95%",
                marginLeft: "auto",
                paddingBottom: "20px",
              }}
            >
              <Button
                shape="round"
                type="primary"
                htmlType="button"
                className="buttonBG"
                onClick={() => getLocationData()}
              >
                Fill the form for selected location
              </Button>
            </Row>
          </>
        )}

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Address Name"
              name="address_name"
              rules={[{ required: true, message: "Please provide address name !" }]}

            >
              <Input
                placeholder="Enter your Address name"
                className="drawerInput"
              />
            </Form.Item>
          </Col>
        </Row>
        {userType === "user" || userType === "supplier" ? (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Governorate" name="governorate">
                  <Input placeholder="Enter Province" className="drawerInput" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Region" name="region">
                  <Input placeholder="Enter Region" className="drawerInput" />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Governorate"
                  name="governorate"
                  rules={[{ required: true, message: "Please select a governorate!" }]}
                >
                  <Select
                    placeholder="Select a Governorate"
                    onChange={handleGovernorateChange}
                    className="drawerInput"
                    showSearch
                    optionFilterProp="children"
                  >
                    {GovernoratesData.Governorates.map((gov) => (
                      <Option key={gov.value} value={gov.value}>
                        {gov.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Region"
                  name="region"
                  rules={[{ required: true, message: "Please select a region!" }]}
                >
                  <Select
                    placeholder="Select a Region"
                    onChange={handleRegionChange}
                    className="drawerInput"
                    showSearch
                    optionFilterProp="children"
                  >
                    {regions.map((region: any, index: any) => (
                      <Option key={index} value={region}>
                        {region}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {/* {selectedRegion && ( */}
        <>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Building name/no."
                name="building_no"

              >
                <Input
                  placeholder="Add Building name/no."
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Floor, apartment or villa no." name="floor">
                <Input
                  type="number"
                  placeholder="Floor, apartment or villa no."
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Street name"
                name="address"

              >
                <Input
                  placeholder="Add your Street name"
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please enter country !",
                  },
                ]}
              >
                <Select
                  className="drawerInput"
                  style={{
                    height: "40px",
                    borderRadius: "67px",
                    width: "100%",
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.key ? option?.key.toLowerCase() : "").includes(input)
                  }
                  showSearch
                  filterSort={(optionA, optionB) =>
                    (optionA?.key ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.key ?? "").toLowerCase())
                  }
                  placeholder="Select Country"
                >
                  {_.map(countriesWithFlagUrls, (eachPool: any) => (
                    <Select.Option
                      key={eachPool.key}
                      value={eachPool.key}
                      optionFilterProp="children"
                      showSearch
                    >
                      <div>
                        <img
                          width={"20px"}
                          height={"20px"}
                          style={{ paddingTop: "4px", marginRight: "5px" }}
                          src={eachPool.flag}
                          alt=""
                        />{" "}
                        &nbsp;
                        {eachPool.key}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Nearest landmark"
                name="landmark"

              >
                <Input
                  placeholder="Nearest landmark"
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="isDefault">
                <Checkbox onChange={(e) => setSaveDefault(e.target.checked)}>
                  Save as Default
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginTop: "20px", marginBottom: "-50px" }}>

            <Button shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
            >
              Add
            </Button>
          </Form.Item>
        </>
        {/* )} */}
      </Form>
    </Drawer>
  );
};

export default AddLocationDrawer;
