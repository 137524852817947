import React from "react";
import { getShortenText, getCapitalize } from "../../../utils/helpers";
import "./BlogCard.css";
import { getMonthDateYear } from "../../../utils/helpers";
import { Col, Row } from "antd";

const BlogCard = React.memo(({ blogItem, onReadMore }: any) => {

  const TextWithDirection = ({ text }: any) => {
    // Regex to check if the first character is Arabic
    const isArabic = /^[\u0600-\u06FF]/.test(text.trim());

    return (
      <span dir={isArabic ? 'rtl' : 'ltr'} style={{ display: 'block' }}>
        {text}
      </span>
    );
  };

  function getPlainText(text: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.body.textContent || "";
  }


  function convertUnixToDate(timestamp: any) {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');


    const amPm = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time (0 becomes 12)

    return `${day}-${month}-${year}  ${hours}:${minutes} ${amPm}`;
  }



  return (
    <Row className="blogCardContainer">
      <Col span={8}>
        <img className="blogImage" src={blogItem?.blog_image_url[0]} />
      </Col>
      <Col span={15} className="blogInfo">
        <p className="subTitle">{convertUnixToDate(blogItem?.created_at)}</p>
        <p className="mainTitle"><TextWithDirection text={getPlainText(blogItem?.blog_heading)} /></p>
        <p className="subTitle">{getPlainText(blogItem?.blog_content)}</p>
        <p className="readMore" onClick={() => onReadMore(blogItem)}>
          Read More
        </p>
      </Col>
    </Row>
  );
});

export default BlogCard;
