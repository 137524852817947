import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Row, Space, Steps, Tag } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from "@mui/material";
import Styles from "../../SupplierScreens/ManageOrders/OrderTable.module.css";
import ReturnProductsModal from "../CustomerPage/ReturnProductsModal";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ProductDeliveredDrawer {
  visible: boolean;
  onClose: () => void;
  data: any;
  mainTableData : any
  fetchData : () => void
}

const ProductDeliveredDrawer: React.FC<ProductDeliveredDrawer> = ({
  visible,
  onClose,
  data,
  mainTableData,
  fetchData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemId, setItemId] = useState("");
  const [orderItem, setOrderItem] = useState<any>([]);

  console.log("data in the delivered drawer is: ", data);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const formatDate = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const confirmReturnService = (event: any, orderItemId: string) => {
    event.preventDefault();
    setItemId(orderItemId);
    setIsModalOpen(true);
  };

  const closeReturnModal = () => {
    setIsModalOpen(false)
    onClose();
  }

  const getStatusColor = (status: string) => {
    switch (status) {
        case "ORDERED":
            return "orange";
        case "DELIVERED":
            return "green";
        case "COMPLETED":
            return "green";
        case "SHIPPED":
            return "blue";
        case "PREPARED":
            return "blue";
        case "PENDING_TO_RTD":
            return "grey";
        case "CANCELLED":
            return "grey";
        case "RETURNED":
            return "purple";
        default:
            return "red";
    }
};

  const trimId = (id: any) => {
    if (id) {
      return id.substring(14, 45);
    }
    return "";
  };

  const orderId = data?.order_id;
  const address = _.get(data, "delivery_address.address", "Address not found");
  const city = _.get(data, "delivery_address.city", "City not found");
  const state = _.get(data, "delivery_address.state", "State not found");
  const country = _.get(data, "delivery_address.country", "Country not found");
  const pincode = _.get(data, "delivery_address.pincode", "Pincode not found");
  const fullAddress = `${address}, ${city}, ${state}, ${country} - ${pincode}`;
  const steps = [
    "Order Placed",
    "Order Shipped",
    "Out for delivery",
    "Delivered",
  ];
  const steps_supplier_courier = ["Order Placed", "Order Shipped", "Delivered"];

  const getActiveStep = (status: string) => {
    switch (status) {
      case "ORDERED":
        return 1;
      case "PREPARED":
        return 2;
      case "SHIPPED":
        return 2;
      case "DELIVERED":
        return 4;
      default:
        return 4;
    }
  };

  const getActiveStep_supplier = (status: string) => {
    switch (status) {
      case "ORDERED":
        return 1;
      case "PREPARED":
        return 2;
      case "SHIPPED":
        return 2;
      case "DELIVERED":
        return 3;
      case "COMPLETED":
        return 3;
      default:
        return 3;
    }
  };

  // const CustomStepIcon = styled(StepIcon)({
  //     color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`,
  //     '&.Mui-active': {
  //        color: 'linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)'
  //     },
  //     '&.Mui-completed': {
  //         backgroundImage:
  //           "linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%);",
  //     },
  //     '&.MuiStepIcon-text': {
  //         color: 'linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)'
  //     }

  //    });

  const formatProductName = (name: string) => {
    const start = name?.substring(0, 10);

    return `${start}..`;
  };

  const formatProductAmount = (amt: string) => {
    const start = amt?.substring(0, 5);

    return `${start}..`;
  };


  useEffect(()=> {

    const filteredOrderItem = data?.productsOrdered?.filter((item : any) => mainTableData.order_item_id === item.order_item_id)
    setOrderItem(filteredOrderItem)
    
  },[data, mainTableData])

    return (
        <>
            <ReturnProductsModal
                isVisible={isModalOpen}
                onClose={closeReturnModal}
                // onSubmit={(reason) => handleReturnOrder(data.id, reason)}
                fetchData={fetchData}
                id={orderId}
                item_id={itemId}
            />
            <Drawer
                title="Order Details"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
                key="right"
                width={"550px"}
                height={"100%"}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                <div style={{ marginBottom: "30px" }}>
                    <Box component="div" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        {orderItem && <Tag color={getStatusColor(orderItem[0]?.order_item_status)}
                        style={{display:"flex",alignItems:"center"}}>
                            {orderItem[0]?.order_item_status}
                        </Tag>}
                        <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                            Paid <div style={{ color: "grey", marginLeft: "3px", fontWeight: "200" }}>
                                {data?.amount_paid_ccy}
                            </div>
                            <div style={{ color: "black", marginLeft: "3px" }}>
                                {data?.amount_paid}
                            </div>
                        </Typography>
                    </Box>
                    <Box component="div" sx={{ display: "flex", flexDirection: "column" }} marginTop={1} marginBlockEnd={2}>
                        <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                            ID | <div style={{ color: "black", marginLeft: "3px" }}>{trimId(orderId)}</div>
                        </Typography>
                        <Typography variant="caption" color={'text.secondary'} gutterBottom>
                            <div style={{ display: "flex", flexDirection: "row", marginRight: "40px" }}>
                                <div style={{ marginRight: "50px" }}> Order date: {formatDate(data?.purchase_date)}
                                </div>
                                {/* <div> Delivery date: {data?.product_order_details?.deliveryTime?.split("T")[0]}

                                </div> */}
              </div>
            </Typography>
          </Box>
          {/* dfljgfldkjg */}
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#f2f1fb",
              borderRadius: "15px",
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "15px",
              }}
              margin={2}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  background: "#e9e9fa",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {orderItem?.map((order: any, index: any) => (
                    <Accordion
                      key={index}
                      sx={{
                        background: "#e9e9fa",
                        boxShadow: "1px 2px 5px #808080ad",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Box marginX={2} marginY={1}>
                          <img
                            src={
                              order &&
                              order.product_images &&
                              order.product_images.length > 0
                                ? order.product_images[0]
                                : "https://picsum.photos/200"
                            }
                            alt=""
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                        <Box display={"flex"}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "90px",
                              }}
                            >
                              <LightTooltip
                                title={
                                  <>
                                    <div style={{ fontWeight: "bold" }}>
                                      Product Name :
                                    </div>
                                    <br />
                                    <div>{order?.product_name}</div>
                                  </>
                                }
                                placement="bottom"
                                arrow
                              >
                                <span
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {formatProductName(order?.product_name)}
                                </span>
                              </LightTooltip>
                              <Typography
                                variant="caption"
                                color={"text.secondary"}
                              >
                                {order?.prod_category}
                              </Typography>
                              <Typography
                                variant="caption"
                                color={"text.secondary"}
                                gutterBottom
                              >
                                Quantity : {order?.units_ordered}
                              </Typography>
                            </div>

                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                style={{
                                  color: "grey",
                                  marginLeft: "3px",
                                  fontWeight: "200",
                                }}
                              >
                                {order?.amount_paid_ccy}
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  marginLeft: "3px",
                                  width: "20px",
                                }}
                              >
                                <LightTooltip
                                  title={
                                    <>
                                      <div style={{ fontWeight: "bold" }}>
                                        Amount Paid :
                                      </div>
                                      <br />
                                      <div>{order?.amount_paid}</div>
                                    </>
                                  }
                                  placement="bottom"
                                  arrow
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {formatProductAmount(order?.amount_paid)}
                                  </span>
                                </LightTooltip>
                              </div>
                            </div>
                            <div
                              style={{
                                marginLeft: "35px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {order?.is_returnable && <Button
                                className={Styles.returnButton}
                                onClick={(event) =>
                                  confirmReturnService(
                                    event,
                                    order?.order_item_id
                                  )
                                }
                              >
                                Return
                              </Button>}
                              {!data?.is_reviewed && <Button className={Styles.feedBackButton}>
                                Rate Product
                              </Button>}
                            </div>
                          </div>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box marginY={1}>
                            {order?.courier_service_choice ===
                            "octopus_courier_service" ? (
                              <Stepper
                                activeStep={getActiveStep(
                                  order.order_item_status
                                )}
                                alternativeLabel
                                sx={{
                                  "& .MuiStepConnector-line": {
                                    borderColor: "#4E4DFF",
                                    borderTopWidth: "2px",
                                    opacity: "20%",
                                  },
                                  "& .MuiStepIcon-root": {
                                    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`,
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`,
                                  },
                                }}
                              >
                                {steps.map((label) => (
                                  <Step key={label}>
                                    <StepLabel
                                      //    StepIconComponent={CustomStepIcon}
                                      StepIconProps={{
                                        classes: {
                                          root: Styles.customCompletedIcon,
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {label}
                                        <Typography
                                          variant="caption"
                                          color={"text.secondary"}
                                        >
                                          {" "}
                                          {label === "Order Placed"
                                            ? formatDate(data?.purchase_date)
                                            : label === "Order Shipped"
                                            ? order?.order_pickup_date_time_unix
                                              ? formatDate(
                                                  order?.order_pickup_date_time_unix
                                                )
                                              : "-"
                                            : label === "Out for delivery"
                                            ? order?.order_handover_date_time_unix
                                              ? formatDate(
                                                  order?.order_handover_date_time_unix
                                                )
                                              : "-"
                                            : label === "Delivered"
                                            ? order?.order_delivery_date_time_unix
                                              ? formatDate(
                                                  order?.order_delivery_date_time_unix
                                                )
                                              : order?.expected_delivery_date_time_unix ? formatDate(
                                                order?.expected_delivery_date_time_unix
                                              ) : "-"
                                            : "-"}{" "}
                                        </Typography>
                                      </div>
                                    </StepLabel>
                                  </Step>
                                ))}
                              </Stepper>
                            ) : (
                              <Stepper
                                activeStep={getActiveStep_supplier(
                                  order.order_item_status
                                )}
                                alternativeLabel
                                sx={{
                                  "& .MuiStepConnector-line": {
                                    borderColor: "#4E4DFF",
                                    borderTopWidth: "2px",
                                    opacity: "20%",
                                  },
                                  "& .MuiStepIcon-root": {
                                    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`,
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`,
                                  },
                                }}
                              >
                                {steps_supplier_courier.map((label) => (
                                  <Step key={label}>
                                    <StepLabel
                                      //    StepIconComponent={CustomStepIcon}
                                      StepIconProps={{
                                        classes: {
                                          root: Styles.customCompletedIcon,
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {label}
                                        <Typography
                                          variant="caption"
                                          color={"text.secondary"}
                                        >
                                          {" "}
                                          {label === "Order Placed"
                                            ? formatDate(data?.purchase_date)
                                            : label === "Order Shipped"
                                            ? order?.order_dispatched_date_time_unix
                                              ? formatDate(
                                                  order?.order_dispatched_date_time_unix
                                                )
                                              : "-"
                                            : label === "Delivered"
                                            ? order?.expected_delivery_date_time_unix
                                              ? formatDate(
                                                  order?.expected_delivery_date_time_unix
                                                )
                                              : "-"
                                            : "-"}{" "}
                                        </Typography>
                                      </div>
                                    </StepLabel>
                                  </Step>
                                ))}
                              </Stepper>
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box marginY={3}>
                        <Typography variant="subtitle1" marginTop={2} fontWeight={700}>Track Order</Typography>
                    </Box>
                    <Box marginY={3}>
                        <Stepper activeStep={4} alternativeLabel
                            sx={{
                                "& .MuiStepConnector-line": {
                                    borderColor: "#4E4DFF",
                                    borderTopWidth: "2px",
                                    opacity: "20%"
                                },
                                "& .MuiStepIcon-root": {
                                    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`
                                },
                                "& .MuiSvgIcon-root": {
                                    color: `linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%)`
                                }
                            }}
                        >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel

                                        //    StepIconComponent={CustomStepIcon}
                                        StepIconProps={{
                                            classes: {
                                                root: Styles.customCompletedIcon,
                                            }
                                        }}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {label}
                                            <Typography variant="caption" color={"text.secondary"}> Sun, 13 Apr 2024 </Typography>
                                        </div>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box> */}
          <div>
            <h2>Billing Summary</h2>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "black",
                fontSize: "14px",
                fontWeight: "800",
              }}
            >
              {" "}
              <span>Subtotal</span>{" "}
              <span
                style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
              >
                BHD{" "}
                {data?.price_per_unit !== null
                  ? data?.total_product_price_amount
                  : "0"}
              </span>
            </p>
            <br />
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "black",
                fontSize: "14px",
                fontWeight: "800",
              }}
            >
              {" "}
              <span>Product Discount</span>{" "}
              <span
                style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
              >
                {"-"} BHD{" "}
                {data?.vat_amount !== null ? data?.total_discount_amount : "0"}
              </span>
            </p>
            <br />
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "black",
                fontSize: "14px",
                fontWeight: "800",
              }}
            >
              {" "}
              <span>Shipping Fee </span>{" "}
              <span
                style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
              >
                {/* BHD {data?.price_per_unit !== null ? data?.price_per_unit : "0"} */}
                BHD {data?.shippingValue}
              </span>
            </p>
            <br />
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "black",
                fontSize: "14px",
                fontWeight: "800",
              }}
            >
              {" "}
              <span>VAT Amount</span>{" "}
              <span
                style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
              >
                BHD {data?.vat_amount !== null ? data?.total_vat_amount : "0"}
              </span>
            </p>
            <Divider />
            <br />
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "black",
                fontSize: "18px",
                fontWeight: "800",
              }}
            >
              {" "}
              <span>Total Paid </span>{" "}
              <span
                style={{ color: "gray", fontSize: "18px", fontWeight: "800" }}
              >
                BHD {data?.amount_paid !== null ? data?.amount_paid : "0"}
              </span>
            </p>

            <Divider />
          </div>

          <div>
            {/* <Button className={Styles.refundButton}>
                            Cancel Order
                        </Button> */}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ProductDeliveredDrawer;
